import { ElementType, ReactElement, useEffect, useRef, useState } from "react";
import { Dialog, PaperProps } from "@mui/material";

interface IProps {
  renderDisplay: (
    open: boolean,
    setOpen: (open: boolean) => void
  ) => ReactElement;
  renderDialog: (
    open: boolean,
    setOpen: (open: boolean) => void
  ) => ReactElement;
  onClose: () => void;
  PaperProps?: Partial<PaperProps<ElementType>>;
}

const DialogModal = ({renderDisplay, renderDialog, onClose, PaperProps}: IProps) => {
  const [open, setOpen] = useState(false);
  const renderedRef = useRef(false);

  useEffect(() => {
    if (!renderedRef.current) {
      renderedRef.current = true;
    } else if (!open && renderedRef.current) {
      onClose();
    }
  }, [open]);

  return (
    <>
      {renderDisplay(open, setOpen)}
      <Dialog open={open} onClose={() => setOpen(false)} PaperProps={PaperProps}>
        {renderDialog(open, setOpen)}
      </Dialog>
    </>
  );
};

export default DialogModal;
