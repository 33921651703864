import { getContentTypeString } from "utils/strings";
import { FileType } from "../S3FileInput/S3FileInput";

export interface IS3FileForm {
  type?: FileType;
  file?: File;
  contentType?: string;
}

export const INITIAL_S3_FILE_FORM = {};

export enum ActionType {
  UpdateType = "update type",
  UpdateFile = "update file",
}

interface Action {
  type: ActionType;
  data: any;
}

const s3FileFormReducer = (form: IS3FileForm, action: Action) => {
  const newForm = { ...form };

  switch (action.type) {
    case ActionType.UpdateType:
      newForm.type = action.data;
      newForm.contentType = getContentTypeString(action.data);
      break;
    case ActionType.UpdateFile:
      newForm.file = action.data;
      break;
  }

  return newForm;
};

export default s3FileFormReducer;
