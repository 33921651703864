import { Card, TableContainer, Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Spacer from "components/Common/Spacer";
import { DataSourceId } from "models/zuko/DataSourceId";

interface IProps<T> {
  models: T[];
  dataSourceIds: DataSourceId[];
  useTable: (
    models: T[],
    dataSouceIds: DataSourceId[],
    heading?: string
  ) => { columns: any[]; rows: any[]; heading: string };
  heading?: string;
}

const ModelListTable = <T,>({
  models,
  useTable,
  heading: forcedHeading,
  dataSourceIds,
}: IProps<T>) => {
  const { columns, rows, heading } = useTable(
    models,
    dataSourceIds,
    forcedHeading
  );

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h6">{heading}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center"></Box>
      </Box>
      <Spacer height={20} />
      <TableContainer component={Card}>
        <DataGrid columns={columns} rows={rows} autoHeight />
      </TableContainer>
    </Box>
  );
};

export default ModelListTable;
