import { Box } from "@mui/material";
import { ChannelList, ChatDown, LoadingChannels } from "stream-chat-react";
import CustomListItem from "./CustomChatListItem";

function CustomListContainer(props: any) {
  const {
    children,
    error = null,
    loading,
    LoadingErrorIndicator = ChatDown,
    LoadingIndicator = LoadingChannels,
  } = props;

  if (error) {
    return <LoadingErrorIndicator type="Connection Error" />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      minWidth="95vw"
      bgcolor="white"
    >
      <Box
        aria-label="Channel list"
        display="flex"
        flexDirection="column"
        width="100%"
        minWidth="95vw"
        bgcolor="white"
      >
        {children}
      </Box>
    </Box>
  );
}

export default function ChatList() {
  return (
    <ChannelList
      sort={[{ unread_count: -1 }]}
      Preview={CustomListItem}
      List={CustomListContainer}
    />
  );
}
