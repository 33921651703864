import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DialogModal from "components/Modals/DialogModal";
import { FinancialInfo } from "models/RiskProfile";
import RiskProfileForm from "./RiskProfileForm";
import useEditRiskProfileDialog from "./EditRiskProfileDialog.hooks";
import { noop } from "lodash";

interface IProps {
  userId: string;
  info: FinancialInfo;
  onEdit: () => void;
}

export default function EditRiskProfileDialog({
  userId,
  info,
  onEdit,
}: IProps) {
  const { setForm, onSave } = useEditRiskProfileDialog(userId, onEdit);

  return (
    <DialogModal
      renderDisplay={(open, setOpen) => (
        <Button startIcon={<Edit />} onClick={() => setOpen(true)}>
          Edit
        </Button>
      )}
      renderDialog={(open, setOpen) => (
        <Box minWidth={400}>
          <DialogTitle>Edit Risk Profile</DialogTitle>
          <DialogContent>
            <RiskProfileForm info={info} onChange={setForm} />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onSave();
                setOpen(false);
              }}
              size="large"
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      )}
      onClose={noop}
    />
  );
}
