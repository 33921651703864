import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { createAdvisorArchSecurityModel } from "api/advisor_arch/securityModels";
import Spacer from "components/Common/Spacer";
import useForm from "components/Form/Form.hooks";
import { RISKS } from "models/advisor_arch/RiskCategory";
import React from "react";

interface IProps {
  open: boolean;
  onClose: () => void;
}

const CreateSecurityModelDialog = ({ open, onClose }: IProps) => {
  const { formValues, handleFormChange } = useForm({});
  const {
    formValues: securitiesValues,
    handleFormChange: handleSecuritiesValuesChange,
  } = useForm({});

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Security Model</DialogTitle>
      <DialogContent>
        <Box
          minWidth={400}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={formValues.name || ""}
            name="name"
            onChange={handleFormChange}
          />
          <Spacer height={20} />
          <TextField
            autoFocus
            margin="dense"
            id="subtitle"
            label="Subtitle"
            type="text"
            fullWidth
            variant="standard"
            value={formValues.subtitle || ""}
            name="subtitle"
            onChange={handleFormChange}
          />
          <Spacer height={20} />
          <TextField
            autoFocus
            margin="dense"
            id="landscapeImageUrl"
            label="Landscape Image Url"
            type="text"
            fullWidth
            variant="standard"
            value={formValues.landscapeImageUrl || ""}
            name="landscapeImageUrl"
            onChange={handleFormChange}
          />
          <Spacer height={20} />
          <FormControl fullWidth>
            <InputLabel id="risk">Risk</InputLabel>
            <Select
              labelId="risk"
              id="risk_select"
              value={formValues.risk || ""}
              name="risk"
              label="Risk"
              onChange={handleFormChange}
            >
              {RISKS.map((risk) => (
                <MenuItem key={risk} value={risk}>
                  {risk}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Spacer height={20} />
          <Typography variant="subtitle1" fontWeight={700}>
            Securities
          </Typography>
          <Spacer height={20} />
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body2">CUSIP: 922908769</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="922908769"
              label="Weight for 922908769"
              type="text"
              fullWidth
              variant="standard"
              value={securitiesValues["922908769"] || ""}
              name="922908769"
              onChange={handleSecuritiesValuesChange}
            />
          </Box>
          <Spacer height={20} />
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body2">CUSIP: 922907746</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="922907746"
              label="Weight for 922907746"
              type="text"
              fullWidth
              variant="standard"
              value={securitiesValues["922907746"] || ""}
              name="922907746"
              onChange={handleSecuritiesValuesChange}
            />
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <TextField
              autoFocus
              margin="dense"
              id="cusip"
              label="CUSIP"
              type="text"
              fullWidth
              variant="standard"
              value={securitiesValues.cusip || ""}
              name="cusip"
              onChange={handleSecuritiesValuesChange}
            />
            <Spacer width={12} />
            <TextField
              autoFocus
              margin="dense"
              id="cusipWeight"
              label="Weight for CUSIP"
              type="text"
              fullWidth
              variant="standard"
              value={securitiesValues.cusipWeight || ""}
              name="cusipWeight"
              onChange={handleSecuritiesValuesChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            const securities = [
              {
                cusip: "922908769",
                weight: parseFloat(securitiesValues["922908769"]),
              },
              {
                cusip: "922907746",
                weight: parseFloat(securitiesValues["922907746"]),
              },
              {
                cusip: securitiesValues.cusip,
                weight: parseFloat(securitiesValues.cusipWeight),
              },
            ];
            const form = {
              ...formValues,
              securities,
            };
            createAdvisorArchSecurityModel(form).subscribe(() => onClose());
            onClose();
          }}
          size="large"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSecurityModelDialog;
