import { createLiquidation } from "api/superAdminActions";
import useForm from "components/Form/Form.hooks";
import useACHRelationship from "components/Hooks/ACHRelationship.hooks";
import useConnectedAccounts from "components/Hooks/ConnectedAccounts.hooks";
import { find } from "lodash";
import { ApexAccountType } from "models/ApexAccount";
import { BankAccount } from "models/BankAccount";

export default function useWithdrawalDialog(
  userId: string,
  onEdit: () => void
) {
  const accounts = useConnectedAccounts(userId);
  const { formValues, handleFormChange, handleCheckboxChange } = useForm({
    type: ApexAccountType.Investment,
  });

  const relationship = useACHRelationship(userId, formValues.type);

  const correspondingConnectedAccount = find(
    accounts,
    (account: BankAccount) => {
      return !!relationship && account.itemId === relationship.itemId;
    }
  );

  const submit = () => {
    if (relationship) {
      createLiquidation(userId, {
        amount: formValues.liquidateAll ? 0 : parseFloat(formValues.amount),
        relationshipId: relationship.id,
        liquidateAll: formValues.liquidateAll,
        terminal: formValues.terminal,
      }).subscribe(onEdit);
    }
  };

  return {
    relationship,
    account: correspondingConnectedAccount,
    formValues,
    handleFormChange,
    handleCheckboxChange,
    submit,
  };
}
