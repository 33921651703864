import { InviteCode } from "models/InviteCode";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useInviteCodeSelect from "./InviteCodeSelect.hooks";

interface IProps {
  onChange: (invite: InviteCode) => void
  property: string;
  label: string;
  payingOnly?: boolean;
}

const InviteCodeSelect = ({onChange, property, label, payingOnly}: IProps) => {
  const {active, codes, setActive} = useInviteCodeSelect(onChange, payingOnly)

  return <FormControl fullWidth variant="standard">
    <InputLabel id="type">{label}</InputLabel>
    <Select
      labelId={property}
      id={property}
      value={active?.id}
      name={property}
      label={label}
      onChange={(e) => {
        setActive(codes?.find((code) => code.id === e.target.value))
      }}
    >
      {codes.map(
        (code) => (
          <MenuItem key={code.id} value={code.id}>
            {code.label}
          </MenuItem>
        )
      )}
    </Select>
  </FormControl>
}

export default InviteCodeSelect