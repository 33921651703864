import { useState } from "react";
import useForm from "../../../../../../components/Form/Form.hooks";
import { ScoutPlan } from "../../../../../../models/ScoutPlan";
import { isValidDollarString } from "../../../../../../utils/strings";
import useFundingAccount from "../../../../../../components/Hooks/FundingAccount";

export default function useUserScoutPlanDialog(scoutPlan: ScoutPlan | null, userId: string) {
  const [roundUps, setRoundUps] = useState<boolean>(scoutPlan?.roundUps ?? false);
  const {formValues, handleFormChange} = useForm({
    ...scoutPlan,
    investmentMonthlyDeposit: scoutPlan?.investmentMonthlyDeposit.toString() ?? "",
    savingsMonthlyDeposit: scoutPlan?.savingsMonthlyDeposit.toString() ?? "",
  });
  const {hasMicroDepositFundingAccount, loading} = useFundingAccount(userId);

  const isSpreadsheetUrlValid = !!formValues.spreadsheetUrl?.trim();
  const isValid = isValidDollarString(formValues.investmentMonthlyDeposit)
    && isValidDollarString(formValues.savingsMonthlyDeposit)
    && isSpreadsheetUrlValid;

  return {
    formValues,
    handleFormChange,
    hasMicroDepositFundingAccount,
    isSpreadsheetUrlValid,
    isValid,
    loading,
    roundUps,
    setRoundUps
  };
};
