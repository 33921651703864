import { DataSourceId } from "models/zuko/DataSourceId";
import { ItemType } from "models/zuko/Item";
import { Player } from "models/zuko/Player";
import { formatUTCDate } from "utils/time";

const usePlayerTable = (
  models: Player[],
  datasourceIds: DataSourceId[],
  heading?: string
) => {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
    },
    {
      field: "givenName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "familyName",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "suffix",
      headerName: "Suffix",
      width: 100,
    },
    {
      field: "birthDate",
      headerName: "Birth Date",
      valueFormatter: (params: any) => {
        return formatUTCDate(params.value, "MMMM D, YYYY");
      },
      width: 200,
    },
    {
      field: "dataSourceId",
      headerName: "Data Source ID",
      width: 200,
    },
  ];

  const getRows = () => {
    const mapping: { [id: string]: DataSourceId } = {};

    for (let i = 0; i < datasourceIds.length; i++) {
      const id = datasourceIds[i];
      if (id.itemType !== ItemType.Player) {
        continue;
      }

      mapping[id.itemId] = id;
    }

    return models.map((model) => ({
      ...model,
      dataSourceId: (mapping[model.id] || {}).dataSourceId || "-",
    }));
  };

  const getHeading = () => {
    return heading || `Players Information`;
  };

  return {
    columns,
    rows: getRows(),
    heading: getHeading(),
  };
};

export default usePlayerTable;
