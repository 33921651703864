import { getRiskProfileByUserId, getUserFinancialInfo } from "api/users";
import { FinancialInfo } from "models/RiskProfile";
import { RiskCategory } from "models/advisor_arch/RiskCategory";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

export default function useRiskProfile(userId: string) {
  const [info, setInfo] = useState<FinancialInfo>();
  const [risk, setRisk] = useState<RiskCategory>();

  const fetchInfo = fetcher(getUserFinancialInfo, [userId], setInfo);
  const fetchRisk = fetcher(
    getRiskProfileByUserId,
    [userId],
    setRisk,
    "riskProfile"
  );

  const fetchAll = () => {
    fetchInfo();
    fetchRisk();
  };

  useEffect(() => {
    fetchAll();
  }, [userId]);

  return { info, risk, fetchAll };
}
