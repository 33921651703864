import { getApexAccountByUserIds } from "api/accounts";
import { getMe } from "api/user";
import { getUsers } from "api/users";
import { debounce } from "lodash";
import { ApexAccount } from "models/ApexAccount";
import { isAdmin, KataraUser } from "models/KataraUser";
import { User } from "models/User";
import { useCallback, useEffect } from "react";
import { useState } from "react";

interface Users {
  total: number;
  value: User[];
}

const useUsersPage = () => {
  const userLimitPerPage = 10;

  const [users, setUsers] = useState<Users>({total: 0, value: []});
  const [query, setQuery] = useState<string>();
  const [page, setPage] = useState<number>(0);
  const [selected, setSelected] = useState<User>();
  const [accounts, setAccounts] = useState<ApexAccount[]>([]);
  const [admin, setAdmin] = useState<KataraUser>();

  const updateQuery = debounce((val) => setQuery(val), 700);
  const fetchUsers = useCallback((query: string, offset: number) => {
    getUsers(query, userLimitPerPage, offset).subscribe(({ data }) =>
      setUsers({
        ...data,
        value: data.users,
      })
    );
  }, []);
  const fetchAccounts = useCallback(
    (ids: string[]) =>
      getApexAccountByUserIds(ids).subscribe(({ data }) => setAccounts(data)),
    []
  );
  const fetchAdmin = useCallback(
    () => getMe().subscribe(({ data }) => setAdmin(data)),
    []
  );

  useEffect(() => {
    fetchAdmin();
  }, []);

  useEffect(() => {
    const offset = page * userLimitPerPage;
    fetchUsers(query || "", offset);
  }, [query, page, fetchUsers]);

  useEffect(() => {
    if (users && users.value.length) {
      fetchAccounts(users.value.map((user) => user.id));
    }
  }, [users.value, fetchAccounts]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onSearchChange = (val: string) => {
    updateQuery(val);
  };

  const onUserRowClick = (id: string) => {
    window.open(`/users/${id}${isAdmin(admin) ? "" : "/simplified"}`, "_self");
  };

  const onUserModalClose = () => {
    setSelected(undefined);
  };

  return {
    users: users.value,
    accounts,
    page,
    onPageChange,
    onSearchChange,
    selected,
    onUserRowClick,
    onUserModalClose,
    userCount: users.total,
    userLimitPerPage,
  };
};

export default useUsersPage;
