import DialogModal from '../../../components/Modals/DialogModal';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { noop } from 'lodash';
import EducationQuestionSelect from 'components/Form/EducationQuestionSelect/EducationQuestionSelect';
import useForm from 'components/Form/Form.hooks';
import { addQuestion } from 'api/educationSessions';
import Spacer from 'components/Common/Spacer';

interface IProps {
  onSave: () => void;
  sessionId?: string;
}

const AddPreexistingQuestionDialog = ({onSave, sessionId}: IProps) => {
  const {setForm, formValues, handleFormChange} = useForm({});
  const {questionId, priority} = formValues;

  return <DialogModal
    renderDisplay={(_, setOpen) => <Link onClick={() => setOpen(true)}>
      <Typography variant="caption">Add from pre-existing</Typography>
    </Link>}
    renderDialog={(_, setOpen) => <Box minWidth={400}>
      <Card>
        <DialogTitle>
          Add Question From Pre-existing
        </DialogTitle>
        <DialogContent>
          <EducationQuestionSelect onChange={(q) => setForm("questionId", q.id)} />
          <Spacer height={12} />
          <TextField
            margin="dense"
            id="priority"
            label="Order"
            type="number"
            fullWidth
            variant="standard"
            value={priority}
            name="priority"
            onChange={handleFormChange}
          />
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="contained" onClick={() => {
            if (sessionId) {
              addQuestion(sessionId, questionId, parseInt(priority)).subscribe(() => {
                setOpen(false);
                onSave();
              })
            }
          }}>Save</Button>
        </DialogActions>
      </Card>
    </Box>}
    onClose={noop}
    />
}

export default AddPreexistingQuestionDialog