import { getInviteCodes } from "api/inviteCodes";
import { InviteCode } from "models/InviteCode";
import { useEffect, useState } from "react";

const useInviteCodes = () => {
  const [inviteCodes, setInviteCodes] = useState<InviteCode[]>([]);

  useEffect(() => {
    getInviteCodes().subscribe(({ data }) => setInviteCodes(data));
  }, []);

  return { inviteCodes };
};

export default useInviteCodes;
