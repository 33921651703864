import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ReceiptOutlined,
  SpaceDashboard,
  AccountCircle,
  AccountCircleOutlined,
  DateRangeOutlined,
  DateRange,
  SpaceDashboardOutlined,
  PeopleOutlined,
  People,
  PieChart,
  PieChartOutlined,
  PersonAddOutlined,
  NotificationsOutlined,
  Notifications,
  CloudUploadOutlined,
  CloudUpload,
  SettingsOutlined,
  Settings,
  FolderOutlined,
  Folder,
  CheckCircleOutlined,
  CheckCircle,
  Receipt,
  Discount,
  DiscountOutlined,
  PersonAdd,
  CheckBoxOutlined,
  CheckBox,
} from "@mui/icons-material";
import { capitalize } from "lodash";
import { useLocation } from "react-router-dom";
import { isAdmin } from "models/KataraUser";
import { getMe } from "api/user";
import { NavLink } from "react-router-dom";

const drawerWidth = 250;

interface IMenuItem {
  text: string;
  Icon: any;
  SelectedIcon: any;
  path: string;
  showDividerBelow: boolean;
}

const createMenuItem = (text: string, Icon: any, SelectedIcon: any, path: string, showDividerBelow: boolean = false) => ({
  text,
  Icon,
  SelectedIcon,
  path,
  showDividerBelow,
});

const MenuDrawer = () => {
  const location = useLocation();
  const [items, setItems] = useState<IMenuItem[]>([]);

  useEffect(() => {
    getMe().subscribe(({data}) => {
      const hasAdminRole = isAdmin(data);
      setItems(
        [
          createMenuItem("Users", AccountCircleOutlined, AccountCircle, "/users"),
          createMenuItem("Chat", PeopleOutlined, People, "/chat"),
          createMenuItem("Education Sessions", DateRangeOutlined, DateRange, "/education_sessions"),
          createMenuItem("Scheduled Events", DateRangeOutlined, DateRange, "/calendly_events"),
          createMenuItem("Scout Plans", SpaceDashboardOutlined, SpaceDashboard, "/scout_plans", true),
        ]
          .concat(
            hasAdminRole
              ? [createMenuItem("Security Models", PieChartOutlined, PieChart, "/security_models")]
              : []
          )
          .concat([
            createMenuItem("Invite Codes", PersonAddOutlined, PersonAdd, "/invite_codes"),
            createMenuItem("Promo Codes", DiscountOutlined, Discount, "/promo_codes"),
            createMenuItem("Notifications", NotificationsOutlined, Notifications, "/notifications"),
            createMenuItem("Upload", CloudUploadOutlined, CloudUpload, "/upload", true),
          ])
          .concat(
            hasAdminRole
              ? [
                createMenuItem("Feature Flags", SettingsOutlined, Settings, "/feature_flags"),
                createMenuItem("SOD Files", FolderOutlined, Folder, "/sod_files"),
                createMenuItem("Onfido Check Files", FolderOutlined, Folder, "/check_files"),
                createMenuItem("Officer Compliance", CheckBoxOutlined, CheckBox, "/officer_compliance", true)
              ]
              : []
          )
          .concat([
            createMenuItem(
              "Model Validation",
              CheckCircleOutlined,
              CheckCircle,
              "/pending_validation_items"
            ),
            createMenuItem("Transactions", ReceiptOutlined, Receipt, "/transactions"),
            // createMenuItem(
            //   "Model Validation - Failed Items",
            //   AssignmentTurnedIn,
            //   "/failed_items"
            // ),
            // createMenuItem(
            //   "Model Player Award Types",
            //   EmojiEventsRounded,
            //   "/player_award_types"
            // ),
          ])
      );
    });
  }, []);

  return (
    <Box borderRight="1px solid #E5E5E5" width={drawerWidth} bgcolor="#fff">
      <Box
        display="flex"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        paddingTop="16px"
        paddingLeft="16px"
        paddingBottom="16px"
        flexDirection="row"
      >
        <img src="/images/logo.png" width="30px" alt="logo"/>
        <Box marginLeft="12px">
          <Typography variant="body1">
            Katara ({capitalize(process.env.REACT_APP_ENV)})
          </Typography>
        </Box>
      </Box>
      <Divider/>
      <List>
        {items.map(({text, Icon, SelectedIcon, path, showDividerBelow}) => (
          <>
            <NavLink
              to={path}
              key={path}
              replace={true}
              style={{textDecoration: "none", color: "inherit"}}
            >
              <ListItem
                key={text}
                sx={
                  path === location.pathname
                    ? {
                      backgroundColor: "#E3F2FD",
                      color: "#2196F3",
                    }
                    : undefined
                }
              >
                <ListItemIcon
                  style={{
                    color: path === location.pathname ? "#2196F3" : undefined,
                  }}
                >
                  {path === location.pathname ? <SelectedIcon/> : <Icon/>}
                </ListItemIcon>
                <ListItemText primary={text}/>
              </ListItem>
            </NavLink>
            {showDividerBelow && <Divider/>}
          </>
        ))}
      </List>
    </Box>
  );
};

export default MenuDrawer;
