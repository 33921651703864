import { cancelACHRelationship } from "api/achRelationships";
import { getLinkedAccountsByUserId } from "api/plaid";
import { BankAccount } from "models/BankAccount";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

export default function useUserConnectedAccounts(userId: string) {
  const [accounts, setAccounts] = useState<BankAccount[]>();
  const [open, setOpen] = useState<BankAccount>();

  const fetchAccounts = fetcher(
    getLinkedAccountsByUserId,
    [userId],
    setAccounts
  );

  const cancelRelationship = (account: BankAccount) => {
    if (account.relationshipId) {
      cancelACHRelationship(userId, account.relationshipId);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [userId]);

  return {
    accounts,
    cancelRelationship,
    open,
    setOpen,
  };
}
