import { Route, Routes } from "react-router-dom";
import PendingValidationItemListPage from "./PendingValidationItemListPage/PendingValidationItemListPage";
import PendingValidationItemDetailPage from "./PendingValidationItemDetailPage/PendingValidationItemDetailPage";

export default function PendingValidationItemsRoutes() {
  return (
    <Routes>
      <Route
        key="pending-validation-items"
        path="/"
        element={<PendingValidationItemListPage />}
      />
      <Route
        key="pending-validation-item-detail"
        path="/:id"
        element={<PendingValidationItemDetailPage />}
      />
    </Routes>
  );
}
