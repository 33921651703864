export const fetcher = (
  getter: Function,
  args: any[],
  setter: Function,
  property?: string,
  callback?: () => void
) => {
  return () =>
    getter(...args).subscribe(({ data }: any) => {
      setter(property ? data[property] : data);
      callback && callback();
    });
};
