import { Link } from "@mui/material";
import { getFailedVerificationItems } from "api/zuko/failedVerificationItems";
import StatusBadge from "components/Badges/StatusBadge";
import { FailedVerificationItem } from "models/zuko/FailedVerificationItem";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";
import { ItemType } from "models/zuko/Item";

const useFailedItemsListPage = () => {
  const [items, setItems] = useState<FailedVerificationItem[]>([]);

  const fetchItems = fetcher(getFailedVerificationItems, [], setItems);

  useEffect(() => {
    fetchItems();
  }, []);

  const columns = [
    {
      field: "itemType",
      headerName: "Item Type",
      flex: 1,
      zeroMinWidth: true,
    },
    {
      field: "linkId",
      headerName: "Link ID",
      flex: 1,
      zeroMinWidth: true,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params: any) => (
        <StatusBadge
          status={
            params.row.manualConfirmedAt
              ? "Confirmed"
              : params.row.manualFailedAt
              ? "Failed Verification"
              : "Needs Review"
          }
        />
      ),
      valueGetter: (params: any) => {
        return params.row.manualConfirmedAt || params.row.manualFailedAt
          ? "Reviewed"
          : "Needs Review";
      },
      width: 200,
      zeroMinWidth: true,
    },
    {
      field: "link",
      headerName: "Link",
      width: 150,
      renderCell: (params: any) => (
        <Link
          href={`/failed_items/${params.row.id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          target="_blank"
        >
          Link
        </Link>
      ),
    },
  ];

  return { items, columns };
};

export default useFailedItemsListPage;
