import { ApexAccountType } from "models/ApexAccount";
import { get, post } from "./base";
import { ApexCashJournal } from "models/ApexTransfer";

export const getApexACHTransfersByUserId = (
  id: string,
  type: ApexAccountType
) => {
  return get(`/admin/users/${id}/apex/ach_transfers/list`, {
    type,
    include: "credits",
  });
};

export const getRecurringDepositsByUserId = (
  id: string,
  type: ApexAccountType
) => {
  return get(`/admin/users/${id}/apex/deposits/recurring`, { type });
};

export const credit = (
  id: string,
  amount: number,
  description: string,
  type: string,
  accountType: string
) => {
  return post(`/admin/users/${id}/apex/credits`, true, {
    amount,
    description,
    type,
    accountType,
  });
};

export const createCashJournalTransfer = (
  id: string,
  cashJournalTransfer: ApexCashJournal
) => {
  return post(
    `/admin/users/${id}/apex/cash_journal_transfers`,
    true,
    cashJournalTransfer
  );
};

export const getACHTransferInfo = (
  id: string,
  type: string,
  transferId: string
) => {
  return get(
    `/admin/users/${id}/apex/ach_transfers/${type}s/${transferId}/info`
  );
};

export const retryLiquidation = (id: string, liquidationId: string) => {
  return post(`/admin/users/${id}/apex/liquidations/${liquidationId}/retry`);
};
