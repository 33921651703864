import { Paper, TableContainer, Typography } from "@mui/material";
import CustomTable from "components/Table/CustomTable";
import useNotifications from "./Notifications.hooks";
import Loader from "components/Common/Loader";

interface IProps {
  userId: string;
}

export default function Notifications({ userId }: IProps) {
  const { notifs } = useNotifications(userId);

  if (!notifs) {
    return <Loader />;
  }

  const rows = notifs.map((notif) => ({
    cells: [
      { value: notif.createdAt },
      {
        renderCell: () => (
          <>
            <Typography variant="body2" fontWeight="bold">{notif.title}</Typography>
            <Typography variant="body2">{notif.body}</Typography>
          </>
        ),
      },
    ],
  }));

  return (
    <TableContainer component={Paper}>
      <CustomTable headings={["Date", "Message", ""]} rows={rows} />
    </TableContainer>
  );
}
