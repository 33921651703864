import useForm from "../../../../../components/Form/Form.hooks";

export default function useAddNoteDialog() {
  const {formValues, handleFormChange} = useForm({});

  return {
    formValues,
    handleFormChange,
    isNoteValid: !!formValues.note?.trim(),
    isSubjectValid: !!formValues.subject?.trim(),
  };
}
