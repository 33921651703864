import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Spacer from "components/Common/Spacer";
import useForm from "components/Form/Form.hooks";
import { map, snakeCase } from "lodash";
import {
  FinancialInfo,
  getAnnualIncomeOptions,
  getInvestmentAlternativeOptions,
  getInvestmentHoldingOptions,
  getInvestmentLossReactionOptions,
  getInvestmentObjectiveOptions,
  getNetWorthOptions,
} from "models/RiskProfile";
import { useEffect } from "react";

interface IProps {
  info: FinancialInfo;
  onChange: (info: FinancialInfo) => void;
}

export default function RiskProfileForm({ info, onChange }: IProps) {
  const { formValues, handleFormChange } = useForm(info);

  useEffect(() => {
    onChange(formValues);
  }, [formValues, onChange]);

  return (
    <Box width="100%">
      <FormControl variant="standard" fullWidth>
        <InputLabel id="investment-objective">Investment Goal</InputLabel>
        <Select
          labelId="investment-objective"
          value={formValues.investmentObjective}
          onChange={handleFormChange}
          name="investmentObjective"
          label="Investment Goal"
        >
          {getInvestmentObjectiveOptions().map((option) => (
            <MenuItem value={snakeCase(option).toUpperCase()}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Spacer height={20} />
      <FormControl variant="standard" fullWidth>
        <InputLabel id="alternative-to-investing">
          Alternative To Investing
        </InputLabel>
        <Select
          labelId="alternative-to-investing"
          value={formValues.investmentAlternative}
          onChange={handleFormChange}
          name="investmentAlternative"
          label="Alternative To Investing"
        >
          {getInvestmentAlternativeOptions().map((option) => (
            <MenuItem value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Spacer height={20} />
      <FormControl variant="standard" fullWidth>
        <InputLabel id="investment-holding">Time Horizon</InputLabel>
        <Select
          labelId="investment-holding"
          value={formValues.investmentHolding}
          onChange={handleFormChange}
          name="investmentHolding"
          label="Investment Goal"
        >
          {map(getInvestmentHoldingOptions(), (value, key) => (
            <MenuItem value={value}>{key}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Spacer height={20} />
      <FormControl variant="standard" fullWidth>
        <InputLabel id="loss-tolerance">
          Loss Tolerance (in event of 5% drop)
        </InputLabel>
        <Select
          labelId="loss-tolerance"
          value={formValues.investmentLossReaction}
          onChange={handleFormChange}
          name="investmentLossReaction"
          label="Loss Tolerance (in event of 5% drop)"
        >
          {getInvestmentLossReactionOptions().map((option) => (
            <MenuItem value={snakeCase(option).toUpperCase()}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Spacer height={20} />
      <FormControl variant="standard" fullWidth>
        <InputLabel id="annual-income-range">Annual Income</InputLabel>
        <Select
          labelId="annual-income-range"
          value={formValues.annualIncomeRange}
          onChange={handleFormChange}
          name="annualIncomeRange"
          label="Annual Income"
        >
          {getAnnualIncomeOptions().map((option) => (
            <MenuItem value={option.replace("from", "").replace("To", "-")}>
              {option.replace("from", "").replace("To", "-")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Spacer height={20} />
      <FormControl variant="standard" fullWidth>
        <InputLabel id="net-worth-range">Net Worth</InputLabel>
        <Select
          labelId="net-worth-range"
          value={formValues.netWorthRange}
          onChange={handleFormChange}
          name="netWorthRange"
          label="Net Worth"
        >
          {getNetWorthOptions().map((option) => (
            <MenuItem value={option.replace("from", "").replace("To", "-")}>
              {option.replace("from", "").replace("To", "-")}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
