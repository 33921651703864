import { ReactNode, useState } from "react";
import { Typography } from "@mui/material";

interface IProps {
  children: ReactNode;
}

const ExpandableText = ({children}: IProps) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  return <Typography onClick={() => setExpanded(true)} sx={{
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: isExpanded ? undefined : 5,
    WebkitBoxOrient: 'vertical'
  }}>{children}</Typography>;
};

export default ExpandableText;
