import Page from "components/Common/Page";
import React from "react";
import useUsersPage from "./UsersPage.hooks";
import { Box, Input, Paper, Avatar, Link } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams
 } from "@mui/x-data-grid";
import moment from "moment";
import { find } from "lodash";
import StatusBadge from "components/Badges/StatusBadge";

const columns: GridColDef[] = [
  { field: "phoneNumber", headerName: "Phone number", width: 230 },
  {
    field: "profileUrl",
    headerName: "Avatar",
    width: 100,
    renderCell: (params: GridRenderCellParams
) => (
      <Avatar
        alt={params.row.id}
        src={params.row.profileUrl}
        sx={{ width: 24, height: 24 }}
      />
    ),
  },
  { field: "givenName", headerName: "First name", width: 290 },
  { field: "familyName", headerName: "Last name", width: 290 },
  { field: "inviteCodeUsed", headerName: "Join Code", width: 200 },
  {
    field: "requestState",
    headerName: "Account request state",
    width: 290,
    renderCell: (params: GridRenderCellParams
) =>
      params.value ? <StatusBadge status={params.value} /> : <Box>N/A</Box>,
  },
  {
    field: "createdAt",
    headerName: "Join date",
    width: 290,
    valueGetter: (params: GridRenderCellParams
) =>
      moment(params.row.createdAt).format("MM/DD/YYYY"),
  },
  {
    field: "id",
    headerName: "Detail View",
    width: 200,
    renderCell: (params: GridRenderCellParams
) =>
      params.value ? (
        <Link
          href={`/users/${params.value}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          target="_blank"
        >
          Link
        </Link>
      ) : (
        <Box>N/A</Box>
      ),
  },
];

const UsersPage = () => {
  const {
    page,
    onPageChange,
    onSearchChange,
    users,
    onUserRowClick,
    accounts,
    userCount,
    userLimitPerPage,
  } = useUsersPage();
  const rows = (users || []).map((user, i) => ({
    ...user,
    requestState: (
      find(accounts, (account) => account.userId === user.id) || {}
    ).requestState,
  }));

  return (
    <Page
      heading="Users"
      RightHeadingComponent={
        <Box minWidth={250}>
          <Input
            fullWidth
            onChange={(e) => onSearchChange(e.currentTarget.value)}
            placeholder="Search phone (555-555-5555)"
          />
        </Box>
      }
    >
      <Paper>
        <DataGrid
          rows={rows}
          columns={columns}
          page={page}
          pageSize={userLimitPerPage}
          paginationMode="server"
          rowCount={userCount}
          autoHeight
          rowsPerPageOptions={[]}
          onRowClick={(params) => onUserRowClick(params.row.id)}
          onPageChange={(page, details) => onPageChange(page)}
        />
      </Paper>
    </Page>
  );
};

export default UsersPage;
