import useUserScoutPlan from "./UserScoutPlan.hooks";
import Loader from "../../../../../../components/Common/Loader";
import React from "react";
import { Card, CardActions, CardContent, Chip, Link, Typography } from "@mui/material";
import UserScoutPlanDialog from "./UserScoutPlanDialog";
import StatusBadge from "../../../../../../components/Badges/StatusBadge";

interface IProps {
  userId: string;
}

const UserScoutPlan = ({userId}: IProps) => {
  const {onSave, scoutPlan} = useUserScoutPlan(userId);

  if (typeof scoutPlan === 'undefined') {
    return <Loader/>;
  }

  return (
    <Card>
      {scoutPlan && (
        <CardContent>
          <Typography color="gray" variant="body2">
            <Typography component="span" fontWeight="bold">Investing Monthly Deposit</Typography><br/>
            ${scoutPlan.investmentMonthlyDeposit} per month<br/>
            <br/>
            <Typography component="span" fontWeight="bold">Savings Monthly Deposit</Typography><br/>
            ${scoutPlan.savingsMonthlyDeposit} per month<br/>
            <br/>
            <Typography component="span" fontWeight="bold">Round Ups</Typography><br/>
            {scoutPlan.roundUps ? "Enabled" : "Disabled"}<br/>
            {scoutPlan.spreadsheetUrl && (<>
              <br/>
              <Typography component="span" fontWeight="bold">URL to Scout Plan</Typography><br/>
              <Link href={scoutPlan.spreadsheetUrl} target="_blank">{scoutPlan.spreadsheetUrl}</Link><br/>
            </>)}
            {scoutPlan.notes && (<>
              <br/>
              <Typography component="span" fontWeight="bold">Additional Notes</Typography><br/>
              {scoutPlan.notes}<br/>
            </>)}
            <br/>
            <Typography component="span" fontWeight="bold">Initiated?</Typography><br/>
            {scoutPlan.initiated ? <Chip color="success" label="Yes"/> : <Chip color="error" label="No"/>}<br/>
            {scoutPlan.initiated && (<>
              <br/>
              <Typography component="span" fontWeight="bold">Current Status</Typography><br/>
              <StatusBadge status={scoutPlan.enabled ? "Enabled" : "Needs Attention"}/><br/>
            </>)}
          </Typography>
        </CardContent>
      )}
      <CardActions>
        <UserScoutPlanDialog
          displayButtonText={scoutPlan ? 'Edit' : 'Add Scout Plan'}
          scoutPlan={scoutPlan}
          onSave={onSave}
          userId={userId}/>
      </CardActions>
    </Card>
  );
};

export default UserScoutPlan;
