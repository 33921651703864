import AWS from "aws-sdk";
import { from } from "rxjs";

const endpoint = new AWS.Endpoint("https://s3-us-west-1.amazonaws.com/");
const BUCKET_NAME = "elevate.prototype";

const s3Service = new AWS.S3({
  endpoint,
  accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
  secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
  region: "us-west-1",
});

export const uploadFile = (
  file: File,
  directory: string,
  contentType: string
) => {
  return from(
    s3Service
      .upload({
        Key: `${directory}${file.name}`,
        Bucket: BUCKET_NAME,
        ACL: "public-read",
        Body: file,
        ContentType: contentType,
      })
      .promise()
  );
};

export const getS3Files = (folder: string) => {
  return from(
    s3Service
      .listObjectsV2({
        Bucket: BUCKET_NAME,
        Prefix: folder,
      })
      .promise()
  );
};

export const getS3LinkUrl = (key: string) => {
  const s3LinkService = new AWS.S3({
    endpoint: new AWS.Endpoint("https://s3.amazonaws.com/"),
    accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
    secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
    region: "us-east-1",
  });

  return from(
    s3LinkService.getSignedUrlPromise("getObject", {
      Bucket: process.env.REACT_APP_LINK_S3_BUCKET!,
      Key: key,
    })
  );
};
