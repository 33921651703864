import DialogModal from "components/Modals/DialogModal";
import React from "react";
import {
  Box,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import moment from "moment";
import useForm from "components/Form/Form.hooks";
import { updateUser } from "api/users";

interface IProps {
  birthDate: string;
  userId: string;
  onClose: () => void;
}

const BirthdateDialog = ({ birthDate, userId, onClose }: IProps) => {
  const { formValues, handleFormChange } = useForm({ birthDate });
  const onSave = () => {
    updateUser(userId, { birthDate: formValues.birthDate });
  };

  return (
    <DialogModal
      onClose={onClose}
      renderDisplay={(open, setOpen) => (
        <Box
          onClick={() => setOpen(true)}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
        >
          <Typography variant="body2">
            {moment(birthDate).utc().format("MM/DD/YYYY")}
          </Typography>
        </Box>
      )}
      renderDialog={(open, setOpen) => (
        <Box minWidth={400}>
          <DialogTitle>Edit Birthday</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="birthDate"
              label="Birthday"
              type="text"
              fullWidth
              variant="standard"
              value={formValues.birthDate}
              name="birthDate"
              onChange={handleFormChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onSave();
                setOpen(false);
              }}
              size="large"
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      )}
    />
  );
};

export default BirthdateDialog;
