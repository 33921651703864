import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Page from "components/Common/Page";
import Spacer from "components/Common/Spacer";
import useChat from "components/Hooks/Chat.hooks";
import { Chat } from "stream-chat-react";
import ChatList from "./ChatList";

export default function ChatPage() {
  const { client } = useChat();

  return (
    <Page noStyling>
      <Box
        display="flex"
        flexDirection="row"
        padding="3vh 2.5vw"
        alignItems="flex-start"
      >
        {client && (
          <Chat client={client}>
            <Box width="100%">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Avatar />
                <Spacer width={16} />
                <Typography variant="h5" fontWeight="bold">
                  Advisor Chats
                </Typography>
              </Box>
              <Spacer height={24} />
              <ChatList />
            </Box>
          </Chat>
        )}
      </Box>
    </Page>
  );
}
