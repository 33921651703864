import { NotificationTargetingType } from "api/notifications";
import {
  NotificationAppVersionTargeting,
  NotificationInviteCodeTargeting,
} from "models/Notifications";

export interface INotificationForm {
  title: string;
  body: string;
  action: string;
  link?: string;
  userIds?: string;
  inviteCode?: NotificationInviteCodeTargeting;
  type: NotificationTargetingType;
  appVersion?: NotificationAppVersionTargeting;
}

export const INITIAL_NOTIFICATION_FORM = {
  title: "",
  body: "",
  action: "",
  type: NotificationTargetingType.UserIds,
  userIds: "",
};

export enum ActionType {
  UpdateTitle = "update title",
  UpdateBody = "update body",
  UpdateLink = "update link",
  UpdateUserIds = "update userIds",
  UpdateAction = "update action",
  UpdateCode = "update code",
  TogglePrefix = "toggle prefix",
  UpdateTargetingType = "update targeting type",
  UpdateMinimum = "update minimum",
  UpdateMaximum = "update maximum",
}

interface Action {
  type: ActionType;
  data: any;
}

const notificationReducer = (form: INotificationForm, action: Action) => {
  const newForm = { ...form };

  switch (action.type) {
    case ActionType.UpdateTitle:
      newForm.title = action.data;
      break;
    case ActionType.UpdateBody:
      newForm.body = action.data;
      break;
    case ActionType.UpdateLink:
      newForm.link = action.data;
      break;
    case ActionType.UpdateUserIds:
      newForm.userIds = action.data;
      break;
    case ActionType.UpdateAction:
      newForm.action = action.data;
      break;
    case ActionType.UpdateCode:
      newForm.inviteCode = {
        code: action.data,
        isPrefix: newForm.inviteCode?.isPrefix || false,
      };
      break;
    case ActionType.TogglePrefix:
      newForm.inviteCode = {
        code: newForm.inviteCode?.code || "",
        isPrefix: !(newForm.inviteCode?.isPrefix || false),
      };
      break;
    case ActionType.UpdateTargetingType:
      newForm.type = action.data;
      break;
    case ActionType.UpdateMinimum:
      newForm.appVersion = {
        minimum: action.data,
        maximum: newForm.appVersion?.maximum || undefined,
      };
      break;
    case ActionType.UpdateMaximum:
      newForm.appVersion = {
        minimum: newForm.appVersion?.minimum || undefined,
        maximum: action.data,
      };
      break;
  }

  return newForm;
};

export default notificationReducer;
