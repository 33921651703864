import { getRecurringDepositsByUserId } from "api/transfers";
import { ApexAccountType } from "models/ApexAccount";
import { RecurringDeposit } from "models/ApexTransfer";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

export default function useRecurringDeposits(
  userId: string,
  type: ApexAccountType,
  match?: (deposit: RecurringDeposit) => boolean
) {
  const [deposits, setDeposits] = useState<RecurringDeposit[]>();

  const fetchDeposits = fetcher(
    getRecurringDepositsByUserId,
    [userId, type],
    setDeposits
  );

  useEffect(() => {
    fetchDeposits();
  }, [userId, type]);

  const deposit = match && (deposits || []).find(match);

  return {
    deposit,
    deposits,
    fetchDeposits,
  };
}
