import { getAdvisor } from "api/chat";
import Loader from "components/Common/Loader";
import Page from "components/Common/Page";
import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import ChatChannel from "./ChatChannel";

const match = "/chat/:id";

export default function ChatChannelPage() {
  const m = useMatch(match);
  const id = m?.params.id || "";
  const [advisorId, setAdvisorId] = useState("");

  useEffect(() => {
    if (id) {
      const split = id.split("-advisor-channel");
      if (split.length > 1) {
        getAdvisor(split[0]).subscribe((resp) => {
          if (resp) {
            setAdvisorId(resp.data.userId);
          }
        });
      }
    }
  }, [id]);

  if (!id || !advisorId) {
    return <Loader />;
  }

  return (
    <Page>
      <ChatChannel id={id} advisorId={advisorId} />
    </Page>
  );
}
