import DialogModal from '../../../components/Modals/DialogModal';
import { noop } from 'lodash';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import Spacer from '../../../components/Common/Spacer';
import React from 'react';
import useForm from '../../../components/Form/Form.hooks';
import { zipAll } from 'rxjs';
import { addQuestion, createQuestion } from '../../../api/educationSessions';
import { EducationSessionQuestion } from '../../../models/EducationSession';
import AddPreexistingQuestionDialog from './AddPreexistingQuestionDialog';

interface IProps {
  onSave:  () => void;
  sessionId?: string;
}

const AddQuestionDialog = ({onSave, sessionId}: IProps) => {
  const { handleFormChange, formValues, setForm } = useForm({});
  const { formUrl, title, priority } = formValues;

  return <DialogModal
    renderDialog={(_, setOpen) =>
      <Box minWidth={400}>
        <Card>
          <DialogTitle>
            <Box display="flex" flexDirection="column">
              Add Question
              <AddPreexistingQuestionDialog onSave={() => {
                onSave();
                setOpen(false);
              }} sessionId={sessionId} />
            </Box>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="formUrl"
              label="Form URL"
              type="text"
              fullWidth
              variant="standard"
              value={formUrl}
              name="formUrl"
              onChange={handleFormChange}
            />
            <Spacer height={12} />
            <TextField
              margin="dense"
              id="title"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              value={title}
              name="title"
              onChange={handleFormChange}
            />
            <Spacer height={12} />
            <TextField
              margin="dense"
              id="priority"
              label="Order"
              type="number"
              fullWidth
              variant="standard"
              value={priority}
              name="priority"
              onChange={handleFormChange}
            />
          </DialogContent>
          <DialogActions>
            <Button fullWidth variant="contained" onClick={() => {
              if (sessionId) {
                createQuestion(formUrl, title).subscribe(({ data }) => {
                  addQuestion(sessionId, data.id, parseInt(priority)).subscribe(() => {
                    setOpen(false);
                    onSave();
                  });
                })
              }
            }}>Save</Button>
          </DialogActions>
        </Card>
      </Box>}
    renderDisplay={(_, setOpen)=> (
      <Button variant="contained" onClick={()=> setOpen(true)}>
        Add Question
      </Button>)}
    onClose={noop}
    />
}

export default AddQuestionDialog