import { Box, Switch } from "@mui/material";
import React from "react";
import { FeatureFlag } from "api/featureFlags";

interface IProps {
  flag: FeatureFlag;
  onClick: (flag: FeatureFlag) => void;
  onChange: (flag: FeatureFlag) => void;
}

const FeatureFlagRow = ({ flag, onClick, onChange }: IProps) => {

  return (
    <Box
      paddingY="12px"
      paddingLeft="24px"
      paddingRight="24px"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        "&:hover": {
          backgroundColor: "#E3F2FD",
          cursor: "pointer",
        },
      }}
      onClick={() => onClick(flag)}
    >
      <Box
        flex={1}
        textAlign="left"
        marginLeft="16px"
        display="flex"
        flexDirection="column"
      >
        <Box fontWeight="bold" marginBottom="4px">
          {flag.title}
        </Box>
      </Box>
      <Box>
        <Switch checked={flag.enabled} onChange={() => onChange(flag)} />
      </Box>
    </Box>
  );
};

export default FeatureFlagRow;
