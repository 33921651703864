import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { formatUTCDate } from "utils/time";
import { InvestmentTransaction } from "../../../models/plaid/Transaction";

interface IProps {
  close: () => void;
  model: InvestmentTransaction;
}

export default function TransactionDetailsDialog({close, model}: IProps) {
  const textSection = (title: string, subtitle: string, addTrailingLineBreak: boolean = true) => {
    return (
      <>
        <b>{title}</b><br/>
        {subtitle}<br/>
        {addTrailingLineBreak && <br/>}
      </>
    );
  };

  let type = model.type;
  if (model.subtype !== model.type) {
    type += ` - ${model.subtype}`;
  }

  const currency = model.isoCurrencyCode ?? model.unofficialCurrencyCode!;

  return (
    <>
      <DialogTitle>Transaction Detail</DialogTitle>
      <DialogContent>
        <Typography fontSize={16}>
          {textSection("Date", formatUTCDate(model.date, "MMMM D, YYYY"))}
          {textSection("Type", type.toUpperCase())}
          {textSection("Transaction", model.name)}
          {textSection("Quantity", model.quantity.toPrecision(20))}
          {textSection("Amount", `${model.amount} ${currency}`)}
          {textSection("Price", `${model.price.toFixed(10)} ${currency}`, false)}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <Button onClick={close}>Ok</Button>
      </DialogActions>
    </>
  );
}
