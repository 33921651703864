import DialogModal from "../../../../../../components/Modals/DialogModal";
import { Edit } from "@mui/icons-material";
import { Box, Button, DialogContent, DialogTitle } from "@mui/material";
import Spacer from "../../../../../../components/Common/Spacer";
import { noop } from "lodash";
import { ScoutPlan } from "../../../../../../models/ScoutPlan";
import useUserScoutPlanDialog from "./UserScoutPlanDialog.hooks";
import ScoutPlanForm from "../../../../../../components/Form/ScoutPlanForm";
import Loader from "../../../../../../components/Common/Loader";

export interface UserScoutPlanDialogResult {
  investmentMonthlyDeposit: string;
  notes?: string;
  savingsMonthlyDeposit: string;
  spreadsheetUrl?: string;
  roundUps: boolean;
}

interface IProps {
  displayButtonText: string;
  onSave: (result: UserScoutPlanDialogResult) => void;
  scoutPlan: ScoutPlan | null;
  userId: string;
}

const UserScoutPlanDialog = ({displayButtonText, onSave, scoutPlan, userId}: IProps) => {
  const {
    formValues,
    handleFormChange,
    hasMicroDepositFundingAccount,
    isSpreadsheetUrlValid,
    isValid,
    loading,
    roundUps,
    setRoundUps
  } = useUserScoutPlanDialog(scoutPlan, userId);

  return (
    <DialogModal
      renderDisplay={(open, setOpen) => (
        <Button onClick={() => setOpen(true)} startIcon={<Edit/>}>
          {displayButtonText}
        </Button>
      )}
      renderDialog={(open, setOpen) => (
        <Box minWidth={400}>
          <DialogTitle>Scout Plan</DialogTitle>
          <DialogContent>
            {loading
              ? <Loader/>
              : (
                <>
                  <ScoutPlanForm
                    disableRoundUps={hasMicroDepositFundingAccount!}
                    formValues={formValues}
                    handleFormChange={handleFormChange}
                    roundUps={roundUps}
                    setRoundUps={setRoundUps}
                    showURLRequiredAlert={!isSpreadsheetUrlValid}/>
                  <Spacer height={20}/>
                  <Button
                    disabled={!isValid}
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      onSave({...formValues, roundUps});
                      setOpen(false);
                    }}
                    size="large">
                    Save
                  </Button>
                </>
              )}
          </DialogContent>
        </Box>
      )}
      onClose={noop}
    />
  );
};

export default UserScoutPlanDialog;
