import { getApexAccountByUserId } from "api/accounts";
import { ApexAccount, ApexAccountType } from "models/ApexAccount";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

export default function useAccountOverview(
  userId: string,
  type: ApexAccountType
) {
  const [account, setAccount] = useState<ApexAccount>();

  const fetchAccount = fetcher(
    getApexAccountByUserId,
    [userId, type],
    setAccount
  );

  useEffect(() => {
    fetchAccount();
  }, [userId, type]);

  return {
    account,
  };
}
