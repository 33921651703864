import { Box, Button, Typography } from "@mui/material";
import Page from "components/Common/Page";
import NotificationForm from "components/Form/NotificationForm/NotificationForm";
import React from "react";
import useCreateNotificationPage from "./CreateNotificationPage.hooks";

const CreateNotificationPage = () => {
  const { onCreateClick, isValid, onFormChange } = useCreateNotificationPage();

  return (
    <Page>
      <Box
        display="flex"
        flexDirection="column"
        padding="16px"
        alignItems="flex-start"
      >
        <Box marginTop="40px" marginBottom="32px">
          <Typography variant="h5">Create Notification</Typography>
        </Box>
        <NotificationForm onChange={onFormChange} />
        <Box
          display="flex"
          justifyContent="flex-end"
          width="100%"
          marginTop="32px"
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!isValid}
            onClick={onCreateClick}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Page>
  );
};

export default CreateNotificationPage;
