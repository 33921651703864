import { get, patch, post } from "./base";

export const createInviteCode = (
  label: string,
  programId: string,
  paying: boolean,
  ownerUserId?: string
) => {
  return post("/admin/invite_codes", true, {
    label,
    programId,
    paying,
    ownerUserId,
  });
};

export const getInviteCodes = () => {
  return get("/admin/invite_codes");
};

export const activateInviteCode = (label: string, date: string) => {
  return post(`/admin/invite_codes/${label}`, true, { date });
};

export const attributeInviteCode = (id: string, ownerUserId: string) => {
  return patch(`/admin/invite_codes/${id}`, true, { ownerUserId });
};

export const getInviteCodePrograms = () => {
  return get(`/admin/invite_code_programs`);
};
