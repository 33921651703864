import { ItemType } from "./Item";
import { capitalize } from "lodash";

export interface PendingValidationItem {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  itemId: string;
  itemType: ItemType;
  model: any;
  validatedAt: Date;

  failedAt?: Date;
  failureReason?: string;
}

export const getPendingValidationItemName = (item: PendingValidationItem) => {
  switch (item.itemType) {
    case ItemType.Link:
      return `${item.model.dataSource}_${item.model.type}_link`
        .split("_")
        .map(capitalize)
        .join(" ");
  }

  return "";
};
