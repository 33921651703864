import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  capitalize,
} from "@mui/material";
import Spacer from "components/Common/Spacer";
import useIntraTransferDialog from "./IntraTransferDialog.hooks";

interface IProps {
  userId: string;
  onEdit: () => void;
}

export default function WithdrawalDialog({ userId, onEdit }: IProps) {
  const { accounts, formValues, handleFormChange, submit } =
    useIntraTransferDialog(userId, onEdit);

  return (
    <Box minWidth={400}>
      <DialogTitle>Make an intra account transfer</DialogTitle>
      <DialogContent>
        <FormControl fullWidth variant="standard">
          <InputLabel id="sourceAccount">From</InputLabel>
          <Select
            labelId="sourceAccount"
            id="sourceAccount"
            value={formValues.sourceAccount}
            name="sourceAccount"
            label="From"
            onChange={handleFormChange}
          >
            {accounts.map((account) => (
              <MenuItem key={account.accountId} value={account.accountId}>
                {capitalize(account.type)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Spacer height={20} />
        <FormControl fullWidth variant="standard">
          <InputLabel id="destinationAccount">To</InputLabel>
          <Select
            labelId="destinationAccount"
            id="destinationAccount"
            value={formValues.destinationAccount}
            name="destinationAccount"
            label="From"
            onChange={handleFormChange}
          >
            {accounts
              .filter(
                (account) => account.accountId !== formValues.sourceAccount
              )
              .map((account) => (
                <MenuItem key={account.accountId} value={account.accountId}>
                  {capitalize(account.type)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Spacer height={20} />
        <TextField
          autoFocus
          margin="dense"
          id="amount"
          label="Amount"
          type="text"
          fullWidth
          variant="standard"
          value={formValues.amount}
          name="amount"
          onChange={handleFormChange}
        />
        <Spacer height={20} />
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={submit} size="large">
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
}
