import { Box, Card, Chip, Link, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Page from "components/Common/Page";
import Spacer from "components/Common/Spacer";
import React, { ReactNode } from "react";
import InviteCodeDialog from "./InviteCodeDialog";
import useInviteCodesPage from "./InviteCodesPage.hooks";

const columns: GridColDef[] = [
  {
    field: "label",
    headerName: "Label",
    flex: 1,
  },
  {
    field: "ownerUserId",
    headerName: "User ID",
    width: 400,
    renderCell: (params: GridRenderCellParams) =>
      (
        <Link href={`/users/${params.value}`} target="_blank">
          {params.value}
        </Link>
      ) as ReactNode,
  },
  {
    field: "programName",
    headerName: "Referral Credit",
    width: 400,
  },
  {
    field: "paying",
    headerName: "Paying?",
    width: 400,
    renderCell: (params: GridRenderCellParams) => (
      <Chip
        label={params.value ? "Yes" : "No"}
        color={params.value ? "success" : "warning"}
      />
    ),
  },
  {
    field: "userSum",
    headerName: "# of users",
    width: 200,
  },
];

const InviteCodesPage = () => {
  const { codes, fetchInviteCodes } = useInviteCodesPage();

  return (
    <Page>
      <Box display="flex" width="100%" justifyContent="space-between">
        <Typography variant="h4">Invite Codes</Typography>
        <InviteCodeDialog onClose={fetchInviteCodes} />
      </Box>
      <Spacer height={32} />
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        <Card>
          <DataGrid rows={codes || []} columns={columns} autoHeight />
        </Card>
      </Box>
    </Page>
  );
};

export default InviteCodesPage;
