import { Player } from "models/zuko/Player";
import { PlayerSalary } from "models/zuko/PlayerSalary";
import { Team } from "models/zuko/Team";
import { formatUTCDate } from "utils/time";

interface PlayerSalaryData {
  playerSalary: PlayerSalary;
  player: Player;
  team: Team;
}

const usePlayerSalaryTable = (model: PlayerSalaryData) => {
  const tableRows = [
    {
      headerName: "ID",
      value: model.playerSalary.id,
    },
    {
      headerName: "Player First Name",
      value: model.player.givenName,
    },
    {
      headerName: "Player Last Name",
      value: model.player.familyName,
    },
    {
      headerName: "Year",
      value: formatUTCDate(model.playerSalary.startYear, "YYYY"),
    },
    {
      headerName: "Salary",
      value: `$${model.playerSalary.salary.toLocaleString()}`,
    },
    {
      headerName: "Salary Type",
      value: model.playerSalary.type,
    },
    {
      headerName: "Team",
      value: model.team.school || `${model.team.city} ${model.team.mascot}`,
    },
  ];

  const getHeading = () => {
    return `Player Salary Information`;
  };

  return {
    tableRows,
    heading: getHeading(),
  };
};

export default usePlayerSalaryTable;
