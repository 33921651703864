import { MerchantCategory } from "models/plaid/Transaction";
import { get, post } from "./base";

export const getFundingAccountByUserId = (id: string) => {
  return get(`/admin/users/${id}/linked_accounts/funded`);
};

export const getLinkedAccountsByUserId = (
  id: string,
  excludeFunded?: boolean
) => {
  return get(`/admin/users/${id}/linked_accounts`, {
    excludeFunded: excludeFunded,
  });
};

export const createLinkToken = (itemId?: string) => {
  return post("/admin/me/plaid/link_token/create", true, {itemId});
};

export const exchangePublicToken = (publicToken: string) => {
  return post("/admin/me/plaid/public_token/exchange", true, {publicToken});
};

export const updateAccessToken = (itemId: string) => {
  return post("/admin/me/plaid/access_token/update", true, {itemId});
};

export const getAccounts = () => {
  return get("/admin/me/plaid/accounts");
};

export const getExpiredAccessTokenInstitutions = () => {
  return get("/admin/me/plaid/institutions/access_token_expired");
};

export const getInvestmentTransactions = (mineOnly: boolean, limit?: number, offset?: number) => {
  return get("/admin/plaid/investments/transactions", {mineOnly, limit, offset});
};

export const getMerchantCategories = () => {
  return get("/admin/plaid/merchant_categories");
};

export const getPersonalFinanceCategories = () => {
  return get("/admin/plaid/personal_finance_categories");
};

export const updateMerchantCategory = (merchantCategory: MerchantCategory) => {
  return post(
    `/admin/plaid/merchant_categories/${merchantCategory.id}/update`,
    true,
    merchantCategory
  );
};

export const confirmMerchantCategory = (merchantCategoryId: string) => {
  return post(
    `/admin/plaid/merchant_categories/${merchantCategoryId}/confirm`,
    true
  );
};

export const batchConfirmMerchantCategory = (ids: string[]) => {
  return post(`/admin/plaid/merchant_categories/confirm/batch`, true, ids);
};
