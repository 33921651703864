import {
  createRecurringDeposit,
  deleteRecurringDeposit,
} from "api/superAdminActions";
import useACHRelationship from "components/Hooks/ACHRelationship.hooks";
import { ApexAccountType } from "models/ApexAccount";
import { DepositType, Frequency, RecurringDeposit } from "models/ApexTransfer";
import { useState } from "react";

export default function useEditPurchaseRoundUpsDialog(
  userId: string,
  deposit?: RecurringDeposit,
  onEdit?: () => void
) {
  const relationship = useACHRelationship(userId, ApexAccountType.Investment);
  const [on, setOn] = useState(!!deposit);

  const onSave = () => {
    if (deposit && !on) {
      deleteRecurringDeposit(userId, deposit.id).subscribe(onEdit);
    } else if (!deposit && on && relationship) {
      createRecurringDeposit(userId, {
        frequency: Frequency.Daily,
        type: DepositType.RoundUp,
        relationshipId: relationship.id,
      }).subscribe(onEdit);
    }
  };

  return {
    on,
    onSave,
    setOn,
    allowEdit: !!relationship,
  };
}
