import { ItemType } from "models/zuko/Item";
import { ZukoAPI, get, post } from "../base";

export const getFailedVerificationItems = () => {
  return get("/admin/failed/items", undefined, ZukoAPI);
};

export const getFailedVerificationItem = (id: string) => {
  return get(`/admin/failed/items/${id}`, undefined, ZukoAPI);
};

export const getModel = (type: ItemType, id: string) => {
  return get(`/admin/${type}/${id}`, undefined, ZukoAPI);
};

export const getLink = (linkId: string) => {
  return get(`/admin/links/${linkId}`, undefined, ZukoAPI);
};

export const queueLink = (linkId: string) => {
  return post(`/admin/links/${linkId}/queue`, true, undefined, ZukoAPI);
};

export const searchTeams = (query: string) => {
  return get(`/admin/teams`, { query }, ZukoAPI);
};

export const confirmFailedVerificationItem = (id: string) => {
  return post(`/admin/failed/items/${id}/confirm`, true, undefined, ZukoAPI);
};

export const resetFailedVerificationItem = (id: string) => {
  return post(`/admin/failed/items/${id}/reset`, true, undefined, ZukoAPI);
};

export const failFailedVerificationItem = (id: string, reason: string) => {
  return post(`/admin/failed/items/${id}/fail`, true, { reason }, ZukoAPI);
};

export const getRandomUnreviewedFailedVerificationItem = (id: string) => {
  return get(`/admin/failed/items/unreviewed/random`, { id }, ZukoAPI);
};

export const resetFailedVerificationItemByItem = (itemType: ItemType, itemId: string) => {
  return post(`/admin/failed/items/reset?itemType=${itemType}&itemId=${itemId}`, true, {}, ZukoAPI);
}

export const getFailedVerificationItemsByItem = (itemType: ItemType, itemId: string) => {
  return get(`/admin/failed/items`, {itemType, itemId}, ZukoAPI);
}