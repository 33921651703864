import { AxiosError, HttpStatusCode } from "axios";
import { useEffect, useState } from "react";
import { getFundingAccountByUserId } from "../../api/plaid";
import { ACHRelationshipApprovalMethod } from "../../models/ApexACHRelationship";

const useFundingAccount = (userId: string) => {
  const [hasMicroDepositFundingAccount, setMicroDepositFundingAccount] = useState<boolean | undefined>();

  useEffect(() => {
    getFundingAccountByUserId(userId).subscribe({
      next: ({data}) => {
        const account = data.activeAccount ?? data.inactiveAccount;
        setMicroDepositFundingAccount(account.approvalMethod === ACHRelationshipApprovalMethod.MicroDeposit);
      },
      error: (error) => {
        if (error instanceof AxiosError && error.response?.status === HttpStatusCode.NotFound) {
          setMicroDepositFundingAccount(false);
        } else {
          throw error;
        }
      },
    });
  }, []);

  return {
    hasMicroDepositFundingAccount,
    loading: typeof hasMicroDepositFundingAccount === "undefined",
  };
};

export default useFundingAccount;
