import { TabPanel } from "@mui/lab";
import { OfficerComplianceTab } from "../../OfficerCompliancePage";
import { Typography } from "@mui/material";
import Spacer from "components/Common/Spacer";
import useMyAccountsTabPanel from "./MyAccountsTabPanel.hooks";
import TransfersTable from "../TransfersTable";
import TransfersTableRow from "../TransferRow";
import ExpiredConnectionsTable from "./ExpiredConnectionsTable";
import React from "react";
import AccountsTable from "./AccountsTable";

interface IProps {
  recentlyConnectedInstitutions: number;
}

export default function MyAccountsTabPanel({recentlyConnectedInstitutions}: IProps) {
  const {
    accounts,
    accountsLoading,
    expiredAccessTokenInstitutions,
    onConnectionUpdated,
    page,
    setPage,
    totalCount,
    transactions,
    transactionsLoading,
    transactionsPerPage
  } = useMyAccountsTabPanel(recentlyConnectedInstitutions);

  return (
    <TabPanel key={OfficerComplianceTab.MyAccounts} value={OfficerComplianceTab.MyAccounts}>
      {expiredAccessTokenInstitutions.length > 0 && (
        <>
          <Typography variant="h6">Expired Connections</Typography>
          <Spacer height={12}/>
          <ExpiredConnectionsTable
            institutions={expiredAccessTokenInstitutions}
            onConnectionUpdated={onConnectionUpdated}/>
          <Spacer height={32}/>
        </>
      )}
      <Typography variant="h6">Connected Accounts</Typography>
      <Spacer height={12}/>
      <AccountsTable accounts={accounts} loading={accountsLoading}/>
      <Spacer height={32}/>
      <Typography variant="h6">Transactions</Typography>
      <Spacer height={12}/>
      <TransfersTable
        loading={transactionsLoading}
        onPageChange={setPage}
        page={page}
        rowCount={totalCount}
        rowsPerPage={transactionsPerPage}>
        {transactions.map(e =>
          <TransfersTableRow
            model={e}
            subhead2Getter={(e) => `$${e.amount}`}
          />
        )}
      </TransfersTable>
    </TabPanel>
  );
}