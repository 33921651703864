import { cancelACHTransfer } from "api/superAdminActions";
import {
  getACHTransferInfo,
  getApexACHTransfersByUserId,
  retryLiquidation,
} from "api/transfers";
import { ApexAccountType } from "models/ApexAccount";
import { ACHTransfer } from "models/ApexTransfer";
import { useEffect, useState } from "react";
import { map } from "rxjs/operators";
import { fetcher } from "utils/data";

export enum AccountActivityDialogType {
  SeeReturnDetails = "See Return Details",
  SeeTransferId = "See Transfer ID",
}

export default function useAccountActivity(
  userId: string,
  type: ApexAccountType
) {
  const [transfers, setTransfers] = useState<ACHTransfer[]>();
  const [selected, setSelected] = useState<ACHTransfer>();
  const [dialog, setDialog] = useState<AccountActivityDialogType>();

  const fetchTransfers = fetcher(
    getApexACHTransfersByUserId,
    [userId, type],
    setTransfers,
    "transfers"
  );

  useEffect(() => {
    fetchTransfers();
  }, [userId, type]);

  const cancelTransfer = (transfer: ACHTransfer) => {
    cancelACHTransfer(userId, transfer.type, transfer.id).subscribe(
      fetchTransfers
    );
  };

  const getReturnDetails = (transfer: ACHTransfer) => {
    return getACHTransferInfo(userId, transfer.type, transfer.id).pipe(
      map(({ data }) => (data ? JSON.stringify(data, null, 2) : ""))
    );
  };

  const retry = (transfer: ACHTransfer) => {
    retryLiquidation(userId, transfer.id).subscribe(fetchTransfers);
  };

  return {
    transfers,
    selected,
    setSelected,
    dialog,
    setDialog,
    onDialogClose: () => setDialog(undefined),
    cancelTransfer,
    getReturnDetails,
    retryLiquidation: retry,
  };
}
