import { Conference, Team, TeamConference } from "models/zuko/Team";
import { formatUTCDate } from "utils/time";

interface TeamConferenceData {
  teamConference: TeamConference;
  team: Team;
  conference: Conference;
}

const useTeamConferenceTable = (model: TeamConferenceData) => {
  const tableRows = [
    {
      headerName: "ID",
      value: model.teamConference.id,
    },
    {
      headerName: "Team",
      value: model.team.school,
    },
    {
      headerName: "Conference",
      value: model.conference.name,
    },
    {
      headerName: "Start Year",
      value: formatUTCDate(model.teamConference.startYear, "YYYY"),
    },
    {
      headerName: "Type",
      value: model.team.type,
    },
    {
      headerName: "Sport",
      value: model.team.sport,
    },
  ];

  const getHeading = () => {
    return `Team Conference Information`;
  };

  return { tableRows, heading: getHeading() };
};

export default useTeamConferenceTable;
