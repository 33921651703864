import { getMe } from "api/user";
import { isAdmin } from "models/KataraUser";
import { useEffect } from "react";
import useAuth from "./Auth.hooks";

const useAdmin = () => {
  useAuth();

  useEffect(() => {
    getMe().subscribe(({ data }) => {
      if (!data) {
        window.open("/login", "_self");
      } else if (!isAdmin(data)) {
        window.open("/unauthorized", "_self");
      }
    });
  }, []);
};

export default useAdmin;
