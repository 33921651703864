import { Player } from "models/zuko/Player";
import { formatUTCDate } from "utils/time";

const usePlayerTable = (model: Player) => {
  const tableRows = [
    {
      headerName: "ID",
      value: model.id,
    },
    {
      headerName: "First Name",
      value: model.givenName,
    },
    {
      headerName: "Last Name",
      value: model.familyName,
    },
    {
      headerName: "Birth Date",
      value: model.birthDate
        ? formatUTCDate(model.birthDate, "MMMM D, YYYY")
        : undefined,
    },
  ];

  const getHeading = () => {
    return `Player Information`;
  };

  return {
    tableRows,
    heading: getHeading(),
  };
};

export default usePlayerTable;
