import { Route, Routes } from "react-router-dom";
import CreateNotificationPage from "views/CreateNotificationPage/CreateNotificationPage";
import NotificationsPage from "./NotificationsPage/NotificationsPage";
import NotificationDetailPage from "./NotificationDetailPage/NotificationDetailPage";

export default function NotificationRoutes() {
  return (
    <Routes>
      <Route
        key="create-notifications"
        path="/create"
        element={<CreateNotificationPage />}
      />
      <Route key="notifications" path="/" element={<NotificationsPage />} />
      <Route
        key="notification-detail"
        path="/:id"
        element={<NotificationDetailPage />}
      />
    </Routes>
  );
}
