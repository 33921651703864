import { LocalStorageProp, setLocalStorageValue } from "utils/localStorage";
import { get, post } from "./base";

export const requestAuthCode = (
  phoneNumber: string,
  superAuth: boolean = false,
  unixSeconds?: number
) => {
  return post(
    superAuth ? "/super/request_auth_code" : "/request_auth_code",
    false,
    {
      phoneNumber: phoneNumber,
      unixSeconds: unixSeconds || Math.round(Date.now()),
    }
  );
};

export const adminLogin = (token: string) => {
  return post("/v1/admin/auth", false, { token });
};

export const getMe = () => {
  return get("/admin/me");
};

export const superAuth = (
  authCode: string,
  phoneNumber: string,
  unixSeconds: number
) => {
  return post("/super/auth", false, { authCode, phoneNumber, unixSeconds }).subscribe(
    ({ data }) => {
      setLocalStorageValue(
        LocalStorageProp.SuperAdminTime,
        Date.now().toString()
      );
      setLocalStorageValue(LocalStorageProp.SuperAdminToken, data.authToken);
    }
  );
};
