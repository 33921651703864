import useForm from "../../components/Form/Form.hooks";
import { CalendlyScheduledEventStatus } from "../../models/CalendlyScheduledEvent";
import { useState } from "react";
import { isValidDollarString } from "../../utils/strings";
import { isString } from "lodash";
import useFundingAccount from "../../components/Hooks/FundingAccount";

const useCalendlyEventsOutcomeDialog = (status: CalendlyScheduledEventStatus, userId: string) => {
  const [roundUps, setRoundUps] = useState<boolean>(false);
  const {formValues, handleCheckboxChange, handleFormChange} = useForm({status: status});
  const {hasMicroDepositFundingAccount, loading} = useFundingAccount(userId);

  const isSpreadsheetUrlValid = !!formValues.spreadsheetUrl?.trim();

  let isValid = true;
  if (formValues.status == CalendlyScheduledEventStatus.Completed && formValues.includeScoutPlan) {
    isValid = isString(formValues.investmentMonthlyDeposit)
      && isValidDollarString(formValues.investmentMonthlyDeposit)
      && isString(formValues.savingsMonthlyDeposit)
      && isValidDollarString(formValues.savingsMonthlyDeposit)
      && isSpreadsheetUrlValid;
  }

  return {
    formValues,
    handleCheckboxChange,
    handleFormChange,
    hasMicroDepositFundingAccount,
    isSpreadsheetUrlValid,
    isValid,
    loading,
    roundUps,
    setRoundUps
  };
};

export default useCalendlyEventsOutcomeDialog;
