import DialogModal from "components/Modals/DialogModal";
import React from "react";
import {
  Box,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Spacer from "components/Common/Spacer";
import useForm from "components/Form/Form.hooks";
import { createInviteCode } from "api/inviteCodes";
import useInviteCodePrograms from "components/Hooks/InviteCodePrograms";

interface IProps {
  onClose: () => void;
}

const InviteCodeDialog = ({ onClose }: IProps) => {
  const { inviteCodePrograms } = useInviteCodePrograms();
  const { formValues, handleFormChange } = useForm({ label: "" });
  const { label, ownerUserId } = formValues;
  const onSave = () => {
    createInviteCode(
      formValues.label,
      formValues.programId,
      formValues.paying === "true",
      formValues.ownerUserId
    );
  };

  return (
    <DialogModal
      onClose={onClose}
      renderDisplay={(open, setOpen) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Create Code
        </Button>
      )}
      renderDialog={(open, setOpen) => (
        <>
          <Box minWidth={400}>
            <DialogTitle>Create Invite Code</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="label"
                label="Label"
                type="text"
                fullWidth
                variant="standard"
                value={label}
                name="label"
                onChange={handleFormChange}
              />
              <Spacer height={12} />
              <TextField
                autoFocus
                margin="dense"
                id="owner_user_id"
                label="Owner User ID (optional)"
                type="text"
                fullWidth
                variant="standard"
                value={ownerUserId}
                name="ownerUserId"
                onChange={handleFormChange}
              />
              <Spacer height={12} />
              <FormControl fullWidth variant="standard">
                <InputLabel id="referral_credit">Referral Credit</InputLabel>
                <Select
                  labelId="referral_credit"
                  id="referral_credit_select"
                  value={formValues.programId || ""}
                  name="programId"
                  label="Referral Credit"
                  onChange={handleFormChange}
                >
                  {inviteCodePrograms.map((program) => (
                    <MenuItem value={program.id}>{program.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Spacer height={12} />
              <FormControl fullWidth variant="standard">
                <InputLabel id="paying">
                  Paying?
                </InputLabel>
                <Select
                  labelId="paying"
                  id="paying_select"
                  value={formValues.paying || ""}
                  name="paying"
                  label="Paying?"
                  onChange={handleFormChange}
                >
                  {[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" },
                  ].map((code) => (
                    <MenuItem value={code.value}>{code.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  onSave();
                  setOpen(false);
                }}
                size="large"
              >
                Save
              </Button>
            </DialogActions>
          </Box>
        </>
      )}
    />
  );
};

export default InviteCodeDialog;
