import { getChatConfig } from "api/chat";
import { StreamChat } from "stream-chat";

let client: any;

export const getChatClient = async (advisorId?: string) => {
  if (!client || (client.user && advisorId !== client.user.id)) {
    await initializeChatClient(advisorId);
  }

  return client;
};

const initializeChatClient = async (advisorId?: string) => {
  const resp = await getChatConfig(advisorId).toPromise();

  if (!resp) {
    return;
  }

  const data = resp.data;

  const c = await StreamChat.getInstance(data.key);

  if (c.user && c.user.id !== data.adminId) {
    await c.disconnectUser();
  }

  if (!c.user) {
    await c.connectUser(
      {
        id: data.adminId,
        name: data.adminName,
      },
      data.token
    );
  }

  client = c;
};
