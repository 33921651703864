import { credit } from "api/transfers";
import useForm from "components/Form/Form.hooks";
import { ApexAccountType } from "models/ApexAccount";

export default function useCreditDialog(userId: string, onEdit: () => void) {
  const { formValues, handleFormChange, handleCheckboxChange } = useForm({
    type: ApexAccountType.Investment,
  });

  const submit = () => {
    credit(
      userId,
      parseFloat(formValues.amount),
      formValues.description,
      formValues.creditType,
      formValues.type
    ).subscribe(onEdit);
  };

  return {
    formValues,
    handleFormChange,
    handleCheckboxChange,
    submit,
  };
}
