import Page from "components/Common/Page";
import usePlayerAwardTypesPage from "./PlayerAwardTypesPage.hooks";
import { Card } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const PlayerAwardTypesPage = () => {
  const { types, columns } = usePlayerAwardTypesPage();

  return (
    <Page heading="Player Award Types">
      <Card>
        <DataGrid
          rows={types}
          columns={columns}
          autoHeight
          rowCount={types.length}
        />
      </Card>
    </Page>
  );
};

export default PlayerAwardTypesPage;
