export enum PlayerCareerStatType {
  SeasonTotal = "season_total",
  PerGame = "per_game",
  None = "",
}

export enum PlayerCareerStatSeasonType {
  Regular = "regular",
  Playoffs = "playoffs",
  ConferenceTournament = "conference_tournament",
  NationalInvitationTournament = "national_invitation_tournament",
  NCAA = "ncaa",
}

export interface PlayerCareerStat {
  id: string;
  playerId: string;
  teamId?: string;
  gameId?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
  deleted: boolean;
  startYear: Date;
  type: PlayerCareerStatType;
  gamesPlayed?: number;
  gamesStarted?: number;
  minutes?: number;
  fieldGoalsMade?: number;
  fieldGoalsAttempted?: number;
  fieldGoalPercentage?: number;
  twoPointsMade?: number;
  twoPointsAttempted?: number;
  twoPointPercentage?: number;
  threePointsMade?: number;
  threePointsAttempted?: number;
  threePointPercentage?: number;
  freeThrowsMade?: number;
  freeThrowsAttempted?: number;
  freeThrowPercentage?: number;
  offensiveRebounds?: number;
  defensiveRebounds?: number;
  totalRebounds?: number;
  turnovers?: number;
  assists?: number;
  steals?: number;
  blocks?: number;
  blockedShots?: number;
  personalFouls?: number;
  pointDifferential?: number;
  points?: number;
  seasonType?: PlayerCareerStatSeasonType;
  winShares?: number;
}

export const getReadablePlayerCareerStatType = (type: PlayerCareerStatType) => {
  switch (type) {
    case PlayerCareerStatType.SeasonTotal:
      return "Season Total";
    case PlayerCareerStatType.PerGame:
      return "Game";
    default:
      return "";
  }
};

export const getReadablePlayerCareerStatSeasonType = (
  seasonType: PlayerCareerStatSeasonType
) => {
  switch (seasonType) {
    case PlayerCareerStatSeasonType.Regular:
      return "Regular Season";
    case PlayerCareerStatSeasonType.Playoffs:
      return "Playoffs";
    case PlayerCareerStatSeasonType.ConferenceTournament:
      return "Conference Tournament";
    case PlayerCareerStatSeasonType.NationalInvitationTournament:
      return "NIT";
    case PlayerCareerStatSeasonType.NCAA:
      return "NCAA Tournament";
    default:
      return "";
  }
};
