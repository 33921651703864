import axios from "axios";
import config from "config";
import moment from "moment";
import { from } from "rxjs";
import {
  getLocalStorageValue,
  LocalStorageProp,
  unsetLocalStorageValue,
} from "utils/localStorage";

const AangAPI = axios.create({ baseURL: config.data });

export enum ErrorType {
  NotAuthenticated = "not authenticated",
  InvalidToken = "invalid token",
}

export const getSuperAdminAuthToken = (): string => {
  const token = getLocalStorageValue(LocalStorageProp.SuperAdminToken);

  if (!token) {
    unsetLocalStorageValue(LocalStorageProp.SuperAdminToken);
    unsetLocalStorageValue(LocalStorageProp.SuperAdminTime);
    throw new Error(ErrorType.NotAuthenticated);
  }

  const time = getSuperAdminAuthTokenTime();
  if (moment(parseInt(time)).add(1, "hour").isBefore(moment())) {
    unsetLocalStorageValue(LocalStorageProp.SuperAdminToken);
    unsetLocalStorageValue(LocalStorageProp.SuperAdminTime);
    throw new Error(ErrorType.NotAuthenticated);
  }

  return token;
};

export const getSuperAdminAuthTokenTime = (): string => {
  const time = getLocalStorageValue(LocalStorageProp.SuperAdminTime);

  if (!time) {
    throw new Error(ErrorType.NotAuthenticated);
  }

  return time;
};

export const superadminpost = (
  route: string,
  body?: { [key: string]: any }
) => {
  const token = getSuperAdminAuthToken();

  return from(
    AangAPI.post(route, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const superadminpatch = (
  route: string,
  body?: { [key: string]: any }
) => {
  const token = getSuperAdminAuthToken();

  return from(
    AangAPI.patch(route, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export const superadmindelete = (route: string, authed: boolean = true) => {
  const token = getSuperAdminAuthToken();

  return from(
    AangAPI.delete(route, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};
