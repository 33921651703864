import { ZukoAPI, get, post } from "../base";

export const getPlayerAwardTypes = () => {
  return get("/admin/player_awards_types", undefined, ZukoAPI);
};

export const updatePlayerAwardTypes = (id: string, fullName: string) => {
  return post(
    `/admin/player_awards_types/${id}/update`,
    true,
    { fullName },
    ZukoAPI
  );
};
