import { noop } from "lodash";
import { Box, Button, Card, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import DialogModal from "components/Modals/DialogModal";
import Spacer from "components/Common/Spacer";
import React from "react";
import useForm from "components/Form/Form.hooks";
import InviteCodeSelect from "components/Form/InviteCodeSelect/InviteCodeSelect";
import { createEducationSession } from '../../../api/educationSessions';
import moment from 'moment';

interface IProps {
  onSave: () => void;
}

const CreateSessionDialog = ({onSave}: IProps) => {
  const { handleFormChange, formValues, setForm } = useForm({});
  const { date, inviteId } = formValues;

  return <DialogModal
    onClose={noop}
    renderDialog={(_, setOpen) => (<Box minWidth={400}>
      <Card>
        <DialogTitle>Create Session</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="date"
            label="Date"
            type="date"
            fullWidth
            variant="standard"
            value={date}
            name="date"
            onChange={handleFormChange}
          />
          <Spacer height={12} />
          <InviteCodeSelect onChange={(code) => setForm("inviteId", code.id)} property="inviteId" label="Invite Code" payingOnly />
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="contained" onClick={() => {
            createEducationSession(moment(date).utc().toISOString(), inviteId).subscribe(() => setOpen(false))
          }}>Save</Button>
        </DialogActions>
      </Card>
    </Box>)}
    renderDisplay={(_, setOpen) => (
      <Button variant="contained" onClick={() => setOpen(true)}>Create Session</Button>
    )} />;
};

export default CreateSessionDialog;