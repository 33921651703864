import { FinancialInfo } from "models/RiskProfile";
import { Address, TrustedContact, User } from "models/User";
import { get, patch, post } from "./base";
import { ScoutPlan } from "../models/ScoutPlan";

export const getUsers = (query: string, limit?: number, offset?: number) => {
  return get(
    "/admin/users/search",
    { q: query, limit: limit, offset: offset },
  );
};

export const resetUserChallenge = (id: string) => {
  return post(`/users/${id}/dining_challenge/reset`);
};

export const getUser = (id: string) => {
  return get(`/admin/users/${id}`);
};

export const updateUserAddress = (id: string, address: Address) => {
  return post(`/admin/users/${id}/address`, true, address);
};

export const updateUser = (id: string, user: Partial<User>) => {
  return patch(`/admin/users/${id}`, true, user);
};

export const getTrustedContact = (id: string) => {
  return get(`/admin/users/${id}/apex/trusted_contact`);
};

export const updateTrustedContact = (
  id: string,
  contact: Partial<TrustedContact>
) => {
  return post(`/admin/users/${id}/apex/trusted_contact`, true, contact);
};

export const getUserFinancialInfo = (id: string) => {
  return get(`/admin/users/${id}/financial_info`);
};

export const updateUserFinancialInfo = (id: string, info: FinancialInfo) => {
  return patch(`/admin/users/${id}/financial_info`, true, info);
};

export const getRiskProfileByUserId = (id: string) => {
  return get(`/admin/users/${id}/risk_profile`);
};

export const getScoutPlan = (userId: string) => {
  return get(`/admin/users/${userId}/scout_plan`);
};

export const createScoutPlan = (plan: ScoutPlan, userId: string) => {
  return post(`/admin/users/${userId}/scout_plan`, true, plan);
};

export const updateScoutPlan = (plan: ScoutPlan, userId: string) => {
  return patch(`/admin/users/${userId}/scout_plan`, true, plan);
};

export interface PlaidBalances {
  available: number;
  current: number;
  iso_currency_code: string;
  limit: number;
  unofficial_currency_code: string;
}

export interface PlaidAccount {
  account_id: string;
  balances: PlaidBalances;
  mask: string;
  name: string;
  official_name: string;
  subtype: string;
  type: string;
  verification_status: string;
}

export interface PlaidCategory {
  category_id: string;
  group: string;
  hierarchy: string[];
}

export interface SpendCategory {
  categoryID: string;
  plaidCategory: PlaidCategory;
  spend: number;
}

export interface UserFinancialData {
  accounts: PlaidAccount[];
  cashIn: number;
  cashOut: number;
  topSpendingCategories: SpendCategory[];
}

export const getUserFinancialData = (userId: string, periodLength: number) => {
  return get(`/admin/user_financial_data`, { userId, periodLength });
};

export const sendSms = (number: string, body: string) => {
  return post(`/sms/manual`, true, { number, body });
};

export const getInviteCodeInfo = (id: string) => {
  return get(`admin/users/${id}/invite_code/info`);
};

export const blockUser = (id: string) => {
  return post(`/admin/users/${id}/block`);
};

export const unblockUser = (id: string) => {
  return post(`/admin/users/${id}/unblock`);
};

export const getAppVersion = (id: string) => {
  return get(`/admin/users/${id}/app_version`);
};

export const setInviteCode = (id: string, inviteCodeId: string) => {
  return post(`/admin/users/${id}/invite_code`, true, { id: inviteCodeId });
};

export enum ActivityType {
  MeetingScheduled = "meeting_scheduled",
  MeetingStatusRescheduled = "meeting_status_rescheduled",
  MeetingStatusNoShow = "meeting_status_noshow",
  MeetingStatusCanceled = "meeting_status_cancelled",
  MeetingStatusCompleted = "meeting_status_completed",
  InternalNote = "internal_note"
}

export interface Note {
  subject: string;
  note: string;
  kataraUserName: string;
}

export interface Event {
  startTime?: string;
}

export interface UserActivity {
  id: string;
  date: string;
  type: ActivityType;
  note?: Note;
  event?: Event;
}

export const getActivities = (userId: string, limit: number, before?: Date) => {
  return get(`/admin/users/${userId}/activity`, {limit, before});
};

export const createNote = (userId: string, note: string, subject: string) => {
  return post(`/admin/users/${userId}/notes`, true, {note, subject});
};
