import { Box, Card, Typography } from "@mui/material";
import { DataGrid, GridRenderCellParams
 } from "@mui/x-data-grid";
import Page from "components/Common/Page";
import Spacer from "components/Common/Spacer";
import moment from "moment";
import React from "react";
import PromoCodeDialog from "./PromoCodeDialog";
import usePromoCodesPage from "./PromoCodesPage.hooks";

const columns = [
  {
    field: "label",
    headerName: "Code Name",
    flex: 1,
  },
  {
    field: "duration",
    headerName: "Fee Waive Duration (months)",
    width: 400,
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    width: 400,
    valueGetter: (params: GridRenderCellParams
) =>
      params.value ? `${moment(params.value).format("MM/DD/YYYY")}` : "",
  },
];

const PromoCodesPage = () => {
  const { codes, fetchPromoCodes } = usePromoCodesPage();

  return (
    <Page>
      <Box display="flex" width="100%" justifyContent="space-between">
        <Typography variant="h4">Promo Codes</Typography>
        <PromoCodeDialog onClose={fetchPromoCodes} />
      </Box>
      <Spacer height={32} />
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        <Card>
          <DataGrid rows={codes || []} columns={columns} autoHeight />
        </Card>
      </Box>
    </Page>
  );
};

export default PromoCodesPage;
