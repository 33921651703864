import Loader from "components/Common/Loader";
import useDeviceSettings from "./DeviceSettings.hooks";
import { Paper, TableContainer } from "@mui/material";
import CustomTable from "components/Table/CustomTable";

interface IProps {
  userId: string;
}

export default function DeviceSettings({ userId }: IProps) {
  const { settings } = useDeviceSettings(userId);

  if (!settings) {
    return <Loader />;
  }

  const rows = settings.map((setting) => ({
    cells: [
      { value: setting.createdAt },
      { value: setting.id },
      { value: setting.manualEnabled ? "Yes" : "No" },
    ],
  }));

  return (
    <TableContainer component={Paper}>
      <CustomTable headings={["Date", "Device ID", "Enabled?"]} rows={rows} />
    </TableContainer>
  );
}
