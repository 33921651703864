import { get, post } from "./base";
import { CalendlyScheduledEventStatus } from "../models/CalendlyScheduledEvent";
import { omitBy } from "lodash";

export const calendlyAuth = (code: string) => {
  return post("/admin/calendly/auth", true, { code });
};

export enum Sort {
  Ascending = "ASC",
  Descending = "DESC",
}

export interface GetScheduledEventsParams {
  limit?: number;
  offset?: number;
  before?: Date;
  since?: Date;
  sort?: Sort;
  status?: CalendlyScheduledEventStatus;
  userName?: string;
}

export const getScheduledEvents = (params: GetScheduledEventsParams) => {
  const nonFalsyParams = omitBy(params, (value) => !value);
  return get("/admin/calendly/scheduled_events", nonFalsyParams);
};

export const updateScheduledEventStatus = (
  id: string,
  status: CalendlyScheduledEventStatus,
  statusReason?: string
) => {
  return post(`/admin/calendly/scheduled_events/${id}/status`, true, {status, statusReason});
};
