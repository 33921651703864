export enum LocalStorageProp {
  Token = "token:scout",
  SuperAdminToken = "supertoken:scout",
  SuperAdminTime = "supertime:scout",
}

export const setLocalStorageValue = (
  prop: LocalStorageProp,
  data: string
): void => {
  window.localStorage[prop] = data;
};

export const getLocalStorageValue = (prop: LocalStorageProp): string => {
  return window.localStorage[prop];
};

export const unsetLocalStorageValue = (prop: LocalStorageProp): void => {
  delete window.localStorage[prop];
};

const localStorageModule = {
  setLocalStorageValue,
  getLocalStorageValue,
  unsetLocalStorageValue,
};

export default localStorageModule;
