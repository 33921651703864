import { getUserNotifications } from "api/notifications";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";
import { Notification } from "models/Notifications";

export default function useNotifications(userId: string) {
  const [notifs, setNotifs] = useState<Notification[]>();

  const fetchNotifs = fetcher(getUserNotifications, [userId], setNotifs);

  useEffect(() => {
    fetchNotifs();
  }, [userId]);

  return { notifs };
}
