import { Route, Routes } from "react-router-dom";
import UserDetailPage from "./UserDetailPage/UserDetailPage";
import UsersPage from "./UsersPage/UsersPage";

export default function UserRoutes() {
  return (
    <Routes>
      <Route key="user-detail" path="/:id" element={<UserDetailPage />} />
      <Route key="users" path="/" element={<UsersPage />} />
    </Routes>
  );
}
