import { createDeposit } from "api/superAdminActions";
import useForm from "components/Form/Form.hooks";
import useACHRelationship from "components/Hooks/ACHRelationship.hooks";
import useConnectedAccounts from "components/Hooks/ConnectedAccounts.hooks";
import { find } from "lodash";
import { ApexAccountType } from "models/ApexAccount";
import { DepositType } from "models/ApexTransfer";
import { BankAccount } from "models/BankAccount";

export default function useDepositDialog(userId: string, onEdit: () => void) {
  const accounts = useConnectedAccounts(userId);
  const { formValues, handleFormChange } = useForm({
    type: ApexAccountType.Investment,
  });

  const relationship = useACHRelationship(userId, formValues.type);

  const correspondingConnectedAccount = find(
    accounts,
    (account: BankAccount) => {
      return !!relationship && account.itemId === relationship.itemId;
    }
  );

  const submit = () => {
    if (relationship) {
      createDeposit(userId, {
        amount: parseFloat(formValues.amount),
        relationshipId: relationship.id,
        type: DepositType.Manual,
      }).subscribe(onEdit);
    }
  };

  return {
    relationship,
    account: correspondingConnectedAccount,
    formValues,
    handleFormChange,
    submit,
  };
}
