import {
  Box,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import useUser from "components/Hooks/User.hooks";

interface IProps {
  inviteCodeUsed: string;
  inviteCodeUsedOwnerID?: string;
  setOpen: (open: boolean) => void;
}

const columns: GridColDef[] = [
  { field: "field", width: 300 },
  {
    field: "value",
    width: 400,
    renderCell: (params: GridRenderCellParams) =>
      params.row.field === "Referrer ID" ? (
        <Link href={`/users/${params.value}`} target="_blank">{params.value}</Link>
      ) : (
        <Typography>{params.value}</Typography>
      ),
  },
];

const JoinInviteCodeDetailsDialog = ({
  inviteCodeUsed,
  inviteCodeUsedOwnerID,
  setOpen,
}: IProps) => {
  const referrer = useUser(inviteCodeUsedOwnerID);
  const rows = [
    { field: "Join Invite Code", value: inviteCodeUsed },
    { field: "Referrer ID", value: inviteCodeUsedOwnerID },
    {
      field: "Referrer Name",
      value: `${referrer?.legalGivenName} ${referrer?.legalFamilyName}`,
    },
    { field: "Referrer Ambassador Status", value: referrer?.isAmbassador ? "Yes" : "No" },
  ];

  return (
    <Box minWidth={600}>
      <DialogTitle>Join Invite Code Details</DialogTitle>
      <DialogContent>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          autoHeight
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.field}
        />
      </DialogContent>
    </Box>
  );
};

export default JoinInviteCodeDetailsDialog;
