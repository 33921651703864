import { Link, Typography } from "@mui/material";
import {
  getFailedVerificationItem,
  getLink,
  getModel,
  getRandomUnreviewedFailedVerificationItem,
  queueLink,
} from "api/zuko/failedVerificationItems";
import { FailedVerificationItem } from "models/zuko/FailedVerificationItem";
import { Link as LinkType } from "models/zuko/Link";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";
import { formatUTCDate } from "utils/time";
import useGameTable from "../../../components/Table/ModelTable/GameTable.hooks";
import usePlayerTable from "../../../components/Table/ModelTable/PlayerTable.hooks";
import usePlayerSalaryTable from "../../../components/Table/ModelTable/PlayerSalaryTable.hooks";
import usePlayerAwardTable from "../../../components/Table/ModelTable/PlayerAwardTable.hooks";
import { getReadablePlayerAwardCategory } from "models/zuko/PlayerAward";
import usePlayerCareerStatTable from "../../../components/Table/ModelTable/PlayerCareerStatTable.hooks";
import { getReadablePlayerCareerStatType } from "models/zuko/PlayerCareerStat";
import useTeamConferenceTable from "../../../components/Table/ModelTable/TeamConferenceTable.hooks";
import { ItemType } from "models/zuko/Item";

const useFailedItemDetailPage = (id: string) => {
  const [item, setItem] = useState<FailedVerificationItem>();
  const [model, setModel] = useState<any>();
  const [link, setLink] = useState<LinkType>();
  const [next, setNext] = useState<FailedVerificationItem>();

  const fetchItem = fetcher(getFailedVerificationItem, [id], setItem);
  const fetchModel = fetcher(
    getModel,
    [item?.itemType, item?.itemId],
    setModel
  );
  const fetchLink = fetcher(getLink, [item?.linkId], setLink);
  const fetchNext = fetcher(
    getRandomUnreviewedFailedVerificationItem,
    [item?.id],
    setNext
  );

  useEffect(() => {
    fetchNext();
  }, [item?.id]);

  useEffect(() => {
    fetchItem();
  }, [id]);

  useEffect(() => {
    if (item?.linkId) {
      fetchLink();
    }
  }, [item?.linkId]);

  useEffect(() => {
    if (item?.itemType && item?.itemId) {
      fetchModel();
    }
  }, [item?.itemType, item?.itemId]);

  const getHeading = () => {
    switch (item?.itemType) {
      case ItemType.Game:
        return `${formatUTCDate(model?.date)} - ${model?.homeTeam.school} vs ${
          model?.awayTeam.school
        }`;
      case ItemType.Player:
        return `${model?.givenName} ${model?.familyName}`;
      case ItemType.PlayerSalary:
        return `${model?.givenName} ${model?.familyName} ${formatUTCDate(
          model?.startYear,
          "YYYY"
        )} Salary`;
      case ItemType.PlayerAward:
        return `${
          model?.playerAward.startYear
            ? formatUTCDate(model?.playerAward.startYear, "YYYY")
            : ""
        } ${model?.playerAward.type} ${getReadablePlayerAwardCategory(
          model?.playerAward.category
        )} Award`;
      case ItemType.PlayerCareerStat:
        return `${model?.player.givenName} ${
          model?.player.familyName
        } ${formatUTCDate(
          model?.startYear,
          "YYYY"
        )} ${getReadablePlayerCareerStatType(
          model?.playerCareerStat.type
        )} Stats`;
      case ItemType.TeamConference:
        return `${model?.team.school} - ${model?.conference.name}`;
    }
  };

  const getLinkRows = () => {
    if (!link || !item) {
      return [];
    }

    const rows = [
      {
        headerName: "Link ID",
        value: link.id,
      },
      {
        headerName: "Link URL",
        value: link.link,
        renderCell: () => (
          <Link href={link.link} target="_blank">
            <Typography
              variant="body2"
              width={500}
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {link.link}
            </Typography>
          </Link>
        ),
      },
      {
        headerName: "Last Attempted",
        value: moment(link.lastAttempted).format("MM/DD/YYYY"),
      },
      {
        headerName: "Last Scraped Successfully",
        value: moment(link.lastVisited).format("MM/DD/YYYY"),
      },
      {
        headerName: "Last Queued",
        value: moment(link.lastQueued).format("MM/DD/YYYY"),
      },
    ];

    if (item.reason) {
      rows.push({
        headerName: "Failure Reason",
        value: item.reason,
      });
    }

    return rows;
  };

  const scrapeLink = () => {
    if (link) {
      queueLink(link.id).subscribe(() => fetchLink());
    }
  };

  const getUseTable = () => {
    if (!item) {
      return;
    }

    switch (item.itemType) {
      case ItemType.Game:
        return useGameTable;
      case ItemType.Player:
        return usePlayerTable;
      case ItemType.PlayerSalary:
        return usePlayerSalaryTable;
      case ItemType.PlayerAward:
        return usePlayerAwardTable;
      case ItemType.PlayerCareerStat:
        return usePlayerCareerStatTable;
      case ItemType.TeamConference:
        return useTeamConferenceTable;
    }
  };

  return {
    item,
    model,
    heading: getHeading(),
    linkRows: getLinkRows(),
    scrapeLink,
    useTable: getUseTable(),
    refetchModel: fetchModel,
    refetchItem: fetchItem,
    next,
  };
};

export default useFailedItemDetailPage;
