import { bool } from "aws-sdk/clients/redshiftdata";
import { last } from "lodash";
import moment from "moment";

export const dummyApexDeposit1 = {
  externalId: "0",
  relationshipId: "fake-relationship",
  memo: "",
  amount: 300.0,
  status: "Pending",
};

export interface TransactionHistory {
  deposits: ApexDeposit[];
  withdrawals: ApexWithdrawal[];
  liquidations: Liquidation[];
  unexecutablePendingDeposits: Deposit[];
  credits: Credit[];
  pendingCredits: Credit[];
}

export interface Credit {
  amount: number;
  createdAt: string;
  id: string;
  status: string;
  type: string;
}

export interface Liquidation {
  account: string;
  amount: number;
  createdAt: string;
  externalId: string;
  id: string;
  liquidateAll: bool;
  status: string;
  updatedAt: string;
}

export interface Deposit {
  amount: number;
  createdAt: string;
  frequency: string;
  id: string;
  relationshipId: string;
  status: string;
  type: string;
  userId: string;
}

export interface ApexDeposit {
  id?: string;
  externalId: string;
  relationshipId: string;
  memo?: string;
  amount: number;
  status?: string;
  createdAt: string;
}

export interface ApexWithdrawal {
  id?: string;
  externalId: string;
  relationshipId: string;
  memo?: string;
  amount: number;
  status?: string;
  isFullDisbursement?: bool;
  createdAt: string;
}

export interface ApexCashJournal {
  id?: string;
  externalId?: string;
  sourceAccount?: string;
  destinationAccount?: string;
  userId?: string;
  amount?: number;
  status?: string;
  isFullDisbursement?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum Frequency {
  Monthly = "monthly",
  Daily = "daily",
}

export enum DepositType {
  Manual = "manual",
  RoundUp = "round_up",
}

enum DepositStatus {
  Pending = "PENDING",
  Executed = "EXECUTED",
}

export interface RecurringDeposit {
  id: string;
  userId: string;
  relationshipId: string;
  amount: number;
  frequency: Frequency;
  type: DepositType;
  status: DepositStatus;
  nextAt: string;
}

export enum CreditType {
  FeeReversal = "FEE_REVERSAL",
  PromoCredit = "PROMO_CREDIT",
  WriteOff = "WRITE_OFF",
  Rebate = "REBATE",
  Courtesy = "COURTESY",
}

export interface ACHTransfer {
  accountId: string;
  amount: number;
  date: string;
  id: string;
  relationshipId: string;
  status: string;
  subtype: string;
  title: string;
  type: string;
}

export const getNextDepositDate = (deposit: RecurringDeposit) => {
  if (deposit.frequency.startsWith(Frequency.Monthly)) {
    const date = last(deposit.frequency.split("_"));
    const currMonth = moment().month();
    const currDate = moment().date();
    const depositDate = date && parseInt(date);

    return (
      depositDate &&
      moment()
        .month(currMonth + (currDate <= depositDate ? 0 : 1))
        .date(depositDate)
    );
  }
};

export const getTransferStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "completed":
      return "success";
    case "returned":
    case "canceled":
      return "error";
    default:
      return "primary";
  }
};

export const isCancelable = (status: string) => {
  switch (status.toLowerCase()) {
    case "requested":
    case "postponed":
    case "fundsposted":
    case "pending":
      return true;
    default:
      return false;
  }
};

export const canRetry = (status: string) => {
  switch (status.toLowerCase()) {
    case "cash_available":
      return true;
    default:
      return false;
  }
};
