import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSuperAdminContext } from "components/Contexts/SuperAdminContext/SuperAdminContext";
import { useState } from "react";

interface IProps {
  renderComponent: (onClick: () => void) => void;
  action: () => void;
}

const SuperAdminAction = ({ renderComponent, action }: IProps) => {
  const token = useSuperAdminContext();
  const [open, setOpen] = useState(false);

  return (
    <>
      {renderComponent(() => {
        if (token) {
          action();
        } else {
          setOpen(true);
        }
      })}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box width="400px">
          <DialogTitle>Forbidden</DialogTitle>
          <DialogContent>
            <Typography>
              Click lock on top right of page to unlock action
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
              size="large"
            >
              Okay
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default SuperAdminAction;
