import { Paper, TableContainer } from "@mui/material";
import { ApexAccountType } from "models/ApexAccount";
import useAccountOverview from "./AccountOverview.hooks";
import HorizontalTable from "components/Table/HorizontalTable";
import Loader from "components/Common/Loader";
import StatusBadge from "components/Badges/StatusBadge";
import AccountRequestDialog from "./AccountRequestDialog";
import AccountSettingsDialog from "./AccountSettingsDialog";

interface IProps {
  userId: string;
  type: ApexAccountType;
}

export default function AccountOverview({ userId, type }: IProps) {
  const { account } = useAccountOverview(userId, type);

  if (!account) {
    return <Loader />;
  }

  const rows = [
    { headerName: "Account Number", value: account.accountId },
    {
      headerName: "Status",
      renderCell: () => <StatusBadge status={account.requestState} />,
    },
    {
      headerName: "Account Request",
      renderCell: () => (
        <AccountRequestDialog userId={userId} accountType={type} />
      ),
    },
  ];

  if (type === ApexAccountType.Investment) {
    rows.push({
      headerName: "Rebalancer Settings",
      renderCell: () => <AccountSettingsDialog userId={userId} />,
    });
  }

  return (
    <TableContainer component={Paper}>
      <HorizontalTable rows={rows} />
    </TableContainer>
  );
}
