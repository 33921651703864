import { Box, Typography } from "@mui/material";
import Page from "components/Common/Page";
import useAdmin from "components/Hooks/Admin.hooks";
import FeatureFlagRow from "components/Rows/FeatureFlagRow";
import React from "react";
import useFeatureFlagsPage from "./FeatureFlagsPage.hooks";

const FeatureFlagsPage = () => {
  useAdmin();
  const { flags, onFeatureFlagChange } = useFeatureFlagsPage();

  return (
    <Page>
      <Box display="flex" width="100%" flexDirection="column">
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          padding="16px"
          alignItems="center"
        >
          <Box flex={1}>
            <Typography variant="h5">Feature Flags</Typography>
          </Box>
        </Box>

        {(flags || []).map((flag) => (
          <FeatureFlagRow flag={flag} onClick={() => {}} onChange={onFeatureFlagChange}/>
        ))}
      </Box>
    </Page>
  );
};

export default FeatureFlagsPage;
