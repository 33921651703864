import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Link,
  Typography,
} from "@mui/material";
import { useMatch } from "react-router-dom";
import Page from "components/Common/Page";
import useAdmin from "components/Hooks/Admin.hooks";
import SuperAdminLock from "components/SuperAdmin/SuperAdminLock";
import { SuperAdminProvider } from "components/Contexts/SuperAdminContext/SuperAdminContext";
import UserSearch from "components/Search/UserSearch";
import UserDetailsContainer from "./components/UserDetailsContainer";
import useUser from "components/Hooks/User.hooks";
import Loader from "components/Common/Loader";
import Spacer from "components/Common/Spacer";
import UserActions from "./components/UserActions/UserActions";

const match = "/users/:id";

const UserDetailPage = () => {
  useAdmin();
  const m = useMatch(match);
  const id = m?.params.id || "";
  const user = useUser(id);

  if (!user) {
    return <Loader />;
  }

  return (
    <SuperAdminProvider>
      <Page>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/users">
              Users
            </Link>
            <Typography color="text.primary">
              {user?.givenName} {user?.familyName}
            </Typography>
          </Breadcrumbs>
          <Box display="flex" alignItems="center">
            <UserSearch />
            <SuperAdminLock />
          </Box>
        </Box>
        <Spacer height={48} />
        {user.blocked && (
          <>
            <Alert severity="error">
              Account Blocked - Fraudulent Activity
            </Alert>
            <Spacer height={32} />
          </>
        )}
        <Box display="flex" alignItems="center">
          <Avatar src={user.profileUrl} sx={{ width: 54, height: 54 }} />
          <Spacer width={8} />
          <Typography variant="h4">
            {user.givenName} {user.familyName}
          </Typography>
        </Box>
        <Spacer height={24} />
        <UserActions userId={id} />
        <Spacer height={48} />
        <UserDetailsContainer userId={id} />
      </Page>
    </SuperAdminProvider>
  );
};

export default UserDetailPage;
