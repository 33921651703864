import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { createACHRelationship } from "api/achRelationships";
import { BankAccount } from "models/BankAccount";

interface IProps {
  open: boolean;
  onClose: () => void;
  onAdd?: () => void;
  account: BankAccount;
  userId: string;
}

export default function AddFundingSourceDialog({
  open,
  onClose,
  onAdd,
  userId,
  account,
}: IProps) {
  return (
    <Dialog open={!!open} onClose={onClose}>
      <DialogTitle>
        Are you sure you want to add this account as a funding source?
      </DialogTitle>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            if (open) {
              createACHRelationship(
                userId,
                account.itemId,
                account.accountId,
                account.name
              ).subscribe(onAdd);
              onClose();
            }
          }}
          size="large"
        >
          Yes
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="inherit"
          onClick={onClose}
          size="large"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
