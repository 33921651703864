import CustomSelectInput from "../CustomSelectInput";
import { FileType } from "../S3FileInput/S3FileInput";

interface IProps {
  onChange: (value: string) => void;
  label: string;
  disabled?: boolean;
}

const FILE_TYPES = [
  { text: "Gif", value: FileType.Gif },
  { text: "Image", value: FileType.Image },
  { text: "PDF", value: FileType.PDF },
];

const S3FileTypeInput = ({ onChange, label, disabled }: IProps) => {
  return (
    <>
      <CustomSelectInput
        label={label}
        showLabel
        disabled={disabled}
        onChange={onChange}
        options={FILE_TYPES}
        renderOption={({ text, value }) => (
          <option value={value} key={text}>
            {text}
          </option>
        )}
      />
    </>
  );
};

export default S3FileTypeInput;
