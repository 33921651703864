import { Alert, AlertTitle, InputLabel } from "@mui/material";
import Spacer from "../../../../../components/Common/Spacer";

export type ActivityCardColor = 'success' | 'info' | 'warning' | 'error';

interface IProps {
  title: string;
  content?: string;
  color: ActivityCardColor;
  bottomLabel: string;
}

export function ActivityCard({title, content, color, bottomLabel}: IProps) {
  return (
    <>
      <Alert variant="outlined" severity={color} sx={{textAlign: "start"}}>
        <AlertTitle>{title}</AlertTitle>
        {content}
      </Alert>
      <Spacer height={8}/>
      <InputLabel sx={{
        fontFamily: "Roboto",
        fontSize: 12,
        letterSpacing: 0.15,
        textAlign: "right"
      }}>{bottomLabel}</InputLabel>
    </>
  );
}
