import useCalendlyEventsGrid from "./CalendlyEventsGrid.hooks";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import moment from "moment/moment";
import StatusBadge from "../../components/Badges/StatusBadge";
import { Fragment } from "react";
import { Sort } from "../../api/calendly";
import { Link } from "@mui/material";
import CalendlyEventsOutcomeDialog from "./CalendlyEventsOutcomeDialog";
import ExpandableText from "../../components/Common/ExpandableText";

interface IProps {
  sort: Sort;
  before?: Date;
  since?: Date;
  query?: string;
}

const CalendlyEventsGrid = ({ sort, before, since, query }: IProps) => {
  const {
    events,
    onSubmit,
    pageSize,
    setPage,
    setPageSize,
    totalEventCount,
    loading,
  } = useCalendlyEventsGrid(sort, before, since, query);

  const columns: GridColDef[] = [
    {
      field: "user_name",
      headerName: "Customer Name",
      sortable: false,
      valueGetter: (params) => {
        return params.row.userGivenName
          ? `${params.row.userGivenName} ${params.row.userFamilyName}`
          : "";
      },
      width: 200,
    },
    {
      field: "userPhoneNumber",
      headerName: "Phone Number",
      sortable: false,
      width: 200,
    },
    {
      field: "_",
      headerName: "Katara Profile",
      renderCell: (params) => (
        <Link href={`/users/${params.row.userId}`} target="_blank">
          Link
        </Link>
      ),
      sortable: false,
      width: 200,
    },
    {
      field: "userInviteCodeUsed",
      headerName: "Invite Code",
      sortable: false,
      width: 200,
    },
    {
      field: "eventTypeName",
      headerName: "Event Type",
      sortable: false,
      width: 200,
    },
    {
      field: "startTime",
      headerName: "Event Date",
      valueFormatter: (params) =>
        moment(params.value).format("YYYY-MM-DD hh:mm:ssa"),
      valueParser: (value) => new Date(value),
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => <StatusBadge status={params.value} />,
      sortable: false,
      width: 200,
    },
    {
      field: "statusReason",
      headerName: "Reason",
      renderCell: params => params.value ? <ExpandableText>{params.value}</ExpandableText> : <Fragment/>,
      sortable: false,
      width: 200,
    },
    {
      field: "____",
      headerName: "Mark Outcome",
      renderCell: (params) => (
        <CalendlyEventsOutcomeDialog
          onSubmit={(result) => onSubmit(params.row, result)}
          status={params.row.status}
          userId={params.row.userId}
        />
      ),
      sortable: false,
      width: 200,
    },
  ];

  return (
    <DataGrid
      autoHeight
      columns={columns}
      disableColumnMenu={true}
      getRowHeight={() => "auto"}
      loading={loading}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      pageSize={pageSize}
      paginationMode="server"
      rowCount={totalEventCount}
      rows={events || []}
      rowsPerPageOptions={[10, 25, 100]}
      sx={{"& .MuiDataGrid-cell": {padding: "9.5px"}}}
    />
  );
};

export default CalendlyEventsGrid;
