import { Paper, TableContainer } from "@mui/material";
import HorizontalTable from "components/Table/HorizontalTable";
import Loader from "components/Common/Loader";
import { ApexAccountType } from "models/ApexAccount";
import useAccountBalances from "./AccountBalances.hooks";

interface IProps {
  userId: string;
  type?: ApexAccountType;
}

export default function AccountBalances({ userId, type }: IProps) {
  const { balances } = useAccountBalances(userId, type);

  if (!balances) {
    return <Loader />;
  }

  const rows = [
    {
      headerName: "Net Liquidity",
      value: `$${balances.netLiquidity}`,
    },
    {
      headerName: "Open Balance",
      value: `$${balances.openBalance}`,
    },
    {
      headerName: "Available to Withdraw",
      value: `$${balances.amountAvailableToWithdraw}`,
    },
  ];

  return (
    <TableContainer component={Paper}>
      <HorizontalTable rows={rows} />
    </TableContainer>
  );
}
