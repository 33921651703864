export enum AdminRole {
  Internal = "internal",
  Admin = "admin",
}

export interface KataraUser {
  id: string;
  email: string;
  givenName: string;
  familyName: string;
  userId: string;
  role: AdminRole;
  phoneNumber: string;
  complianceOfficer: boolean;
}

export const isAdmin = (user?: KataraUser) => user && user.role === AdminRole.Admin;
