import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Switch,
  Typography,
} from "@mui/material";
import DialogModal from "components/Modals/DialogModal";
import SuperAdminAction from "components/SuperAdmin/SuperAdminAction";
import useEditPurchaseRoundUpsDialog from "./EditPurchaseRoundUpsDialog.hooks";
import { RecurringDeposit } from "models/ApexTransfer";
import { noop } from "lodash";

interface IProps {
  userId: string;
  deposit?: RecurringDeposit;
  onEdit?: () => void;
}

export default function EditPurchaseRoundUpsDialog({
  userId,
  deposit,
  onEdit,
}: IProps) {
  const { on, onSave, setOn, allowEdit } = useEditPurchaseRoundUpsDialog(
    userId,
    deposit,
    onEdit
  );

  return (
    <DialogModal
      renderDisplay={(open, setOpen) =>
        allowEdit ? (
          <SuperAdminAction
            renderComponent={(onClick) => <Link onClick={onClick}>Edit</Link>}
            action={() => setOpen(true)}
          />
        ) : (
          <></>
        )
      }
      renderDialog={(open, setOpen) => (
        <Box width="400px">
          <DialogTitle>Edit Purchase Round-Ups</DialogTitle>
          <DialogContent>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" flex={1}>
                Add Purchase Round Ups?
              </Typography>
              <Switch
                defaultChecked={on}
                name="on"
                onChange={() => setOn(!on)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onSave();
                setOpen(false);
              }}
              size="large"
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      )}
      onClose={noop}
    />
  );
}
