import Page from "components/Common/Page";
import useOfficerCompliancePage from "./OfficerCompliancePage.hooks";
import { Button, Tab, Tabs } from "@mui/material";
import Spacer from "components/Common/Spacer";
import { TabContext } from "@mui/lab";
import MyAccountsTabPanel from "./components/MyAccountsTabPanel/MyAccountsTabPanel";
import AllAccountsTabPanel from "./components/AllAccountsTabPanel/AllAccountsTabPanel";

export enum OfficerComplianceTab {
  MyAccounts = "my accounts",
  AllAccounts = "all accounts",
}

export default function OfficerCompliancePage() {
  const {
    handleTabChange,
    isComplianceOfficer,
    onConnectInvestmentAccountsClick,
    recentlyConnectedInstitutions,
    tab,
    userName
  } = useOfficerCompliancePage();

  return (
    <Page heading={userName}
          RightHeadingComponent={<Button onClick={onConnectInvestmentAccountsClick} variant="contained">connect
            investment accounts</Button>}>
      {isComplianceOfficer && (
        <>
          <Spacer height={40}/>
          <Tabs onChange={handleTabChange} value={tab}>
            {Object.values(OfficerComplianceTab).map(e => <Tab label={e} key={e} value={e}
                                                               sx={{minWidth: {sm: 240}}}/>)}
          </Tabs>
        </>
      )}
      <TabContext value={tab}>
        <MyAccountsTabPanel recentlyConnectedInstitutions={recentlyConnectedInstitutions}/>
        <AllAccountsTabPanel/>
      </TabContext>
    </Page>
  );
}
