import { Link } from "@mui/material";
import { deleteUser } from "api/superAdminActions";
import SuperAdminAction from "components/SuperAdmin/SuperAdminAction";

interface IProps {
  userId: string;
}

export default function DeleteUser({ userId }: IProps) {
  return (
    <SuperAdminAction
      renderComponent={(onClick) => (
        <Link underline="always" onClick={onClick}>
          Delete User
        </Link>
      )}
      action={() =>
        deleteUser(userId).subscribe(() => window.open("/users", "_self"))
      }
    />
  );
}
