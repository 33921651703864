import { Input, Paper, Tab, Tabs } from "@mui/material";
import Page from "../../components/Common/Page";
import Spacer from "../../components/Common/Spacer";
import CalendlyEventsGrid from "./CalendlyEventsGrid";
import { Sort } from "../../api/calendly";
import useCalendlyEventsPage, { CalendlyEventsTab } from "./CalendlyEventsPage.hooks";
import { Box } from "@mui/system";

const CalendlyEventsPage = () => {
  const { currentTab, hasQuery, onQueryChange, query, setCurrentTab } = useCalendlyEventsPage();

  const now = new Date();
  return (
    <Page
      heading="Calendly Events"
      RightHeadingComponent={
        <Box width={250}>
          <Input
            fullWidth
            placeholder="Search Users"
            onChange={(e) => onQueryChange(e.currentTarget.value.trim())}
          />
        </Box>
      }
    >
      <Tabs
        onChange={(_, value) => setCurrentTab(value)}
        value={currentTab}
        TabIndicatorProps={{ hidden: hasQuery }}
      >
        {Object.values(CalendlyEventsTab).map((e) => (
          <Tab disabled={hasQuery} label={e} value={e} />
        ))}
      </Tabs>
      <Spacer height={24} />
      <Paper>
        {hasQuery
          ? <CalendlyEventsGrid sort={Sort.Ascending} query={query} />
          : (
            <>
              {currentTab === CalendlyEventsTab.UpcomingEvents && (
                <CalendlyEventsGrid sort={Sort.Ascending} since={now} />
              )}
              {currentTab === CalendlyEventsTab.PastEvents && (
                <CalendlyEventsGrid sort={Sort.Descending} before={now} />
              )}
            </>
          )}
      </Paper>
    </Page>
  );
};

export default CalendlyEventsPage;
