import { getMe } from "api/user";
import { KataraUser } from "models/KataraUser";
import { useCallback, useEffect, useState } from "react";

const useKataraUser = (adminUser?: KataraUser) => {
  const [user, setUser] = useState<KataraUser>();

  const fetchKataraUser = useCallback(
    () => getMe().subscribe(({ data }) => setUser(data)),
    []
  );

  useEffect(() => {
    try {
      if (!adminUser) {
        fetchKataraUser();
      } else {
        setUser(adminUser);
      }
    } catch (e) {}
  }, [adminUser, fetchKataraUser]);

  return { user };
};

export default useKataraUser;
