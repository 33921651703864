import { getTrustedContact, getUser } from "api/users";
import { TrustedContact, User } from "models/User";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

export default function useUserBasicInfo(userId: string) {
  const [user, setUser] = useState<User>();
  const [contact, setContact] = useState<TrustedContact>();

  const fetchUser = fetcher(getUser, [userId], setUser);
  const fetchTrustedContact = fetcher(getTrustedContact, [userId], setContact);

  const refetch = () => {
    fetchUser();
    fetchTrustedContact();
  };

  useEffect(() => {
    fetchUser();
    fetchTrustedContact();
  }, [userId]);

  return { user, contact, refetch };
}
