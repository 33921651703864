import { Route, Routes } from "react-router-dom";
import EducationSessionsListPage from "./ListPage/EducationSessionsListPage";
import EducationSessionDetailPage from "./DetailPage/EducationSessionDetailPage";

export default function EducationSessionRoutes() {
  return (
    <Routes>
      <Route key="sessions" path="/" element={<EducationSessionsListPage/>}/>
      <Route key="session" path="/:id" element={<EducationSessionDetailPage/>}/>
    </Routes>
  );
}
