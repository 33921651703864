import { Game } from "models/zuko/Game";
import { formatUTCDate } from "utils/time";

const useGameTable = (model: Game) => {
  const tableRows = [
    {
      headerName: "ID",
      value: model.id,
    },
    {
      headerName: "Date",
      value: formatUTCDate(model.date),
    },
    {
      headerName: "Team 1",
      value: model.homeTeam.school,
    },
    {
      headerName: "Team 1 Points",
      value: model.homeTeamPoints,
    },
    {
      headerName: "Team 2",
      value: model.awayTeam.school,
    },
    {
      headerName: "Team 2 Points",
      value: model.awayTeamPoints,
    },
    {
      headerName: "Point Differential",
      value: model.pointDifferential,
    },
    {
      headerName: "Location",
      value: model.location,
    },
  ];

  const getHeading = () => {
    return `Game Information`;
  };

  return {
    tableRows,
    heading: getHeading(),
  };
};

export default useGameTable;
