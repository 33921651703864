import { Box } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";
import React from "react";
import NavBar from "./NavBar/NavBar";

const NavWrapper = () => {
  return (
    <Box bgcolor="#FBFCFD" display="flex" width="100vw" height="100vh">
      <NavBar />
      <Box flex={1}>
        <Toolbar/>
        <Outlet/>
      </Box>
    </Box>
  );
};

export default NavWrapper;
