import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const UnauthorizedPage = () => {
  return (
    <Box
      display="flex"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography variant="body1">
        You are not authorized to view this page. If you think this is a
        mistake, feel free to reach out to Cindy.
      </Typography>
    </Box>
  );
};

export default UnauthorizedPage;
