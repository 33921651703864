import { SyntheticEvent, useEffect, useState } from "react";
import { fetcher } from "../../../../utils/data";
import { createLinkToken, updateAccessToken } from "../../../../api/plaid";
import { usePlaidLink } from "react-plaid-link";

export default function useExpiredConnectionsTable(onConnectionUpdated: (itemId: string) => void) {
  const [itemId, setItemId] = useState<string | undefined>();
  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>();
  const [token, setToken] = useState<string | null>(null);

  const resetMenuAnchor = () => setMenuAnchor(undefined);

  const fetchToken = fetcher(createLinkToken, [itemId], setToken, "linkToken");

  useEffect(() => {
    if (itemId) {
      fetchToken();
    }
  }, [itemId]);

  const {open, ready} = usePlaidLink({
    token: token,
    onExit: () => setItemId(undefined),
    onSuccess: () => {
      updateAccessToken(itemId!).subscribe(() => {
        onConnectionUpdated(itemId!);
        setItemId(undefined);
      });
    }
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  const onMenuClick = (event: SyntheticEvent<any>) => {
    if (itemId === undefined) {
      setMenuAnchor(event.currentTarget);
    }
  };

  const onUpdateConnectionClick = (itemId: string) => {
    resetMenuAnchor();
    setItemId(itemId);
  };

  return {menuAnchor, onMenuClick, onMenuClose: resetMenuAnchor, onUpdateConnectionClick: onUpdateConnectionClick};
}