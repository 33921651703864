import usePendingValidationItemListPage from "./PendingValidationItemListPage.hooks";
import Page from "components/Common/Page";
import Spacer from "components/Common/Spacer";
import { Box, Typography, Card } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const PendingValidationItemListPage = () => {
  const { columns, rows } = usePendingValidationItemListPage();

  return (
    <Page>
      <Box display="flex" width="100%" justifyContent="space-between">
        <Typography variant="h4">Pending Validation Items</Typography>
      </Box>
      <Spacer height={32} />
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        <Card>
          <DataGrid rows={rows} columns={columns} autoHeight />
        </Card>
      </Box>
    </Page>
  );
};

export default PendingValidationItemListPage;
