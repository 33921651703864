import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import DialogModal from "components/Modals/DialogModal";
import SuperAdminAction from "components/SuperAdmin/SuperAdminAction";
import { RecurringDeposit } from "models/ApexTransfer";
import useEditRecurringDeposit from "./EditRecurringDepositDialog.hooks";
import { ApexAccountType } from "models/ApexAccount";
import { noop } from "lodash";
import Spacer from "components/Common/Spacer";

interface IProps {
  userId: string;
  type: ApexAccountType;
  deposit?: RecurringDeposit;
  onEdit?: () => void;
}

export default function EditRecurringDepositDialog({
  deposit,
  onEdit,
  type,
  userId,
}: IProps) {
  const { onSave, allowEdit, onDelete, handleFormChange, formValues } =
    useEditRecurringDeposit(userId, type, deposit, onEdit);

  return (
    <DialogModal
      key={deposit?.id}
      renderDisplay={(open, setOpen) =>
        allowEdit ? (
          <SuperAdminAction
            renderComponent={(onClick) => {
              return <Link onClick={onClick}>Edit</Link>;
            }}
            action={() => setOpen(true)}
          />
        ) : (
          <></>
        )
      }
      renderDialog={(open, setOpen) => (
        <Box width="400px">
          <DialogTitle>Edit Recurring Deposit</DialogTitle>
          <DialogContent>
            {deposit && (
              <>
                <Box fontSize={16}>
                  <Typography fontWeight={700}>ID</Typography>
                  <Typography>{deposit.id}</Typography>
                </Box>
                <Spacer height={20} />
              </>
            )}
            <TextField
              autoFocus
              margin="dense"
              id="amount"
              label="Monthly Deposit"
              type="number"
              fullWidth
              variant="standard"
              value={formValues.amount}
              name="amount"
              onChange={handleFormChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onSave();
                setOpen(false);
              }}
              size="large"
            >
              Save
            </Button>
            {deposit && (
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  onDelete();
                  setOpen(false);
                }}
                size="large"
                color="inherit"
              >
                Delete Deposit
              </Button>
            )}
          </DialogActions>
        </Box>
      )}
      onClose={noop}
    />
  );
}
