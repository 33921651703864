import { Paper, TableContainer } from "@mui/material";
import { ApexAccountType } from "models/ApexAccount";
import useRecurringDeposits from "components/Hooks/RecurringDeposits.hooks";
import HorizontalTable from "components/Table/HorizontalTable";
import moment from "moment";
import EditRecurringDepositDialog from "./EditRecurringDepositDialog/EditRecurringDepositDialog";
import { DepositType, Frequency } from "models/ApexTransfer";

interface IProps {
  userId: string;
  type: ApexAccountType;
}

export default function RecurringDeposits({ userId, type }: IProps) {
  const { deposit, fetchDeposits } = useRecurringDeposits(
    userId,
    type,
    (deposit) =>
      deposit.frequency.startsWith(Frequency.Monthly) &&
      deposit.type === DepositType.Manual
  );
  const nextDepositDate = deposit && moment(deposit.nextAt.slice(0, 10));

  const rows = [
    {
      headerName: deposit
        ? `Next: ${nextDepositDate!.format("MMMM DD")}`
        : "No Deposits Created",
      value: deposit ? `$${deposit.amount}` : undefined,
      action: () => (
        <EditRecurringDepositDialog
          userId={userId}
          type={type}
          deposit={deposit}
          onEdit={() => setTimeout(fetchDeposits, 1000)}
        />
      ),
    },
  ];

  return (
    <TableContainer component={Paper}>
      <HorizontalTable rows={rows} />
    </TableContainer>
  );
}
