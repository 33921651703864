import React from "react";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import Routes from "routes";
import "./App.css";
import colors from "styles/colors";
import { GoogleOAuthProvider } from "@react-oauth/google";

const theme = createTheme({
  palette: {
    primary: {
      main: colors.elevateBlue,
    },
    secondary: {
      main: colors.elevateYellow,
    },
  },
});

function App() {
  return (
    <GoogleOAuthProvider clientId="376691001277-3fin7955dsuohqoq270ui7cnhh7gi1pn.apps.googleusercontent.com">
      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          flexDirection="column"
          style={{ width: "100vw", height: "100vh" }}
          className="app"
        >
          <Routes />
        </Box>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
