export enum LinkType {
  InternationalPlayerOverview = "international_player_overview",
  PlayerOverview = "player_overview",
  PlayerOverviewList = "player_overview_list",
  SalaryCapList = "salary_cap_list",
  YearlyPlayerSalaryList = "yearly_player_salary_list",
  TeamPayrollList = "team_payroll_list",
  PlayerSalary = "player_salary",
  YearlyTeamPlayerSalaryList = "yearly_team_player_salary_list",
  GameBoxScoreList = "game_box_score_list",
  ConferenceList = "conference_list",
  YearlyConferenceSummary = "yearly_conference_summary",
  YearlyPlayerGameList = "yearly_player_game_list",
  PlayerGameList = "player_game_list",
  YearlyGameList = "yearly_game_list",
  SchoolList = "school_list",
  YearlyTeamRoster = "yearly_team_roster",
  SchoolConferenceList = "school_conference_list",
  ConferenceOverview = "conference_overview",
  ConferenceStatLeaderboard = "conference_stat_leaderboard",
  TeamRoster = "team_roster",
  PlayerBirthDateList = "player_birth_date_list",
  PlayerAwardList = "player_award_list",
  TeamAwardList = "team_award_list",
  YearlyPlayerLeaderboard = "yearly_player_leaderboard",
  PlayerStatLeaderboard = "player_stat_leaderboard",

  Unknown = "unknown",
}

export enum DataSource {
  OSDB = "osdb",
  RealGM = "real_gm",
  SportsReference = "sports_reference",
  BasketballReference = "basketball_reference",
  HoopsHype = "hoops_hype",
}

export interface Link {
  id: string;
  link: string;
  createdAt?: Date;
  updatedAt?: Date;
  deleted: boolean;
  type: LinkType;
  dataSource: DataSource;
  lastVisited?: Date;
  lastAttempted?: Date;
  lastExtracted?: Date;
  lastFEVersion?: string;
  lastQueued?: Date;
}

export enum ProcessorType {
  FeatureExtractor = "feature_extractor",
  Scraper = "scraper",
}

export interface QueuedLink {
  createdAt?: Date;
  updatedAt?: Date;
  deleted: boolean;
  id: string;
  linkId: string;
  processorType: ProcessorType;
  processorVersion: string;
  queuedAt: Date;
  attemptedAt?: Date;
  completedAt?: Date;
  failedAt?: Date;
}