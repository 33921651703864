import React from "react";
import { Box } from "@mui/material";
import { DOTS, useIndicator } from "./PageIndicator.hooks";
import RectangleButton from "components/Buttons/RectangleButton";

interface IProps {
  pagesCount: number;
  currentPageIndex: number;
  siblingsCount: number;
  onClick: (pageIndex: number) => void;
}

export const PageIndicator = ({
  pagesCount,
  currentPageIndex,
  siblingsCount,
  onClick,
}: IProps) => {
  const { hasReachedLeft, hasReachedRight, indexes } = useIndicator(
    pagesCount,
    currentPageIndex,
    siblingsCount
  );
  return (
    <Box
      flexDirection="row"
      display="flex"
      justifyContent="center"
      component="div"
    >
      <>
        <RectangleButton
          text="<"
          color="#C4CDD5"
          borderColor={hasReachedLeft ? "#919EAB" : "#DFE3E8"}
          bgcolor={hasReachedLeft ? "#919EAB" : "#FFFFFF"}
          onClick={() => {
            !hasReachedLeft && onClick(currentPageIndex - 1);
          }}
        />
        {indexes.map((index) =>
          RectangleButton({
            text: index == DOTS ? "..." : (index + 1).toString(),
            color: index == currentPageIndex ? "#0541D8" : "#212B36",
            borderColor: index == currentPageIndex ? "#0541D8" : "#DFE3E8",
            bgcolor: "#FFFFFF",
            onClick: () => {
              index != DOTS && onClick(index);
            },
          })
        )}
        <RectangleButton
          text=">"
          color="#C4CDD5"
          borderColor={hasReachedRight ? "#919EAB" : "#DFE3E8"}
          bgcolor={hasReachedRight ? "#919EAB" : "#FFFFFF"}
          onClick={() => {
            !hasReachedRight && onClick(currentPageIndex + 1);
          }}
        />
      </>
    </Box>
  );
};
