import { getApexAccountByUserId } from "api/accounts";
import { createCashJournalTransfer } from "api/transfers";
import useForm from "components/Form/Form.hooks";
import { ApexAccount, ApexAccountType } from "models/ApexAccount";
import { useCallback, useEffect, useState } from "react";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

export default function useIntraTransferDialog(
  userId: string,
  onEdit: () => void
) {
  const [accounts, setAccounts] = useState<ApexAccount[]>([]);
  const { formValues, handleFormChange } = useForm({});

  const accountTypes = [ApexAccountType.Investment, ApexAccountType.Savings];
  const fetchAccounts = useCallback(
    () =>
      combineLatest(
        accountTypes.map((type) => getApexAccountByUserId(userId, type))
      ).pipe(map((results) => setAccounts(results.map(({ data }) => data)))),
    []
  );

  useEffect(() => {
    fetchAccounts().subscribe();
  }, []);

  const submit = () => {
    if (formValues.sourceAccount === formValues.destinationAccount) {
      return;
    }

    createCashJournalTransfer(userId, {
      amount: parseFloat(formValues.amount),
      sourceAccount: formValues.sourceAccount,
      destinationAccount: formValues.destinationAccount,
      userId,
      isFullDisbursement: false,
    }).subscribe(onEdit);
  };

  return {
    formValues,
    handleFormChange,
    submit,
    accounts,
  };
}
