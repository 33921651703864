import { DepositType, RecurringDeposit } from "models/ApexTransfer";
import { concat } from "rxjs";
import { superadmindelete, superadminpost } from "./superAdmin";

export const deleteUser = (id: string) => {
  return superadmindelete(`/admin/users/${id}`);
};

interface DepositRequest {
  amount: number;
  relationshipId: string;
  type: DepositType;
}

export const createDeposit = (id: string, deposit: DepositRequest) => {
  return superadminpost(`/admin/users/${id}/apex/deposits`, deposit);
};

interface WithdrawalRequest {
  amount: number;
  relationshipId: string;
  liquidateAll?: boolean;
  terminal?: boolean;
}

export const createLiquidation = (
  id: string,
  withdrawal: WithdrawalRequest
) => {
  return superadminpost(`/admin/users/${id}/apex/liquidations`, withdrawal);
};

export const createRecurringDeposit = (
  id: string,
  deposit: Partial<RecurringDeposit>
) => {
  return superadminpost(`/admin/users/${id}/apex/deposits`, deposit);
};
export const deleteRecurringDeposit = (id: string, depositId: string) => {
  return superadmindelete(
    `/admin/users/${id}/apex/deposits/recurring/${depositId}`
  );
};

export const updateRecurringDeposit = (
  id: string,
  deposit: RecurringDeposit
) => {
  return concat([
    deleteRecurringDeposit(id, deposit.id),
    createRecurringDeposit(id, deposit),
  ]);
};

export const cancelACHTransfer = (
  id: string,
  transferType: string,
  transferId: string
) => {
  return superadminpost(
    `/admin/users/${id}/apex/ach_transfers/${transferType}/${transferId}/cancel`
  );
};

export const cancelLiquidation = (id: string, liquidationId: string) => {
  return superadminpost(
    `/admin/users/${id}/apex/ach_transfers/liquidationRequest/${liquidationId}/cancel`
  );
};
