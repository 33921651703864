import { getLinkedAccountsByUserId } from "api/plaid";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

export default function useConnectedAccounts(userId: string) {
  const [accounts, setAccounts] = useState();

  const fetchConnectedAccounts = fetcher(
    getLinkedAccountsByUserId,
    [userId],
    setAccounts
  );

  useEffect(() => {
    fetchConnectedAccounts();
  }, [userId]);

  return accounts;
}
