import Page from "components/Common/Page";
import TextField from "@mui/material/TextField";
import { Paper, Box, Button } from "@mui/material";
import Spacer from "components/Common/Spacer";
import { useLocation } from "react-router-dom";
import useForm from "components/Form/Form.hooks";
import { getCheckPDF } from "api/onfido";

const OnfidoCheckDownloadPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { formValues, handleFormChange } = useForm({ id: params.get("id") });

  return (
    <Page heading="Download Check Files">
      <Paper>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          padding="32px 16px"
        >
          <TextField
            autoFocus
            margin="dense"
            id="id"
            label="Check ID"
            type="text"
            fullWidth
            variant="standard"
            value={formValues.id}
            name="id"
            onChange={handleFormChange}
          />
          <Spacer height={32} />
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              formValues.id &&
              getCheckPDF(formValues.id).subscribe(({ data }) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(data);

                // create "a" HTLM element with href to file & click
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", `${formValues.id}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
              })
            }
          >
            Download File
          </Button>
        </Box>
      </Paper>
    </Page>
  );
};

export default OnfidoCheckDownloadPage;
