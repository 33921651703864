import { get, post } from "./base";

export const reissueMicroDeposit = (id: string, relationshipId: string) => {
  return post(
    `/admin/users/${id}/apex/ach_relationships/${relationshipId}/reissue`
  );
};

export const getACHRelationshipDetails = (id: string) => {
  return get(`/admin/users/${id}/apex/ach_relationships/detail`);
};

export const cancelACHRelationship = (id: string, relationshipId: string) => {
  return post(
    `/admin/users/${id}/apex/ach_relationships/${relationshipId}/cancel`,
    true,
    { comment: "katara" }
  );
};

export const getApexACHRelationshipByUserId = (id: string) => {
  return get(`/admin/users/${id}/apex/ach_relationships/by_account`);
};

export const createACHRelationship = (
  id: string,
  itemId: string,
  bankAccountId: string,
  bankAccountNickname: string
) => {
  return post(`/v2/admin/users/${id}/apex/ach_relationships`, true, {
    itemId,
    bankAccountId,
    bankAccountNickname,
    approvalMethod: "PLAID_TOKEN",
  });
};
