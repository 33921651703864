import { NotificationInfo, getNotificationsInfo } from "api/notifications";
import { useState, useEffect } from "react";

export const ITEMS_PER_PAGE = 10;

export const useNotificationsPage = () => {
  const [currentPageIndex, setPage] = useState<number>(0);
  const [notificationInfo, setNotificationInfo] = useState<NotificationInfo[]>(
    []
  );
  const [pageNotificationInfo, setPageNotificationInfo] = useState<NotificationInfo[]>([]);

  const fetchNotificationInfo = () => {
    getNotificationsInfo().subscribe(({ data }) => {
      setNotificationInfo(data);
    });
  };

  useEffect(() => {
    fetchNotificationInfo();
  }, []);

  useEffect(() => {
    updatePageItems();
  }, [notificationInfo, currentPageIndex]);

  
  const updatePageItems = () => {
    const pageNotificationInfo = notificationInfo.slice(
      currentPageIndex * ITEMS_PER_PAGE,
      (currentPageIndex + 1) * ITEMS_PER_PAGE - 1
    );
    setPageNotificationInfo(pageNotificationInfo);
  };

  const onCreateClick = () => {
    window.open("/notifications/create", "_self");
  };

  const onNotificationInfoClick = (notificationInfo: NotificationInfo) => {
    window.open(`/notifications/${notificationInfo.id}`, "_self");
  };

  const onPageIndicatorClick = (pageIndex: number) => {
    setPage(pageIndex);
  };

  return {
    notificationInfo,
    pageNotificationInfo,
    currentPageIndex,
    onCreateClick,
    onNotificationInfoClick,
    onPageIndicatorClick,
  };
};
