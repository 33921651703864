import { getSuperAdminAuthToken } from "api/superAdmin";
import { useEffect, useState } from "react";

const useSuperAdmin = () => {
  const [token, setToken] = useState<string>();

  const checkToken = () => {
    try {
      const token = getSuperAdminAuthToken();

      setToken(token);
    } catch (e) {
      setToken(undefined);
    }
  };

  useEffect(() => {
    checkToken();
    const timer = setInterval(checkToken, 1000 * 5);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return { token };
};

export default useSuperAdmin;
