import * as React from "react";
import useSuperAdmin from "./SuperAdmin.hooks";

interface IProps {
  children: React.ReactNode;
}

const SuperAdminContext = React.createContext<string | undefined>(undefined!);

const SuperAdminProvider = ({ children }: IProps) => {
  const { token } = useSuperAdmin();

  return (
    <SuperAdminContext.Provider value={token}>
      {children}
    </SuperAdminContext.Provider>
  );
};

const useSuperAdminContext = () => {
  return React.useContext(SuperAdminContext);
};

export { SuperAdminProvider, useSuperAdminContext };
