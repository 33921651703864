import Page from "../../../components/Common/Page";
import useEducationSessionsListPage from "./EducationSessionsListPage.hooks";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Paper, Tab, Tabs } from "@mui/material";
import Spacer from "../../../components/Common/Spacer";
import { useNavigate } from "react-router-dom";
import CreateSessionDialog from "./CreateSessionDialog";

const EducationSessionsListPage = () => {
  const {TABS, tab, setTab, sessions, columns, refresh} = useEducationSessionsListPage()
  const navigate = useNavigate()

  return <Page
    heading="Education Sessions"
    RightHeadingComponent={<CreateSessionDialog onSave={refresh}/>}
  >
    <Tabs
      onChange={(_, value) => setTab(value)}
      value={tab}
    >
      {TABS.map((e) => (
        <Tab label={e} value={e}/>
      ))}
    </Tabs>
    <Spacer height={24}/>
    <Paper>
      <DataGrid
        autoHeight
        columns={columns}
        disableColumnMenu
        rowHeight={70}
        loading={sessions === undefined}
        pageSize={10}
        rows={sessions || []}
        onRowClick={(params) => navigate(`/education_sessions/${params.row.id}`)}
      />
    </Paper>
  </Page>
}

export default EducationSessionsListPage