import { ChevronRight } from "@mui/icons-material";
import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import Spacer from "components/Common/Spacer";
import useChatChannel from "components/Hooks/ChatChannel.hooks";
import { useNavigate } from "react-router-dom";

export default function CustomListItem(props: any) {
  const {
    active,
    channel,
    className: customClassName = "",
    displayImage,
    displayTitle,
    latestMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    unread,
    watchers,
  } = props;
  const navigate = useNavigate();
  const { user } = useChatChannel(channel);

  const avatarName = `${user?.givenName} ${user?.familyName}`;

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }

    navigate(`/chat/${channel.id}`);
  };

  return (
    <Button
      onClick={onSelectChannel}
      style={{ padding: 0, textTransform: "none" }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        height={100}
        bgcolor="white"
        width="100%"
        borderBottom="1px solid #E5E5E5"
      >
        <Box>
          <Avatar
            {...{
              children: avatarName[0],
            }}
          />
        </Box>
        <Spacer width={16} />
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          justifyContent="center"
          alignItems="flex-start"
          color="black"
        >
          <Typography variant="body1" fontWeight={unread ? "bold" : "normal"}>
            {`${user?.givenName} ${user?.familyName}`}
          </Typography>
          <Box
            width="100%"
            maxWidth={200}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              fontWeight={unread ? "bold" : "normal"}
            >
              {latestMessage}
            </Typography>
          </Box>
        </Box>
        {!!unread && <Chip label={unread} color="primary" />}
        <Box paddingRight={4} color="#212121">
          <ChevronRight />
        </Box>
      </Box>
    </Button>
  );
}
