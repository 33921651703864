import { EducationSessionQuestion } from 'models/EducationSession';
import { useEffect, useState } from 'react';
import { fetcher } from 'utils/data';
import { getQuestions } from 'api/educationSessions';

const useEducationQuestionSelect = (onChange: (question: EducationSessionQuestion) => void) => {
  const [questions, setQuestions] = useState<EducationSessionQuestion[]>([]);
  const [active, setActive] = useState<EducationSessionQuestion>();
  const [search, setSearch] = useState("")

  const fetchQuestions = fetcher(getQuestions, [search], setQuestions);

  useEffect(() => {
    fetchQuestions();
  }, [search]);

  return {questions, setActive, active, setSearch}
}

export default useEducationQuestionSelect