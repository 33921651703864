import { range } from "lodash";
import { useMemo } from "react";

export const DOTS = 100000000;
const INDIACTOR_COUNT = 5;

export const useIndicator = (
  pagesCount: number,
  currentPageIndex: number,
  siblingCount: number
) => {
  const indexes = useMemo(() => {
    if (pagesCount <= INDIACTOR_COUNT) {
      return range(0, pagesCount);
    }

    var result = [currentPageIndex];

    const leftSibilingsCount =
      currentPageIndex < siblingCount ? currentPageIndex : siblingCount;

    const toRight = pagesCount - (currentPageIndex + 1);
    const rightSibilingsCount = toRight > siblingCount ? siblingCount : toRight;

    const leftSibilings =
      leftSibilingsCount > 0
        ? range(
            currentPageIndex - 1,
            currentPageIndex - 1 - leftSibilingsCount,
            -1
          )
        : [];
    const rightSibilings =
      rightSibilingsCount > 0
        ? range(
            currentPageIndex + 1,
            currentPageIndex + 1 + rightSibilingsCount
          )
        : [];

    result = [...leftSibilings, ...result, ...rightSibilings];
    if (result.length >= INDIACTOR_COUNT) {
      return result;
    }

    if (result[0] != 0) {
      result = [DOTS, ...result];
    }
    if (result[result.length - 1] != pagesCount - 1) {
      result = [...result, DOTS];
    }

    return result;
  }, [pagesCount, siblingCount, currentPageIndex]);

  const hasReachedLeft = currentPageIndex == 0;
  const hasReachedRight = currentPageIndex == pagesCount - 1;

  return { hasReachedLeft, hasReachedRight, indexes };
};
