import { useEffect, useState } from "react";
import { ScoutPlan } from "../../../../../../models/ScoutPlan";
import { createScoutPlan, getScoutPlan, updateScoutPlan } from "../../../../../../api/users";
import { AxiosError, HttpStatusCode } from "axios";
import { firstValueFrom } from "rxjs";
import { UserScoutPlanDialogResult } from "./UserScoutPlanDialog";

export default function useUserScoutPlan(userId: string) {
  const [scoutPlan, setScoutPlan] = useState<ScoutPlan | null>();

  const fetchScoutPlan = async (): Promise<ScoutPlan | null> => {
    try {
      const response = await firstValueFrom(getScoutPlan(userId));
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status == HttpStatusCode.NotFound) {
        return null;
      } else {
        throw error;
      }
    }
  };

  useEffect(() => {
    fetchScoutPlan().then(setScoutPlan);
  }, [userId]);

  const onSave = async ({
                          investmentMonthlyDeposit,
                          notes,
                          savingsMonthlyDeposit,
                          spreadsheetUrl,
                          roundUps
                        }: UserScoutPlanDialogResult) => {
    const plan: ScoutPlan = {
      investmentMonthlyDeposit: parseFloat(investmentMonthlyDeposit),
      notes: notes?.trim(),
      savingsMonthlyDeposit: parseFloat(savingsMonthlyDeposit),
      spreadsheetUrl: spreadsheetUrl?.trim(),
      roundUps: roundUps,
      userId: userId,
    };
    const current = await fetchScoutPlan();
    if (current) {
      await updateScoutPlan(plan, userId);
      setScoutPlan({...current, ...plan});
    } else {
      await createScoutPlan(plan, userId);
      setScoutPlan(plan);
    }
  };

  return {onSave, scoutPlan};
};
