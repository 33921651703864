import { FileType } from "components/Form/S3FileInput/S3FileInput";
import { camelCase, startCase } from "lodash";

export const getAssetUrl = (key: string) => {
  return "https://assets.elevatewith.com/" + key.split(" ").join("+");
};

export const extractS3Key = (url: string) => {
  return url.split("/").slice(-2).join("/");
};

export const getContentTypeString = (type: FileType) => {
  switch (type) {
    case FileType.Gif:
      return "image/gif";
    case FileType.Trailer:
    case FileType.Clip:
      return "video/mp4";
    case FileType.CoachAvatar:
      return "image/png";
    case FileType.TagIcon:
      return "image/svg+xml";
    case FileType.SuggestedPurchaseImage:
    case FileType.Bundle:
      return "image/png";
    case FileType.Image:
      return "image/*";
    case FileType.PDF:
      return "application/pdf";
  }
};

export const capitalizeAll = (str: string) => {
  return startCase(camelCase(str));
};

export const isValidDollarString = (string: string) => {
  let cleared = string.trim();
  if (cleared.length === 0) {
    return false;
  }

  const leadingZerosExp = RegExp("^0+");
  if (leadingZerosExp.test(cleared)) {
    cleared = cleared.replace(leadingZerosExp, "");
  }

  let dotIndex = cleared.indexOf(".");
  if (dotIndex === cleared.length - 1) {
    cleared = cleared.substring(0, cleared.length - 1);
    dotIndex = -1;
  }

  const number = parseFloat(cleared);
  if (isNaN(number) || !isFinite(number)) {
    return false;
  }

  let minimumFractionDigits: number | undefined;
  if (dotIndex !== -1) {
    const fractionDigits = cleared.length - (dotIndex + 1);
    minimumFractionDigits = Math.min(fractionDigits, 2);
  }
  const formatted = Intl.NumberFormat("en-US", {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: 2,
    useGrouping: false,
  }).format(number);
  return cleared === formatted;
}
