import { Paper, TableContainer, Typography } from "@mui/material";
import CustomTable from "components/Table/CustomTable";
import useFundingAccount, { FundingAccountDialogType, } from "./FundingAccount.hooks";
import { ApexAccountType } from "models/ApexAccount";
import Loader from "components/Common/Loader";
import StatusBadge from "components/Badges/StatusBadge";
import useSuperAdmin from "components/Contexts/SuperAdminContext/SuperAdmin.hooks";
import { GridAlignment } from "@mui/x-data-grid";
import MenuButton from "components/Buttons/MenuButton";
import IdDialog from "components/Modals/IdDialog";
import AddFundingSourceDialog from "components/Modals/AddFundingSourceDialog/AddFundingSourceDialog";
import DetailsDialog from "components/Modals/DetailsDialog";
import { ACHRelationshipApprovalMethod } from "models/ApexACHRelationship";

interface IProps {
  userId: string;
  type: ApexAccountType;
}

export default function FundingAccount({ userId, type }: IProps) {
  const {
    relationship,
    account,
    onDialogClose,
    dialog,
    setDialog,
    getRelationshipDetails,
    removeFundingSource,
    reinitiateMicrodeposits,
  } = useFundingAccount(userId, type);
  const { token } = useSuperAdmin();

  if (!relationship) {
    return <Loader />;
  }

  const rows = [
    {
      cells: [
        {
          renderCell: () => (
            <>
              <Typography variant="body2" fontWeight="bold">
                {relationship.bankAccountNickname}
              </Typography>
              <Typography variant="body2">
                {relationship.bankAccountNickname}
              </Typography>
              {account && (
                <Typography variant="body2">
                  ${account.availableBalance} Available
                </Typography>
              )}
            </>
          ),
        },
        {
          renderCell: () => <StatusBadge status={relationship.status} />,
        },
        {
          renderCell: () => {
            const items = [];

            if (token && relationship.status.toLowerCase() === "approved") {
              items.push({
                name: "Remove Funding Source",
                onClick: removeFundingSource,
              });
            } else if (
              token &&
              relationship.status.toLowerCase() === "rejected"
            ) {
              items.push({
                name: "See Error Details",
                onClick: () => {
                  setDialog(FundingAccountDialogType.SeeErrorDetails);
                },
              });
            } else if (
              token &&
              relationship.status.toLowerCase() === "canceled"
            ) {
              items.push({
                name: "Make Funding Source",
                onClick: () => {
                  setDialog(FundingAccountDialogType.AddFundingSource);
                },
              });
            } else if (
              token &&
              relationship.approvalMethod === ACHRelationshipApprovalMethod.MicroDeposit &&
              relationship.status.toLowerCase() === "pending"
            ) {
              items.push({
                name: "Reinitiate Microdeposits",
                onClick: reinitiateMicrodeposits,
              });
            }

            items.push({
              name: "See Relationship ID",
              onClick: () => {
                setDialog(FundingAccountDialogType.SeeRelationshipId);
              },
            });

            return <MenuButton options={items} />;
          },
          align: "right" as GridAlignment,
        },
      ],
    },
  ];

  return (
    <>
      <TableContainer component={Paper}>
        <CustomTable headings={["Account", "Status", ""]} rows={rows} />
      </TableContainer>
      <IdDialog
        heading="Relationship ID"
        open={dialog === FundingAccountDialogType.SeeRelationshipId}
        id={relationship.id}
        onClose={onDialogClose}
      />
      {account && (
        <AddFundingSourceDialog
          open={dialog === FundingAccountDialogType.AddFundingSource}
          onClose={onDialogClose}
          userId={userId}
          account={account}
        />
      )}
      <DetailsDialog
        open={dialog === FundingAccountDialogType.SeeErrorDetails}
        onClose={onDialogClose}
        heading="Error Details"
        getDetails={getRelationshipDetails}
      />
    </>
  );
}
