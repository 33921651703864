import { EducationSessionQuestion } from "../../../models/EducationSession";
import { Box } from "@mui/system";
import { Paper, Switch, ToggleButton, Typography } from "@mui/material";
import React from "react";
import Spacer from "../../../components/Common/Spacer";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";

interface IProps {
  questions: EducationSessionQuestion[];
  onToggle: (id: string, on: boolean) => void;
}


const EducationSessionQuestionsSection = ({questions, onToggle}: IProps) => {
  const columns = [
    {
      field: "priority",
      headerName: "Order",
      width: 200,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1
    },
    {
      field: "formUrl",
      headerName: "Form URL",
      flex: 1
    },
    {
      field: "active",
      headerName: "Active",
      renderCell: (params: GridRenderCellParams) => {
        return <Box display="flex" alignItems="center">
          <Switch checked={params.value} onChange={(_, checked) => {
            onToggle(params.row.id, checked)
          }}/>
          <Typography>{params.value ? "Active" : "Inactive"}</Typography>
        </Box>
      },
      width: 200,
    }]

  return <Box display="flex" flexDirection="column">
    <Typography variant="h6">Questions</Typography>
    <Spacer height={8}/>
    <Paper>
      <DataGrid
        autoHeight
        columns={columns}
        disableColumnMenu
        rowHeight={70}
        pageSize={10}
        rows={questions || []}
      />
    </Paper>
  </Box>
}

export default EducationSessionQuestionsSection