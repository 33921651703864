import { Chip, Link, Paper } from "@mui/material";
import Page from "components/Common/Page";
import { DataGrid } from "@mui/x-data-grid";
import useScoutPlansPage from "./ScoutPlansPage.hooks";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { Fragment } from "react";
import { v4 } from "uuid";
import ScoutPlanDetailsDialog from "./ScoutPlanDetailsDialog";
import StatusBadge from "../../components/Badges/StatusBadge";
import ExpandableText from "../../components/Common/ExpandableText";

const ScoutPlansPage = () => {
  const {findUser, pageSize, setPage, setPageSize, scoutPlans, users} = useScoutPlansPage();

  const columns: GridColDef[] = [
    {
      field: "userId",
      headerName: "Customer Name",
      sortable: false,
      valueGetter: params => {
        const user = findUser(params.value);
        return user ? `${user.givenName} ${user.familyName}` : "";
      },
      width: 200
    },
    {
      field: "_",
      headerName: "Chat",
      renderCell: params => <Link href={`/chat/${params.row.userId}-advisor-channel`} target="_blank">Link</Link>,
      sortable: false,
      width: 200
    },
    {
      field: "__",
      headerName: "Invite Code",
      valueGetter: params => findUser(params.row.userId)?.inviteCodeUsed ?? "",
      sortable: false,
      width: 200
    },
    {
      field: "investmentMonthlyDeposit",
      headerName: "Monthly Investing",
      sortable: false,
      valueFormatter: params => `$${params.value}`,
      width: 200
    },
    {
      field: "savingsMonthlyDeposit",
      headerName: "Monthly Savings",
      sortable: false,
      valueFormatter: params => `$${params.value}`,
      width: 200
    },
    {
      field: "roundUps",
      headerName: "Round Ups",
      sortable: false,
      valueGetter: params => params.value ? "Yes" : "No",
      width: 200
    },
    {
      field: "notes",
      headerName: "Additional Notes",
      renderCell: params => <ExpandableText>{params.value}</ExpandableText>,
      sortable: false,
      width: 200,
    },
    {
      field: "initiated",
      headerName: "Initiated?",
      renderCell: params => params.value ? <Chip color="success" label="Yes"/> : <Chip color="error" label="No"/>,
      sortable: false,
      width: 200
    },
    {
      field: "enabled",
      headerName: "Current State",
      renderCell: params => params.row.initiated
        ? <StatusBadge status={params.value ? "Enabled" : "Needs Attention"}/>
        : <Fragment/>,
      sortable: false,
      width: 200
    },
    {
      field: "___",
      headerName: "See Details",
      renderCell: params => {
        const user = findUser(params.row.userId);
        const userName = user ? `${user.givenName} ${user.familyName}` : "";
        return <ScoutPlanDetailsDialog userId={params.row.userId} userName={userName}/>;
      },
      sortable: false,
      width: 200
    }
  ];

  return (
    <Page heading="Scout Plans">
      <Paper>
        <DataGrid
          autoHeight
          columns={columns}
          disableColumnMenu
          getRowHeight={() => "auto"}
          getRowId={v4}
          loading={scoutPlans === undefined || users === undefined}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
          rowCount={scoutPlans?.length}
          rows={scoutPlans || []}
          rowsPerPageOptions={[10, 25, 100]}
          sx={{"& .MuiDataGrid-cell": {padding: "9.5px"}}}/>
      </Paper>
    </Page>
  );
};

export default ScoutPlansPage;
