import { Link, ProcessorType, QueuedLink } from "models/zuko/Link";
import { Link as MuiLink, Typography } from "@mui/material";
import moment from "moment";
import { fetcher } from "utils/data";
import { getQueuedLink } from "api/zuko/pendingValidationItems";
import { useEffect, useState } from "react";
import { getS3LinkUrl } from "api/s3";

const useLinkTable = (model: Link) => {
  const [scraperQueued, setScraperQueued] = useState<QueuedLink>();
  const [featureExtractorQueued, setFeatureExtractorQueued] =
    useState<QueuedLink>();
  const [linkUrl, setLinkUrl] = useState<string>("");

  const fetchScraperQueued = fetcher(
    getQueuedLink,
    [model.id, ProcessorType.Scraper],
    setScraperQueued
  );
  const fetchFeatureExtractorQueued = fetcher(
    getQueuedLink,
    [model.id, ProcessorType.FeatureExtractor],
    setFeatureExtractorQueued
  );

  const getLinkPage = async () => {
    if (!process.env.REACT_APP_LINK_S3_BUCKET) {
      return;
    }

    getS3LinkUrl(
      `${process.env.REACT_APP_ENV}/${model.dataSource}/${model.id}.html`
    ).subscribe((data) => {
      setLinkUrl(data);
    });
  };

  useEffect(() => {
    fetchScraperQueued();
    fetchFeatureExtractorQueued();
    getLinkPage();
  }, [model.id]);

  const tableRows = [
    {
      headerName: "Link ID",
      value: model.id,
    },
    {
      headerName: "Link URL",
      value: model.link,
      renderCell: () => (
        <MuiLink href={model.link} target="_blank">
          <Typography
            variant="body2"
            width={500}
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {model.link}
          </Typography>
        </MuiLink>
      ),
    },
    {
      headerName: "Local Link Page",
      value: model.id,
      renderCell: () => {
        return !linkUrl ? (
          <Typography
            variant="body2"
            width={500}
            textOverflow="ellipsis"
            overflow="hidden"
          >
            N/A
          </Typography>
        ) : (
          <MuiLink href={linkUrl} target="_blank">
            <Typography
              variant="body2"
              width={500}
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {linkUrl}
            </Typography>
          </MuiLink>
        );
      },
    },
    {
      headerName: "Last Queued For Scraping",
      value: moment(scraperQueued?.queuedAt).format("MM/DD/YYYY"),
    },
    {
      headerName: "Last Scraped Successfully",
      value: moment(scraperQueued?.completedAt).format("MM/DD/YYYY"),
    },
    {
      headerName: "Last Queued For Feature Extraction",
      value: moment(featureExtractorQueued?.queuedAt).format("MM/DD/YYYY"),
    },
    {
      headerName: "Last Extracted Successfully",
      value: moment(featureExtractorQueued?.completedAt).format("MM/DD/YYYY"),
    },
    {
      headerName: "Last Feature Extractor Version",
      value: featureExtractorQueued?.processorVersion,
    },
  ];

  const getHeading = () => {
    return `Link Information`;
  };

  return { tableRows, heading: getHeading() };
};

export default useLinkTable;
