import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import MuiDivider from "@mui/material/Divider";
import MenuDrawer from "./MenuDrawer";
import { getAuthToken } from "api/base";

const drawerWidth = 240;

export default function NavBar() {
  const [open, setOpen] = React.useState(false);
  const authToken = getAuthToken();

  const handleMenuClick = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const copyAuthToken = () => {
    navigator.clipboard.writeText(authToken);
  };

  const drawerContent = (
    <>
      <Toolbar />
      <MuiDivider />
      <MenuDrawer />
    </>
  );

  return (
    <Box>
      <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" flex={1} />
          {authToken ? (
            <Button color="inherit" onClick={() => copyAuthToken()}>
              Copy Auth Token
            </Button>
          ) : (
            <Button
              color="inherit"
              onClick={() => window.open("/login", "_self")}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        open
        sx={{
          display: {xs: "none", sm: "block"},
          width: drawerWidth,
          "& .MuiDrawer-paper": {boxSizing: "border-box", width: drawerWidth},
        }}
        variant="permanent">
        {drawerContent}
      </Drawer>
      <Drawer
        sx={{
          display: {xs: "block", sm: "none"},
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {width: drawerWidth, boxSizing: "border-box"},
        }}
        variant="temporary"
        open={open}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
}
