import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
} from "@mui/material";
import { ReactNode } from "react";

interface IProps<T> {
  children: ReactNode;
  loading: boolean;
  page: number;
  onPageChange: (page: number) => void;
  rowCount: number;
  rowsPerPage: number;
}

export default function TransfersTable<T>({children, loading, page, onPageChange, rowCount, rowsPerPage}: IProps<T>) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableCell>Transfer</TableCell>
        </TableHead>
        <TableBody>
          {loading ? <CircularProgress/> : children}
        </TableBody>
        <TableFooter>
          <TablePagination
            labelRowsPerPage={undefined}
            count={rowCount}
            onPageChange={(_, value) => onPageChange(value)}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}/>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
