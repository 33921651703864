import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { ErrorOutline, MoreVert } from "@mui/icons-material";
import { Institution } from "../../../../models/plaid/Institution";
import useExpiredConnectionsTable from "./ExpiredConnectionsTable.hooks";
import Spacer from "../../../../components/Common/Spacer";

export interface IProps {
  institutions: Institution[];
  onConnectionUpdated: (itemId: string) => void;
}

export default function ExpiredConnectionsTable({institutions, onConnectionUpdated}: IProps) {
  const {
    menuAnchor,
    onMenuClick,
    onMenuClose,
    onUpdateConnectionClick
  } = useExpiredConnectionsTable(onConnectionUpdated);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableCell>Connection</TableCell>
        </TableHead>
        <TableBody>
          {institutions.map(e => (
            <TableRow key={e.itemId}>
              <TableCell sx={{alignItems: "center", display: "flex", flexDirection: "row"}}>
                <ErrorOutline color="error"/>
                <Spacer width={8}/>
                <Typography color="error" fontSize={14} fontWeight="bold" flex={1}>{e.name}</Typography>
                <IconButton onClick={onMenuClick}>
                  <MoreVert/>
                </IconButton>
                <Menu anchorEl={menuAnchor} onClose={onMenuClose} open={!!menuAnchor}>
                  <MenuItem onClick={() => onUpdateConnectionClick(e.itemId)}>Update Connection</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}