import { Fragment, useEffect, useState } from "react";
import { EducationSession } from "models/EducationSession";
import { fetcher } from "utils/data";
import { getEducationSessions } from "../../../api/educationSessions";
import moment from "moment";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { Chip, Link } from "@mui/material";
import ExpandableText from "../../../components/Common/ExpandableText";
import StatusBadge from "../../../components/Badges/StatusBadge";
import ScoutPlanDetailsDialog from "../../ScoutPlans/ScoutPlanDetailsDialog";

const TABS = ["Upcoming Sessions", "Previous Sessions"]
const columns: GridColDef[] = [
  {
    field: "date",
    headerName: "Date",
    valueGetter: params => {
      return moment(params.value).utc().format("MMMM D, YYYY")
    },
    flex: 1
  },
  {
    field: "inviteCode",
    headerName: "Invite Code",
    flex: 1
  },
  {
    field: "numQuestions",
    headerName: "Number Of Questions",
    width: 200
  },
  {
    field: "active",
    headerName: "Active",
    renderCell: (params) => {
      let status = params.value ? "Active" : "Inactive"
      if (params.row.ended) {
        status = "Ended"
      }
      return <StatusBadge status={status} />
    },
    width: 200
  }
];

const useEducationSessionsListPage = () => {
  const [sessions, setSessions] = useState<EducationSession[]>()
  const [tab, setTab] = useState<string>(TABS[0])

  const fetchSessions = (since?: string, before?: string) => {
    fetcher(getEducationSessions, [since, before], setSessions)();
  }

  useEffect(() => {
    refresh()
  }, [tab]);

  const refresh =  () => {
    if (tab === TABS[0]) {
      fetchSessions(moment().toISOString())
    } else {
      fetchSessions(undefined, moment().toISOString())
    }
  }

  return {TABS, sessions, setTab, tab, columns, refresh}
}

export default useEducationSessionsListPage