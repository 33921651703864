import {
  CircularProgress,
  Paper,
  Table,
  TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import { Account } from "../../../../models/plaid/Account";

interface IProps {
  accounts: Account[];
  loading: boolean;
}

export default function AccountsTable({accounts, loading}: IProps) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableCell>Account</TableCell>
        </TableHead>
        <TableBody>
          {loading ? <CircularProgress/> : (
            <>
              {accounts.map(e => (
                <TableRow key={e.id}>
                  <TableCell>
                    <Typography>
                      <b>{e.name}</b><br/>
                      {e.mask && <>ending in ${e.mask}<br/></>}
                      {e.balance && `$${e.balance.toFixed(2)}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
