import { Player } from "models/zuko/Player";
import {
  PlayerAward,
  getReadablePlayerAwardCategory,
} from "models/zuko/PlayerAward";
import { formatUTCDate } from "utils/time";

interface PlayerAwardData {
  playerAward: PlayerAward;
  player: Player;
}

const usePlayerAwardTable = (model: PlayerAwardData) => {
  const tableRows = [
    {
      headerName: "ID",
      value: model.playerAward.id,
    },
    {
      headerName: "Player First Name",
      value: model.player.givenName,
    },
    {
      headerName: "Player Last Name",
      value: model.player.familyName,
    },
    {
      headerName: "Year",
      value: model.playerAward.startYear
        ? formatUTCDate(model.playerAward.startYear, "YYYY")
        : "N/A",
    },
    {
      headerName: "Type",
      value: model.playerAward.type,
    },
    {
      headerName: "Award Category",
      value: getReadablePlayerAwardCategory(model.playerAward.category),
    },
    {
      headerName: "Stat",
      value: model.playerAward.stat,
    },
    {
      headerName: "Place",
      value: model.playerAward.place,
    },
    {
      headerName: "Team Award?",
      value: model.playerAward.teamAward ? "Yes" : "No",
    },
    {
      headerName: "Career Award?",
      value: model.playerAward.careerAward ? "Yes" : "No",
    },
  ];

  const getHeading = () => {
    return `Player Award Information`;
  };

  return {
    tableRows,
    heading: getHeading(),
  };
};

export default usePlayerAwardTable;
