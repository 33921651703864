import DialogModal from "../../../../../components/Modals/DialogModal";
import { noop } from "lodash";
import { Alert, Box, Button, Card, CardContent, CardHeader, TextField } from "@mui/material";
import useAddNoteDialog from "./AddNoteDialog.hooks";
import Spacer from "components/Common/Spacer";

export interface AddNoteDialogResult {
  note: string;
  subject: string;
}

interface IProps {
  onSubmit: (result: AddNoteDialogResult) => void;
}

export default function AddNoteDialog({onSubmit}: IProps) {
  const {formValues, handleFormChange, isNoteValid, isSubjectValid} = useAddNoteDialog();

  let alert: string | undefined;
  if (!isSubjectValid) {
    alert = "Subject is required";
  } else if (!isNoteValid) {
    alert = "Notes is required";
  }

  return (
    <DialogModal
      onClose={noop}
      renderDialog={(_, setOpen) => (
        <Box minWidth={400}>
          <Card>
            <CardHeader title="Add Note" titleTypographyProps={{variant: "h5"}}/>
            <CardContent>
              <TextField
                fullWidth
                label="Subject"
                onChange={handleFormChange}
                name="subject"
                value={formValues.subject}
                variant="standard"/>
              <Spacer height={20}/>
              <TextField
                fullWidth
                label="Notes"
                onChange={handleFormChange}
                name="note"
                value={formValues.note}
                variant="standard"/>
              {alert && (
                <>
                  <Spacer height={20}/>
                  <Alert severity="error">{alert}</Alert>
                </>
              )}
              <Spacer height={20}/>
              <Button fullWidth onClick={() => {
                setOpen(false);
                onSubmit(formValues);
              }} variant="contained">Submit</Button>
            </CardContent>
          </Card>
        </Box>
      )}
      renderDisplay={(open, setOpen) => (
        <Button fullWidth onClick={() => setOpen(!open)} variant="contained">Add Note</Button>)}
    />
  );
}
