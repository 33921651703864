import { Box } from "@mui/material";
import SelectFileButton from "components/Buttons/SelectFileButton";
import { useEffect, useReducer } from "react";
import { getContentTypeString } from "utils/strings";
import S3FileTypeInput from "../S3FileTypeInput/S3FileTypeInput";
import s3FileFormReducer, {
  ActionType,
  INITIAL_S3_FILE_FORM,
  IS3FileForm,
} from "./s3FileForm.reducer";

interface IProps {
  onChange: (value: IS3FileForm) => void;
  disabled?: boolean;
}

const S3FileForm = ({ onChange, disabled }: IProps) => {
  const [state, dispatch] = useReducer(s3FileFormReducer, INITIAL_S3_FILE_FORM);

  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <>
      <Box marginBottom="20px" width="100%">
        <S3FileTypeInput
          label="File Type"
          onChange={(value) =>
            dispatch({ type: ActionType.UpdateType, data: value })
          }
          disabled={disabled}
        />
      </Box>
      <Box width="100%" display="flex" flexDirection="row" alignItems="center">
        <SelectFileButton
          disabled={disabled}
          onSelect={(file) =>
            dispatch({ type: ActionType.UpdateFile, data: file })
          }
          acceptType={
            state.type ? getContentTypeString(state.type) : "image/png"
          }
        />
        {state.file && <Box marginLeft="8px">{state.file.name}</Box>}
      </Box>
    </>
  );
};

export default S3FileForm;
