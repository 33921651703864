import { SyntheticEvent, useEffect, useState } from "react";
import { KataraUser } from "../../models/KataraUser";
import { getMe } from "../../api/user";
import { fetcher } from "utils/data";
import { usePlaidLink } from "react-plaid-link";
import { createLinkToken, exchangePublicToken } from "../../api/plaid";
import { OfficerComplianceTab } from "./OfficerCompliancePage";

export default function useOfficerCompliancePage() {
  const [user, setUser] = useState<KataraUser>();
  const [token, setToken] = useState<string | null>(null);
  const [tab, setTab] = useState<OfficerComplianceTab>(OfficerComplianceTab.MyAccounts);
  const [recentlyConnectedInstitutions, setRecentlyConnectedInstitutions] = useState<number>(0);

  const fetchUser = fetcher(getMe, [], setUser);

  useEffect(() => {
    fetchUser();
  }, []);

  const {open, ready} = usePlaidLink({
    token: token,
    onSuccess: (publicToken) => {
      exchangePublicToken(publicToken).subscribe(() => {
        setRecentlyConnectedInstitutions(recentlyConnectedInstitutions + 1);
      });
    },
  });

  useEffect(() => {
    if (ready) {
      open();
    }
  }, [open, ready]);

  const fetchToken = fetcher(createLinkToken, [], setToken, "linkToken");

  const handleTabChange = (event: SyntheticEvent, value: any) => setTab(value);

  return {
    handleTabChange,
    isComplianceOfficer: user?.complianceOfficer ?? false,
    onConnectInvestmentAccountsClick: fetchToken,
    recentlyConnectedInstitutions,
    tab,
    userName: user ? `${user.givenName} ${user.familyName}` : undefined,
  };
}
