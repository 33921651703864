import { filter, findIndex, isEqual } from "lodash";
import {
  AdvisorArchModelTarget,
  AdvisorArchSecurityModel,
} from "models/advisor_arch/AdvisorArchSecurityModel";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const useSecurityModelDialog = (
  model: AdvisorArchSecurityModel,
  onClose: () => void,
  refetch: () => void
) => {
  const [targets, setTargets] = useState<AdvisorArchModelTarget[]>(
    model.model_targets
  );

  const onRowChange = (newRow: AdvisorArchModelTarget) => {
    const index = findIndex(targets, (target) => target.security?.cusip === newRow.security?.cusip);
    let head: AdvisorArchModelTarget[] = [];
    if (index === 0) {
      head.push(newRow);
    } else {
      head = targets.slice(0, index);
      head.push(newRow);
    }
    let tail: AdvisorArchModelTarget[] = [];
    if (index !== targets.length - 1) {
      tail = targets.slice(index + 1);
    }
    setTargets(head.concat(tail));
  };

  const onRowDelete = (cusip: string) => {
    setTargets(filter(targets, (target) => target.security?.cusip !== cusip));
  };

  const onRowAdd = () => {
    setTargets(
      targets.concat([
        {
          taxable_weight: 0,
          non_taxable_weight: 0,
          security: {
            cusip: uuidv4(),
            long_name: "",
            symbol: "",
            fractional_trade_eligible: "",
            id: 0,
            short_name: "",
            type: "",
          },
        },
      ])
    );
  };

  const onSave = (values: Partial<AdvisorArchSecurityModel>) => {
    // updateBundle(
    //   {...bundle, ...values},
    //   securities.map((security: any) => ({
    //     ...security,
    //     weight: parseFloat(security.weight),
    //   }))
    // ).subscribe(() => {
    //   onClose();
    //   refetch();
    // });
  };

  return {
    targets,
    setTargets,
    onRowChange,
    onRowDelete,
    onRowAdd,
    onSave,
    editing: !isEqual(targets, model.model_targets),
  };
};

export default useSecurityModelDialog;
