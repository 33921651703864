import { ApexAccountType } from "./ApexAccount";

export enum ACHRelationshipStatus {
  Pending = "PENDING",
  Rejected = "REJECTED",
  Canceled = "CANCELED",
  Approved = "APPROVED",
  Prepending = "PREPENDING",
}

export enum ACHRelationshipApprovalMethod {
  MicroDeposit = "MICRO_DEPOSIT",
  PlaidToken = "PLAID_TOKEN",
  UseExisting = "USE_EXISTING"
}

export interface ApexACHRelationship {
  apexAccountId: string;
  apexAccountType: ApexAccountType;
  approvalMethod: ACHRelationshipApprovalMethod;
  bankAccountId: string;
  bankAccountNickname: string;
  id: string;
  itemId: string;
  status: ACHRelationshipStatus;
  userId: string;
}
