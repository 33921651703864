import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { GridAlignment } from "@mui/x-data-grid";

interface CustomTableCell {
  value?: string | number;
  renderCell?: () => JSX.Element;
  align?: GridAlignment;
}

interface CustomTableRow {
  cells: CustomTableCell[];
}

interface IProps {
  headings: string[];
  rows: CustomTableRow[];
}

export default function CustomTable({ headings, rows }: IProps) {
  return (
    <Table sx={{ minWidth: "50vw" }}>
      <TableHead>
        <TableRow>
          {headings.map((heading, index) => (
            <TableCell key={index}>{heading}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index}>
            {row.cells.map((cell, index) => (
              <TableCell key={index} align={cell.align}>
                {cell.renderCell ? cell.renderCell() : cell.value}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
