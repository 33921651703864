import { Paper, TableContainer } from "@mui/material";
import useRecurringDeposits from "components/Hooks/RecurringDeposits.hooks";
import HorizontalTable from "components/Table/HorizontalTable";
import { ApexAccountType } from "models/ApexAccount";
import { DepositType, Frequency } from "models/ApexTransfer";
import EditPurchaseRoundUpsDialog from "./EditPurchaseRoundUpsDialog/EditPurchaseRoundUpsDialog";

interface IProps {
  userId: string;
}

export default function PurchaseRoundUps({ userId }: IProps) {
  const { deposit, fetchDeposits } = useRecurringDeposits(
    userId,
    ApexAccountType.Investment,
    (deposit) =>
      deposit.frequency.startsWith(Frequency.Daily) &&
      deposit.type === DepositType.RoundUp
  );

  const rows = [
    {
      headerName: `Status: ${deposit ? "Enabled" : "Disabled"}`,
      action: () => (
        <EditPurchaseRoundUpsDialog
          userId={userId}
          deposit={deposit}
          onEdit={fetchDeposits}
        />
      ),
    },
  ];

  return (
    <TableContainer component={Paper}>
      <HorizontalTable rows={rows} />
    </TableContainer>
  );
}
