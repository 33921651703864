import { capitalize } from "lodash";

export enum StatCategory {
  BlockPercentage = "block_percentage",
  Blocks = "blocks",
  BlocksPerGame = "blocks_per_game",
  BoxPlusMinus = "box_plus_minus",
  OffensiveBoxPlusMinus = "offensive_box_plus_minus",
  DefensiveBoxPlusMinus = "defensive_box_plus_minus",
  PersonalFouls = "personal_fouls",
  PlayerEfficiencyRating = "player_efficiency_rating",
  Points = "points",
  PointsPerGame = "points_per_game",
  TotalReboundPercentage = "total_rebound_percentage",
  TotalRebounds = "total_rebounds",
  TotalReboundsPerGame = "total_rebounds_per_game",
  OffensiveReboundPercentage = "offensive_rebound_percentage",
  OffensiveRebounds = "offensive_rebounds",
  DefensiveReboundPercentage = "defensive_rebound_percentage",
  DefensiveRebounds = "defensive_rebounds",
  Assists = "assists",
  AssistsPerGame = "assists_per_game",
  Steals = "steals",
  StealsPerGame = "steals_per_game",
  FieldGoalPercentage = "field_goal_percentage",
  FreeThrowPercentage = "free_throw_percentage",
  ThreePointPercentage = "three_point_percentage",
  TwoPointPercentage = "two_point_percentage",
  EffectiveFieldGoalPercentage = "effective_field_goal_percentage",
  TrueShootingPercentage = "true_shooting_percentage",
  FieldGoals = "field_goals",
  FieldGoalsAttempts = "field_goals_attempts",
  FieldGoalsMissed = "field_goals_missed",
  TwoPointFieldGoals = "two_point_field_goals",
  TwoPointFieldGoalAttempts = "two_point_field_goal_attempts",
  TwoPointFieldGoalPercentage = "two_point_field_goal_percentage",
  ThreePointFieldGoals = "three_point_field_goals",
  ThreePointFieldGoalAttempts = "three_point_field_goal_attempts",
  TripleDoubles = "triple_doubles",
  FreeThrows = "free_throws",
  FreeThrowAttempts = "free_throw_attempts",
  Games = "games",
  MinutesPlayed = "minutes_played",
  MinutesPerGame = "minutes_per_game",
  Turnovers = "turnovers",
  WinShares = "win_shares",
  WinSharesPer48Minutes = "win_shares_per_48_minutes",
  OffensiveWinShares = "offensive_win_shares",
  DefensiveWinShares = "defensive_win_shares",
  TurnoverPercentage = "turnover_percentage",
  Awards = "awards",
  Unknown = "",
  ValueOverReplacementPlayer = "value_over_replacement_player",
  OffensiveRating = "offensive_rating",
  DefensiveRating = "defensive_rating",
  UsagePercentage = "usage_percentage",
}

export interface PlayerAward {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  deleted?: boolean;
  playerId: string;
  startYear?: Date;
  category: StatCategory;
  stat?: number | null;
  place?: number | null;
  type: string;
  teamAward: boolean;
  careerAward: boolean;
}

export const getReadablePlayerAwardCategory = (category: StatCategory) => {
  return (category || "")
    .split("_")
    .map((w) => capitalize(w))
    .join(" ");
};

export interface PlayerAwardType {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  deleted?: boolean;
  abbreviation: string;
  fullName: string;
  linkId: string;
  link: string;
}
