import { Box } from "@mui/material";
import { S3 } from "aws-sdk";
import Link, { LinkType } from "components/Common/Link";
import React from "react";
import { getAssetUrl } from "utils/strings";
import CustomSelectInput from "../CustomSelectInput";
import useS3FileInput from "./S3FileInput.hooks";

export enum FileType {
  Gif = "gifs/",
  Trailer = "clips/",
  Clip = "clips/",
  CoachAvatar = "coaches/",
  TagIcon = "tags/",
  Bundle = "bundles/",
  SuggestedPurchaseImage = "spending_goals/suggested_purchases/",
  Image = "images/",
  PDF = "pdfs/",
}

interface IProps {
  type: FileType;
  label: string;
  onChange: (key: string) => void;
  value?: string;
  disabled?: boolean;
}

const S3FileInput = ({ type, label, onChange, value, disabled }: IProps) => {
  const { files } = useS3FileInput(type);

  return (
    <>
      <CustomSelectInput
        disabled={disabled}
        label={label}
        value={value}
        showLabel={!value}
        onChange={onChange}
        options={files || []}
        renderOption={(file: S3.Object) => (
          <option value={file.Key} key={file.Key}>
            {file.Key}
          </option>
        )}
      />
      <Box marginTop="8px" display="flex" flexDirection="row" textAlign="left">
        <Box flex={1}>
          <Link type={LinkType.Helper} path="/upload">
            Can’t find the file you’re looking for? Create new file here.
          </Link>
        </Box>
        {value && (
          <Box>
            <Link type={LinkType.Helper} path={getAssetUrl(value)} newTab>
              Preview
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
};

export default S3FileInput;
