import { FormControl, InputLabel, Select } from "@mui/material";
import React from "react";

interface IProps {
  label: string;
  showLabel: boolean;
  value?: any;
  onChange: (value: string) => void;
  options: any[];
  renderOption: (data: any) => any;
  caption?: any;
  disabled?: boolean;
}

const CustomSelectInput = ({
  label,
  showLabel,
  value,
  onChange,
  options,
  renderOption,
  caption,
  disabled,
}: IProps) => {
  const displayLabel = showLabel ? label : undefined;
  const tag = label.split(" ").join("-").toLowerCase();

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="outlined-trailer-native-simple">
        {displayLabel}
      </InputLabel>
      <Select
        disabled={disabled}
        native
        value={value}
        // onChange={(e) => onChange(e.currentTarget.value as string)}
        label={displayLabel}
        placeholder={displayLabel}
        inputProps={{
          name: tag,
          id: `outlined-${tag}-native-simple`,
        }}
        onChange={(e) => onChange(e.target.value)}
        required
      >
        <option aria-label="None" value="" />
        {options.map((option) => renderOption(option))}
      </Select>
      {caption}
    </FormControl>
  );
};

export default CustomSelectInput;
