import { Alert, Box, Switch, TextField, Typography } from "@mui/material";
import Spacer from "../Common/Spacer";

interface IProps {
  disableRoundUps: boolean;
  formValues: { [key: string]: any };
  handleFormChange: (e: any) => void;
  roundUps: boolean;
  setRoundUps: (value: boolean) => void;
  showURLRequiredAlert: boolean;
}

const ScoutPlanForm = ({
                         disableRoundUps,
                         formValues,
                         handleFormChange,
                         roundUps,
                         setRoundUps,
                         showURLRequiredAlert
                       }: IProps) => {
  return (
    <>
      <TextField
        inputProps={{inputMode: 'numeric'}}
        label="Investing Monthly Deposit ($)"
        onChange={handleFormChange}
        name="investmentMonthlyDeposit"
        required
        value={formValues.investmentMonthlyDeposit}
        variant="standard"
        fullWidth/>
      <Spacer height={20}/>
      <TextField
        inputProps={{inputMode: 'numeric'}}
        label="Savings Monthly Deposit ($)"
        onChange={handleFormChange}
        name="savingsMonthlyDeposit"
        required
        value={formValues.savingsMonthlyDeposit}
        variant="standard"
        fullWidth/>
      <Spacer height={20}/>
      <Box display="flex" alignItems="center">
        <Typography>Add Round Ups?</Typography>
        <Spacer width={20}/>
        <Switch
          disabled={disableRoundUps}
          checked={roundUps}
          onChange={(_, checked) => setRoundUps(checked)}/>
      </Box>
      <Spacer height={20}/>
      {disableRoundUps && (<>
        <Alert severity="warning">Round Ups cannot be enabled because user has a manually linked bank account.</Alert>
        <Spacer height={20}/>
      </>)}
      <TextField
        fullWidth
        label="URL to Scout Plan"
        onChange={handleFormChange}
        multiline
        name="spreadsheetUrl"
        required
        value={formValues.spreadsheetUrl}
        variant="standard"/>
      <Spacer height={20}/>
      <TextField
        fullWidth
        label="Additional Notes (Optional)"
        onChange={handleFormChange}
        multiline
        name="notes"
        value={formValues.notes}
        variant="standard"/>
      {showURLRequiredAlert && (<>
        <Spacer height={20}/>
        <Alert severity="error">Scout Plan URL is required</Alert>
      </>)}
    </>
  );
};

export default ScoutPlanForm;
