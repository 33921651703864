import {
  createRecurringDeposit,
  deleteRecurringDeposit,
  updateRecurringDeposit,
} from "api/superAdminActions";
import useForm from "components/Form/Form.hooks";
import useACHRelationship from "components/Hooks/ACHRelationship.hooks";
import { ApexAccountType } from "models/ApexAccount";
import { DepositType, Frequency, RecurringDeposit } from "models/ApexTransfer";

export default function useEditRecurringDeposit(
  userId: string,
  type: ApexAccountType,
  deposit?: RecurringDeposit,
  onEdit?: () => void
) {
  const relationship = useACHRelationship(userId, type);
  const { formValues, handleFormChange } = useForm(deposit || {});
  const { amount } = formValues;

  const onSave = () => {
    if (deposit) {
      updateRecurringDeposit(userId, {
        ...deposit,
        amount: parseFloat(amount),
      }).subscribe(onEdit);
    } else if (relationship) {
      createRecurringDeposit(userId, {
        amount: parseFloat(amount),
        relationshipId: relationship.id,
        frequency: Frequency.Monthly,
        type: DepositType.Manual,
      }).subscribe(onEdit);
    }
  };

  const onDelete = () => {
    if (deposit) {
      deleteRecurringDeposit(userId, deposit.id).subscribe(onEdit);
    }
  };

  return {
    onSave,
    onDelete,
    allowEdit: !!relationship,
    handleFormChange,
    formValues,
  };
}
