import { Link } from "@mui/material";
import { getAccountSettings } from "api/accounts";
import DetailsDialog from "components/Modals/DetailsDialog";
import DialogModal from "components/Modals/DialogModal";
import { noop } from "lodash";
import { map } from "rxjs/operators";

interface IProps {
  userId: string;
}

export default function AccountSettingsDialog({ userId }: IProps) {
  return (
    <DialogModal
      renderDisplay={(open, setOpen) => (
        <Link onClick={() => setOpen(true)}>View Settings</Link>
      )}
      renderDialog={(open, setOpen) => (
        <DetailsDialog
          open={open}
          onClose={() => setOpen(false)}
          heading="Account Settings"
          getDetails={() =>
            getAccountSettings(userId).pipe(
              map(({ data }) => JSON.stringify(data, null, 2))
            )
          }
        />
      )}
      onClose={noop}
    />
  );
}
