import React from "react";
import {
  Box,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { TrustedContact } from "models/User";
import Spacer from "components/Common/Spacer";
import DialogModal from "components/Modals/DialogModal";
import useForm from "components/Form/Form.hooks";
import { updateTrustedContact } from "api/users";

interface IProps {
  contact?: TrustedContact;
  userId: string;
  onClose: () => void;
}

const TrustedContactDialog = ({ userId, contact, onClose }: IProps) => {
  const { formValues, handleFormChange } = useForm(contact || {});
  const { givenName, familyName, phoneNumber, emailAddress } = formValues;

  const onSave = () => {
    updateTrustedContact(userId, {
      givenName,
      familyName,
      phoneNumber,
      emailAddress,
    });
  };

  return (
    <DialogModal
      onClose={onClose}
      renderDisplay={(open, setOpen) => (
        <Box onClick={() => setOpen(true)} width="100%" height="100%">
          {contact ? (
            <>
              <Typography>
                {contact.givenName} {contact.familyName}
              </Typography>
              <Typography>{contact.phoneNumber}</Typography>
            </>
          ) : (
            <Typography>Add Trusted Contact</Typography>
          )}
        </Box>
      )}
      renderDialog={(open, setOpen) => (
        <Box minWidth={400}>
          <DialogTitle>Edit Trusted Contact</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="givenName"
              label="First Name"
              type="text"
              fullWidth
              variant="standard"
              value={givenName}
              name="givenName"
              onChange={handleFormChange}
            />
            <Spacer height={20} />
            <TextField
              margin="dense"
              id="familyName"
              label="Last Name"
              type="text"
              fullWidth
              variant="standard"
              value={familyName}
              name="familyName"
              onChange={handleFormChange}
            />
            <Spacer height={20} />
            <TextField
              margin="dense"
              id="phoneNumber"
              label="Phone Number"
              type="phoneNumber"
              fullWidth
              variant="standard"
              value={phoneNumber}
              name="phoneNumber"
              onChange={handleFormChange}
            />
            <Spacer height={20} />
            <TextField
              margin="dense"
              id="email"
              label="Email (optional)"
              type="email"
              fullWidth
              variant="standard"
              value={emailAddress}
              name="emailAddress"
              onChange={handleFormChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onSave();
                setOpen(false);
              }}
              size="large"
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      )}
    />
  );
};

export default TrustedContactDialog;
