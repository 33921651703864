import { getApexACHRelationshipByUserId } from "api/achRelationships";
import { ApexACHRelationship } from "models/ApexACHRelationship";
import { ApexAccountType } from "models/ApexAccount";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

export default function useACHRelationship(
  userId: string,
  type: ApexAccountType
) {
  const [relationship, setRelationship] = useState<ApexACHRelationship>();

  const fetchRelationship = fetcher(
    getApexACHRelationshipByUserId,
    [userId],
    setRelationship,
    type
  );

  useEffect(() => {
    fetchRelationship();
  }, [userId, type]);

  return relationship;
}
