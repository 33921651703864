import { Chip } from "@mui/material";

interface IProps {
  status: string;
}

export default function StatusBadge({status}: IProps) {
  let color = "info";
  switch (status.toLowerCase()) {
    case "complete":
    case "completed":
    case "approved":
    case "reviewed":
    case "confirmed":
    case "enabled":
    case "active":
      color = "success";
      break;
    case "canceled":
    case "returned":
    case "rejected":
    case "closed":
    case "needs review":
    case "no_show":
    case "ended":
      color = "error";
      break;
    case "failed verification":
    case "needs attention":
    case "inactive":
      color = "warning";
      break;
  }

  return <Chip color={color as any} label={status}/>;
}
