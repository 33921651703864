import { getUsers } from "api/users";
import { debounce } from "lodash";
import { User } from "models/User";
import { useCallback, useEffect, useState } from "react";

const useUserSearch = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [query, setQuery] = useState<string>();

  const updateQuery = debounce((val) => setQuery(val), 700);
  const fetchUsers = useCallback((query: string) => {
    getUsers(query).subscribe(({ data }) => setUsers(data.users));
  }, []);

  const onSearchChange = (val: string) => {
    updateQuery(val);
  };

  useEffect(() => {
    if (query) {
      fetchUsers(query);
    } else {
      setUsers([]);
    }
  }, [query, fetchUsers]);

  return {
    onSearchChange,
    users,
  };
};

export default useUserSearch;
