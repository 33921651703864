import { useState } from "react";
import { getChatClient } from "utils/chat";

export default function useChat(advisorId?: string) {
  const [client, setClient] = useState(null);

  getChatClient(advisorId).then((client) => setClient(client));

  return { client };
}
