import { get, post } from "api/base";
import { AdvisorArchSecurityModel } from "models/advisor_arch/AdvisorArchSecurityModel";

export const getAdvisorArchSecurityModels = () => {
  return get(`/admin/advisor_arch/security_models`);
};

export const createAdvisorArchSecurityModel = (
  model: AdvisorArchSecurityModel
) => {
  return post(`/admin/advisor_arch/security_models`, true, model);
};
