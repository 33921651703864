import { Button, Card, TableContainer, Typography } from "@mui/material";
import { Box } from "@mui/system";
import HorizontalTable from "components/Table/HorizontalTable";
import Spacer from "components/Common/Spacer";
import { FailedVerificationItem } from "models/zuko/FailedVerificationItem";
import {
  confirmFailedVerificationItem,
  failFailedVerificationItem,
  resetFailedVerificationItem,
} from "api/zuko/failedVerificationItems";
import FailedVerificationButton from "components/Buttons/FailedVerificationButton/FailedVerificationButton";
import StatusBadge from "components/Badges/StatusBadge";
import ModelTable from "components/Table/ModelTable/ModelTable";

interface IProps {
  model: any;
  item: FailedVerificationItem;
  onUpdate: () => void;
  useTable: (model: any) => { tableRows: any[]; heading: string };
}

const FailedItemTable = ({ model, item, onUpdate, useTable }: IProps) => {
  return (
    <Box>
      <ModelTable
        model={model}
        useTable={useTable}
        leftHeaderItems={[
          <Spacer width={12} />,
          (!!item.manualConfirmedAt || !!item.manualFailedAt) && (
            <StatusBadge
              status={
                item.manualConfirmedAt
                  ? "Confirmed"
                  : item.manualFailedAt
                  ? "Failed Verification"
                  : "Needs Review"
              }
            />
          ),
        ]}
        rightHeaderItems={[
          !item.manualConfirmedAt && !item.manualFailedAt && (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  confirmFailedVerificationItem(item.id).subscribe(onUpdate)
                }
              >
                Confirm
              </Button>
              <Spacer width={12} />
              <FailedVerificationButton
                item={item}
                onUpdate={onUpdate}
                failItem={failFailedVerificationItem}
              />
            </>
          ),
          !!item.manualFailedAt && (
            <Button
              variant="contained"
              color="inherit"
              onClick={() =>
                resetFailedVerificationItem(item.id).subscribe(onUpdate)
              }
            >
              Reset
            </Button>
          ),
        ]}
      />
    </Box>
  );
};

export default FailedItemTable;
