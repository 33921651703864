import { getInviteCodePrograms } from "api/inviteCodes";
import { InviteCodeProgram } from "models/InviteCode";
import { useEffect, useState } from "react";

const useInviteCodePrograms = () => {
  const [inviteCodePrograms, setInviteCodePrograms] = useState<InviteCodeProgram[]>(
    []
  );

  useEffect(() => {
    getInviteCodePrograms().subscribe(({ data }) =>
      setInviteCodePrograms(data)
    );
  }, []);

  return { inviteCodePrograms };
};

export default useInviteCodePrograms;
