import { Route, Routes } from "react-router-dom";
import FailedItemsListPage from "./FailedItemsListPage/FailedItemsListPage";
import FailedItemDetailPage from "./FailedItemDetailPage/FailedItemDetailPage";

export default function FailedItemsRoutes() {
  return (
    <Routes>
      <Route key="items-list" path="/" element={<FailedItemsListPage />} />
      <Route key="item-detail" path="/:id" element={<FailedItemDetailPage />} />
    </Routes>
  );
}
