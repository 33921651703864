import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import { setInviteCode } from "api/users";
import useForm from "components/Form/Form.hooks";
import useInviteCodes from "components/Hooks/InviteCodes.hooks";

interface IProps {
  userId: string;
  setOpen: (open: boolean) => void;
}

const EditInviteCodeDialog = ({ userId, setOpen }: IProps) => {
  const { formValues, handleFormChange } = useForm({ inviteCodeUsed: "" });
  const { inviteCodes } = useInviteCodes();

  const onSave = () => {
    setInviteCode(userId, formValues.inviteCodeUsed);
  };

  return (
    <Box minWidth={400}>
      <DialogTitle>Edit Join Invite Code</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="invite_code">Join Invite Code</InputLabel>
          <Select
            labelId="invite_code"
            id="invite_code_select"
            value={formValues.inviteCodeUsed || ""}
            name="inviteCodeUsed"
            label="Invite Code"
            onChange={handleFormChange}
          >
            {inviteCodes.map((code) => (
              <MenuItem value={code.id}>{code.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            onSave();
            setOpen(false);
          }}
          size="large"
        >
          Save
        </Button>
      </DialogActions>
    </Box>
  );
};

export default EditInviteCodeDialog;
