import {
  NotificationAppVersionTargeting,
  NotificationInviteCodeTargeting,
} from "models/Notifications";
import { get, post } from "./base";

export enum NotificationTargetingType {
  UserIds = "userIds",
  InviteCode = "inviteCode",
  AppVersion = "appVersion",
}

export interface NotificationTargeting {
  type: NotificationTargetingType;
  userIds?: Array<string>;
  inviteCode?: NotificationInviteCodeTargeting;
  appVersion?: NotificationAppVersionTargeting;
}

export enum NotificationAction {
  OpenLink = "openLink",
}

export interface Notification {
  notificationId?: string;
  targeting: NotificationTargeting;

  action: NotificationAction;
  body: string;
  createdAt?: string;
  error?: string;
  id?: string;
  link?: string;
  title: string;
  token?: string;
  type?: string;
  userId?: string;
}

export interface NotificationInfo {
  id: string;
  title: string;
  body: string;
  type: string;
  link?: string;
  count: number;
  sent: number;
  opened: number;
  userIds?: string[];
}

export const createNotification = (notification: Notification) => {
  return post(`/notify/manual`, true, notification);
};

export const getNotificationsInfo = () => {
  return get(`/notifications`);
};

export const getNotificationInfo = (id: string) => {
  return get(`/notifications/${id}`);
};

export const getUserNotifications = (id: string) => {
  return get(`/admin/users/${id}/notifications`);
};

export const getUserNotificationSettings = (id: string) => {
  return get(`/admin/users/${id}/notifications/settings`);
};
