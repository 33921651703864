import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Spacer from "components/Common/Spacer";
import useForm from "components/Form/Form.hooks";
import { PlayerAwardType } from "models/zuko/PlayerAward";

interface IProps {
  playerAwardType: PlayerAwardType;
  open: boolean;
  setOpen: (open: boolean) => void;
  update: (name: string) => void;
}
const EditPlayerAwardTypeDialog = (props: IProps) => {
  const { playerAwardType, setOpen, update } = props;
  const { formValues, handleFormChange } = useForm(playerAwardType);

  return (
    <Box width="400px">
      <DialogTitle>Edit Player Award Type</DialogTitle>
      <DialogContent>
        <Box fontSize={16}>
          <Typography fontWeight={700}>Abbreviation</Typography>
          <Typography>{formValues.abbreviation}</Typography>
        </Box>
        <Spacer height={20} />
        <Box fontSize={16}>
          <Typography fontWeight={700}>Sample URL</Typography>
          <Typography>
            <Link href={formValues.link} target="_blank">
              {formValues.link}
            </Link>
          </Typography>
        </Box>
        <Spacer height={20} />
        <TextField
          variant="standard"
          fullWidth
          label="Full Name"
          name="fullName"
          multiline
          value={formValues.fullName}
          onChange={handleFormChange}
        />
        <Spacer height={20} />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => {
            update(formValues.fullName);
            setOpen(false);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Box>
  );
};

export default EditPlayerAwardTypeDialog;
