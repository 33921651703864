import { Paper, TableContainer, Typography } from "@mui/material";
import { ApexAccountType } from "models/ApexAccount";
import useAccountActivity, {
  AccountActivityDialogType,
} from "./AccountActivity.hooks";
import Loader from "components/Common/Loader";
import CustomTable from "components/Table/CustomTable";
import moment from "moment";
import StatusBadge from "components/Badges/StatusBadge";
import MenuButton from "components/Buttons/MenuButton";
import { GridAlignment } from "@mui/x-data-grid";
import { isCancelable } from "models/ApexTransfer";
import useSuperAdmin from "components/Contexts/SuperAdminContext/SuperAdmin.hooks";
import IdDialog from "components/Modals/IdDialog";
import DetailsDialog from "components/Modals/DetailsDialog";

interface IProps {
  userId: string;
  type: ApexAccountType;
}

export default function AccountActivity({ userId, type }: IProps) {
  const {
    transfers,
    cancelTransfer,
    selected,
    setSelected,
    dialog,
    setDialog,
    onDialogClose,
    getReturnDetails,
    retryLiquidation,
  } = useAccountActivity(userId, type);
  const { token } = useSuperAdmin();

  if (!transfers) {
    return <Loader />;
  }

  const rows = (transfers || []).map((transfer) => ({
    cells: [
      {
        renderCell: () => (
          <>
            <Typography variant="body2" fontWeight="bold">
              {moment(transfer.date).format("MM/DD/YYYY")}
            </Typography>
            <Typography variant="body2">{transfer.title}</Typography>
            <Typography variant="body2">${transfer.amount}</Typography>
          </>
        ),
      },
      {
        renderCell: () => <StatusBadge status={transfer.status} />,
      },
      {
        renderCell: () => {
          const items = [];

          if (token && isCancelable(transfer.status)) {
            items.push({
              name: "Cancel Transfer",
              onClick: () => {
                cancelTransfer(transfer);
              },
            });
          }

          if (transfer.status.toLowerCase() === "returned") {
            items.push({
              name: "See Return Details",
              onClick: () => {
                setSelected(transfer);
                setDialog(AccountActivityDialogType.SeeReturnDetails);
              },
            });
          }

          if (transfer.type === "liquidation") {
            items.push({
              name: "Retry Liquidation",
              onClick: () => {
                retryLiquidation(transfer);
              }
            })
          }

          items.push({
            name: "See Transfer ID",
            onClick: () => {
              setSelected(transfer);
              setDialog(AccountActivityDialogType.SeeTransferId);
            },
          });

          return <MenuButton options={items} />;
        },
        align: "right" as GridAlignment,
      },
    ],
  }));

  return (
    <>
      <TableContainer component={Paper}>
        <CustomTable headings={["Transfer", "Status", ""]} rows={rows} />
      </TableContainer>
      {selected && (
        <IdDialog
          heading="Transfer ID"
          open={dialog === AccountActivityDialogType.SeeTransferId}
          onClose={onDialogClose}
          id={selected.id}
        />
      )}
      {selected && (
        <DetailsDialog
          heading="Return Details"
          open={dialog === AccountActivityDialogType.SeeReturnDetails}
          onClose={onDialogClose}
          getDetails={() => getReturnDetails(selected)}
        />
      )}
    </>
  );
}
