import { ProcessorType } from "models/zuko/Link";
import { get, post, ZukoAPI } from "../base";

export const getPendingValidationItems = () => {
  return get("/admin/pending_validation/items", undefined, ZukoAPI);
};

export const getPendingValidationItem = (id: string) => {
  return get(`/admin/pending_validation/items/${id}`, undefined, ZukoAPI);
};

export const getChangeLog = (itemType: string, itemId: string) => {
  return get(`/admin/${itemType}/${itemId}/change_log`, undefined, ZukoAPI);
};

export const validatePendingValidationItem = (
  id: string,
  validated: boolean,
  reason?: string
) => {
  return post(
    `/admin/pending_validation/items/${id}/validate`,
    true,
    { validated, reason },
    ZukoAPI
  );
};

export const getQueuedLink = (link: string, processor: ProcessorType) => {
  return get(`/admin/queued_links/search`, { processor, link }, ZukoAPI);
};
