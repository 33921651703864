import DialogModal from "../../components/Modals/DialogModal";
import {
  Box, Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { noop } from "lodash";
import { CalendlyScheduledEventStatus } from "../../models/CalendlyScheduledEvent";
import Spacer from "components/Common/Spacer";
import useCalendlyEventsOutcomeDialog from "./CalendlyEventsOutcomeDialog.hooks";
import ScoutPlanForm from "../../components/Form/ScoutPlanForm";
import Loader from "components/Common/Loader";

interface IProps {
  status: CalendlyScheduledEventStatus;
  onSubmit: (result: CalendlyEventsOutcomeDialogResult) => void;
  userId: string;
}

export interface CalendlyEventsOutcomeDialogResult {
  investmentMonthlyDeposit: string;
  notes?: string;
  savingsMonthlyDeposit: string;
  spreadsheetUrl?: string;
  status: CalendlyScheduledEventStatus;
  statusReason?: string;
  roundUps: boolean;
}

const CalendlyEventsOutcomeDialog = ({onSubmit, status, userId}: IProps) => {
  const {
    formValues,
    handleCheckboxChange,
    handleFormChange,
    hasMicroDepositFundingAccount,
    isSpreadsheetUrlValid,
    isValid,
    loading,
    roundUps,
    setRoundUps
  } = useCalendlyEventsOutcomeDialog(status, userId);

  return <DialogModal onClose={noop}
                      renderDialog={(_, setOpen) => (
                        <Box minWidth={400}>
                          <Card>
                            <CardHeader>
                              <Typography variant="h5">Set Meeting Outcome</Typography>
                            </CardHeader>
                            <CardContent>
                              <FormControl fullWidth variant="standard">
                                <InputLabel>Outcome</InputLabel>
                                <Select
                                  onChange={handleFormChange}
                                  name="status"
                                  size="medium"
                                  value={formValues.status}>
                                  {Object.values(CalendlyScheduledEventStatus).map(e => <MenuItem
                                    value={e}>{e}</MenuItem>)}
                                </Select>
                              </FormControl>
                              <Spacer height={20}/>
                              {(formValues.status === CalendlyScheduledEventStatus.Canceled ||
                                formValues.status === CalendlyScheduledEventStatus.Rescheduled) && (
                                <>
                                  <TextField
                                    fullWidth
                                    label="Reason"
                                    onChange={handleFormChange}
                                    name="statusReason"
                                    value={formValues.statusReason}
                                    variant="standard"/>
                                  <Spacer height={20}/>
                                </>
                              )}
                              {formValues.status === CalendlyScheduledEventStatus.Completed && (
                                <>
                                  <FormControlLabel
                                    control={<Checkbox onChange={handleCheckboxChange} name="includeScoutPlan"/>}
                                    label="Customer Created Scout Plan"/>
                                  <Spacer height={20}/>
                                  {formValues.includeScoutPlan && (
                                    loading ? <Loader/> : (
                                      <>
                                        <Typography variant="subtitle2">Their Scout Plan</Typography>
                                        <Spacer height={20}/>
                                        <ScoutPlanForm
                                          disableRoundUps={hasMicroDepositFundingAccount!}
                                          formValues={formValues}
                                          handleFormChange={handleFormChange}
                                          roundUps={roundUps}
                                          setRoundUps={setRoundUps}
                                          showURLRequiredAlert={!isSpreadsheetUrlValid}/>
                                        <Spacer height={20}/>
                                      </>
                                    )
                                  )}
                                </>
                              )}
                              <Button disabled={!isValid} fullWidth onClick={() => {
                                onSubmit({...formValues, roundUps});
                                setOpen(false);
                              }} size="large" variant="contained">Submit</Button>
                            </CardContent>
                          </Card>
                        </Box>
                      )}
                      renderDisplay={(_, setOpen) => (
                        <Link onClick={() => setOpen(true)}>
                          Set Outcome
                        </Link>
                      )}
  />;
};

export default CalendlyEventsOutcomeDialog;
