import { Typography } from "@mui/material";
import { getUserNotificationSettings } from "api/notifications";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

interface IProps {
  userId: string;
}

function useNotifsStatus(userId: string) {
  const [status, setStatus] = useState(false);

  const fetchStatus = fetcher(
    getUserNotificationSettings,
    [userId],
    setStatus,
    "showNotifications"
  );

  useEffect(() => {
    fetchStatus();
  }, [userId]);

  return { status };
}

export default function NotifsStatus({ userId }: IProps) {
  const { status } = useNotifsStatus(userId);

  return (
    <Typography variant="body1">
      Show Notifications: {capitalize(status.toString())}
    </Typography>
  );
}
