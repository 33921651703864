import { Table, TableBody, TableCell, TableRow } from "@mui/material";

interface ITableRow {
  headerName: string;
  value?: string | number;
  renderCell?: () => JSX.Element;
  action?: () => JSX.Element;
  key?: string;
}

interface IProps {
  rows: ITableRow[];
}

export default function HorizontalTable({ rows }: IProps) {
  return (
    <Table sx={{ minWidth: "50vw" }}>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={row.key || index}>
            <TableCell key={`${row.key || row.headerName}-heading`}>
              {row.headerName}
            </TableCell>
            <TableCell key={`${row.key || row.headerName}-value`}>
              {row.renderCell ? row.renderCell() : row.value}
            </TableCell>
            {row.action && (
              <TableCell
                align="right"
                key={`${row.key || row.headerName}-action`}
              >
                {row.action()}
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
