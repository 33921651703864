import DialogModal from "components/Modals/DialogModal";
import React from "react";
import {
  Box,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import Spacer from "components/Common/Spacer";
import useForm from "components/Form/Form.hooks";
import { createPromoCode } from "api/promoCodes";

interface IProps {
  onClose: () => void;
}

const PromoCodeDialog = ({ onClose }: IProps) => {
  const { formValues, handleFormChange } = useForm({
    label: "",
    duration: 0,
  });
  const { label, duration } = formValues;
  const onSave = () => {
    delete formValues["activationDateStr"];
    formValues["duration"] = parseInt(duration);
    createPromoCode(formValues);
  };

  return (
    <DialogModal
      onClose={onClose}
      renderDisplay={(open, setOpen) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Create Code
        </Button>
      )}
      renderDialog={(open, setOpen) => (
        <>
          <Box minWidth={400}>
            <DialogTitle>Create Promo Code</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="label"
                label="Code Name"
                type="text"
                fullWidth
                variant="standard"
                value={label}
                name="label"
                onChange={handleFormChange}
              />
              <Spacer height={12} />
              <TextField
                autoFocus
                margin="dense"
                id="duration"
                label="Fee Waive Duration (months)"
                type="number"
                fullWidth
                variant="standard"
                value={duration}
                name="duration"
                onChange={handleFormChange}
              />
              <Spacer height={12} />
              {/* <TextField
                autoFocus
                margin="dense"
                id="activationDate"
                label="Activation Date (optional)"
                type="text"
                fullWidth
                variant="standard"
                value={activationDateStr}
                name="activationDate"
                onChange={(e) => {
                  setForm("activationDateStr", e.target.value);
                  setForm(e.target.name, new Date(e.target.value));
                }}
              /> */}
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  onSave();
                  setOpen(false);
                }}
                size="large"
              >
                Save
              </Button>
            </DialogActions>
          </Box>
        </>
      )}
    />
  );
};

export default PromoCodeDialog;
