import {
  getFailedVerificationItemsByItem,
  resetFailedVerificationItemByItem,
} from "api/zuko/failedVerificationItems";
import {
  getPendingValidationItem,
  getChangeLog,
  validatePendingValidationItem,
} from "api/zuko/pendingValidationItems";
import { FailedVerificationItem } from "models/zuko/FailedVerificationItem";
import { ChangeLogItem, ItemType } from "models/zuko/Item";
import {
  PendingValidationItem,
  getPendingValidationItemName,
} from "models/zuko/PendingValidationItem";
import { useEffect, useState } from "react";
import { Observable } from "rxjs";
import { fetcher } from "utils/data";
import moment from "moment";

const usePendingValidationItemDetailPage = (id: string) => {
  const [item, setItem] = useState<PendingValidationItem>();
  const [changeLog, setChangeLog] = useState<ChangeLogItem[]>();
  const [changeLogLoading, setChangeLogLoading] = useState<boolean>(true);
  const [recentFailed, setRecentFailed] = useState<FailedVerificationItem>();

  const fetchItem = fetcher(getPendingValidationItem, [id], setItem);
  const fetchChangeLog = fetcher(
    getChangeLog,
    [item?.itemType, item?.itemId],
    setChangeLog,
    undefined,
    () => {
      setChangeLogLoading(false);
    }
  );

  useEffect(() => {
    fetchItem();
  }, [id]);

  useEffect(() => {
    if (item) {
      setChangeLogLoading(true);
      fetchChangeLog();
      if (!item.failedAt || !item.failureReason) {
        getFailedVerificationItemsByItem(item.itemType, item.itemId).subscribe(
          ({ data }) => {
            const mostRecent = getMostRecentFailedItem(data);
            setRecentFailed(mostRecent);
          }
        );
      }
    }
  }, [item]);

  const getHeading = () => {
    if (!item) {
      return "";
    }

    return getPendingValidationItemName(item);
  };

  const onValidate = (validate: boolean, reason?: string) => {
    if (!item) {
      return new Observable();
    }

    if (validate) {
      return validatePendingValidationItem(item.id, validate);
    }

    return validatePendingValidationItem(item.id, validate, reason);
  };

  const onReset = () => {
    if (!item) {
      return new Observable();
    }

    return resetFailedVerificationItemByItem(item.itemType, item.itemId);
  };

  const getDataSourceIds = () => {
    if (!changeLog) {
      return [];
    }

    const ids = changeLog.filter((log) => log.type === ItemType.DataSourceId);
    if (!ids || ids.length === 0) {
      return [];
    }

    return ids[0].items;
  };

  const getMostRecentFailedItem = (failedItems: FailedVerificationItem[]) => {
    if (!failedItems || failedItems.length === 0) {
      return undefined;
    }

    return failedItems.sort((a, b) =>
      moment(a.updatedAt).isBefore(moment(b.updatedAt)) ? -1 : 1
    )[0];
  };

  return {
    item,
    changeLog,
    heading: getHeading(),
    onValidate,
    onReset,
    refetch: fetchItem,
    changeLogLoading,
    dataSourceIds: getDataSourceIds(),
    recentFailed,
  };
};

export default usePendingValidationItemDetailPage;
