import { useEffect, useState } from "react";
import {
  getScheduledEvents,
  Sort,
  updateScheduledEventStatus,
} from "../../api/calendly";
import {
  CalendlyScheduledEvent,
  CalendlyScheduledEvents,
  CalendlyScheduledEventStatus,
} from "../../models/CalendlyScheduledEvent";
import { fetcher } from "../../utils/data";
import {
  createScoutPlan,
  getScoutPlan,
  updateScoutPlan,
} from "../../api/users";
import { CalendlyEventsOutcomeDialogResult } from "./CalendlyEventsOutcomeDialog";
import { ScoutPlan } from "../../models/ScoutPlan";

const useCalendlyEventsGrid = (sort: Sort, before?: Date, since?: Date, query?: string) => {
  const [events, setEvents] = useState<CalendlyScheduledEvents>();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchScheduledEvents = (limit: number, offset: number) => {
    const fetch = fetcher(
      getScheduledEvents,
      [{ limit, offset, before, since, sort, userName: query }],
      setEvents,
      undefined,
      () => {
        setLoading(false);
      }
    );
    return fetch();
  };

  useEffect(() => {
    const offset = page * pageSize;
    setLoading(true);
    fetchScheduledEvents(pageSize, offset);
  }, [page, pageSize, query]);

  const onSubmit = async (
    event: CalendlyScheduledEvent,
    {
      investmentMonthlyDeposit,
      notes,
      savingsMonthlyDeposit,
      spreadsheetUrl,
      status,
      statusReason,
      roundUps,
    }: CalendlyEventsOutcomeDialogResult
  ) => {
    const reason =
      status === CalendlyScheduledEventStatus.Canceled ||
      status === CalendlyScheduledEventStatus.Rescheduled
        ? statusReason
        : undefined;
    if (reason !== event.statusReason || status !== event.status) {
      await updateScheduledEventStatus(event.id, status, reason);
      if (events) {
        const eventList = [...events.events];
        const eventIndex = eventList.indexOf(event);
        if (eventIndex !== -1) {
          eventList[eventIndex] = { ...event, status, statusReason: reason };
          setEvents({ ...events, events: eventList });
        }
      }
    }

    const newPlan: ScoutPlan = {
      investmentMonthlyDeposit: parseFloat(investmentMonthlyDeposit),
      notes: notes?.trim(),
      roundUps: roundUps,
      savingsMonthlyDeposit: parseFloat(savingsMonthlyDeposit),
      spreadsheetUrl: spreadsheetUrl?.trim(),
      userId: event.userId,
    };
    getScoutPlan(event.userId).subscribe({
      next: (_) => {
        updateScoutPlan(newPlan, event.userId).subscribe();
      },
      error: (error) => {
        if (error.response.status === 404) {
          createScoutPlan(newPlan, event.userId).subscribe();
        }
      },
    });
  };

  return {
    events: events?.events,
    onSubmit,
    pageSize,
    setPage,
    setPageSize,
    totalEventCount: events?.totalCount,
    loading,
  };
};

export default useCalendlyEventsGrid;
