import Page from "components/Common/Page";
import React from "react";
import { Button, Paper } from "@mui/material";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import useSecurityModelsPage from "./SecurityModelsPage.hooks";
import SecurityModelDialog from "./SecurityModelDialog";
import CreateSecurityModelDialog from "./CreateSecurityModelDialog";
import useAdmin from "components/Hooks/Admin.hooks";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 200 },
  { field: "name", headerName: "Security Model Name", width: 230 },
  {
    field: "risk",
    headerName: "Risk",
    width: 100,
  },
];

const SecurityModelsPage = () => {
  useAdmin();
  const { models, setSelected, selected, refetch, creating, setCreating } =
    useSecurityModelsPage();

  return (
    <Page
      heading="Security Models"
      // RightHeadingComponent={
      //   <Button variant="contained" onClick={() => setCreating(true)}>
      //     Create Security Model
      //   </Button>
      // }
    >
      <Paper>
        <DataGrid
          rows={models || []}
          columns={columns}
          pageSize={10}
          autoHeight
          rowsPerPageOptions={[10]}
          onRowClick={(params: GridRowParams) => setSelected(params.row)}
        />
      </Paper>
      {!creating && selected && (
        <SecurityModelDialog
          open={!!selected}
          onClose={() => setSelected(undefined)}
          model={selected}
          refetch={refetch}
        />
      )}
      {creating && (
        <CreateSecurityModelDialog
          open={creating}
          onClose={() => {
            setCreating(false);
            refetch();
          }}
        />
      )}
    </Page>
  );
};

export default SecurityModelsPage;
