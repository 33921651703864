import { Box } from "@mui/material";
import { NotificationInfo } from "api/notifications";

interface IProps {
  notificationInfo: NotificationInfo;
  onClick: (notificationInfo: NotificationInfo) => void;
}

const NotificationRow = ({ notificationInfo, onClick }: IProps) => {
  const sentPercentage = (notificationInfo.sent / notificationInfo.count) * 100;
  const openedPercentage =
    notificationInfo.sent > 0
      ? (notificationInfo.opened / notificationInfo.sent) * 100
      : 0;

  return (
    <Box
      paddingX="23px"
      paddingTop="13px"
      paddingBottom="11px"
      display="flex"
      flexDirection="column"
      alignItems="start"
      justifyContent="center"
      sx={{
        "&:hover": {
          backgroundColor: "#E3F2FD",
          cursor: "pointer",
        },
      }}
      onClick={() => onClick(notificationInfo)}
    >
      <Box fontWeight="500" marginBottom="4px">
        {notificationInfo.title}
      </Box>
      <Box color="#757575">
        {notificationInfo.id} • {sentPercentage}% sent • {openedPercentage}%
        opened
      </Box>
    </Box>
  );
};

export default NotificationRow;
