import React from "react";
import { TableContainer, Paper } from "@mui/material";
import TrustedContactDialog from "./TrustedContactDialog";
import AddressDialog from "./AddressDialog";
import BirthdateDialog from "./BirthdateDialog";
import LegalNameDialog from "./LegalNameDialog";
import EmailDialog from "./EmailDialog";
import JoinInviteCodeDialog from "./JoinInviteCodeDialog";
import useUserBasicInfo from "./UserBasicInfo.hooks";
import HorizontalTable from "components/Table/HorizontalTable";
import Loader from "components/Common/Loader";

interface IProps {
  userId: string;
}

const UserBasicInfo = ({ userId }: IProps) => {
  const { user, contact, refetch } = useUserBasicInfo(userId);

  if (!user) {
    return <Loader />;
  }

  const rows = [
    {
      headerName: "Name",
      key: "name",
      renderCell: () => (
        <LegalNameDialog
          legalGivenName={user.legalGivenName}
          legalMiddleName={user.legalMiddleName}
          legalFamilyName={user.legalFamilyName}
          legalNameSuffix={user.legalNameSuffix}
          userId={user.id}
          onClose={refetch}
        />
      ),
    },
    {
      headerName: "Email",
      key: "email",
      renderCell: () => (
        <EmailDialog email={user.email} userId={user.id} onClose={refetch} />
      ),
    },
    { headerName: "Phone Number", value: user.phoneNumber },
    {
      headerName: "Join Invite Code",
      key: "joinInviteCode",
      renderCell: () => (
        <JoinInviteCodeDialog
          inviteCodeUsed={user.inviteCodeUsed}
          userId={user.id}
          inviteCodeUsedOwnerID={user.inviteCodeUsedOwnerID}
          onClose={refetch}
        />
      ),
    },
    {
      headerName: "Birthday",
      key: "birthday",
      renderCell: () => (
        <BirthdateDialog
          birthDate={user.birthDate}
          userId={user.id}
          onClose={refetch}
        />
      ),
    },
    {
      headerName: "Social Security Number",
      key: "ssnLastFour",
      value: `***-**-${user.ssnLastFour}`,
    },
    {
      headerName: "Address",
      key: "address",
      renderCell: () => (
        <AddressDialog
          userId={user.id}
          address={user.address}
          onClose={refetch}
        />
      ),
    },
    {
      headerName: "Trusted Contact",
      key: "trustedContact",
      renderCell: () => (
        <TrustedContactDialog
          contact={contact}
          userId={user.id}
          onClose={refetch}
        />
      ),
    },
  ];

  return (
    <TableContainer component={Paper}>
      <HorizontalTable rows={rows} />
    </TableContainer>
  );
};

export default UserBasicInfo;
