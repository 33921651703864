import React from "react";
import { Box, Typography } from "@mui/material";
import DialogModal from "components/Modals/DialogModal";
import EditInviteCodeDialog from "./EditInviteCodeDialog";
import JoinInviteCodeDetailsDialog from "./JoinInviteCodeDetailsDialog";

interface IProps {
  inviteCodeUsed: string;
  userId: string;
  inviteCodeUsedOwnerID?: string;
  onClose: () => void;
}

const JoinInviteCodeDialog = ({
  userId,
  inviteCodeUsed,
  inviteCodeUsedOwnerID,
  onClose,
}: IProps) => {
  return (
    <DialogModal
      onClose={onClose}
      renderDisplay={(open, setOpen) => (
        <Box
          onClick={() => setOpen(true)}
          width="100%"
          height="100%"
          alignItems="center"
          display="flex"
        >
          <Typography>{inviteCodeUsed}</Typography>
        </Box>
      )}
      renderDialog={(open, setOpen) =>
        !inviteCodeUsed ? (
          <EditInviteCodeDialog userId={userId} setOpen={setOpen} />
        ) : (
          <JoinInviteCodeDetailsDialog
            inviteCodeUsed={inviteCodeUsed}
            inviteCodeUsedOwnerID={inviteCodeUsedOwnerID}
            setOpen={setOpen}
          />
        )
      }
    />
  );
};

export default JoinInviteCodeDialog;
