import { NotificationInfo, getNotificationInfo } from "api/notifications";
import { useState, useEffect, useCallback } from "react";

export const useNotificationDetailPage = (id: string) => {
  const [notificationInfo, setNotificationInfo] = useState<NotificationInfo>();

  const fetchNotificationInfo = useCallback(() => {
    getNotificationInfo(id).subscribe(({ data }) => {
      setNotificationInfo(data);
    });
  }, [id]);

  useEffect(() => {
    fetchNotificationInfo();
  }, [fetchNotificationInfo]);

  return { notificationInfo };
};
