import React from "react";
import { Typography, TableContainer, Paper } from "@mui/material";
import { GridAlignment } from "@mui/x-data-grid";
import MenuButton from "components/Buttons/MenuButton";
import useUserConnectedAccounts from "./UserConnectedAccounts.hooks";
import CustomTable from "components/Table/CustomTable";
import Loader from "components/Common/Loader";
import AddFundingSourceDialog from "components/Modals/AddFundingSourceDialog/AddFundingSourceDialog";

interface IProps {
  userId: string;
}

const UserConnectedAccounts = ({ userId }: IProps) => {
  const { accounts, cancelRelationship, setOpen, open } =
    useUserConnectedAccounts(userId);

  if (!accounts) {
    return <Loader />;
  }

  const rows = (accounts || []).map((account) => ({
    cells: [
      {
        renderCell: () => {
          return (
            <>
              <Typography variant="body1" fontWeight="bold">
                {account.institutionName}
              </Typography>
              <Typography variant="body1">{account.name}</Typography>
              <Typography variant="body1">
                ${account.availableBalance} Available
              </Typography>
            </>
          );
        },
      },
      { value: "" },
      {
        renderCell: () => {
          const items =
            account.relationshipId &&
            account.relationshipStatus?.toLowerCase() !== "canceled" &&
            account.relationshipStatus?.toLowerCase() !== "rejected"
              ? [
                  {
                    name: "Cancel ACH Relationship",
                    onClick: () => {
                      cancelRelationship(account);
                    },
                  },
                ]
              : [
                  {
                    name: "Make Funding Source",
                    onClick: () => {
                      setOpen(account);
                    },
                  },
                ];

          return <MenuButton options={items} />;
        },
        align: "right" as GridAlignment,
      },
    ],
  }));

  return (
    <>
      <TableContainer component={Paper}>
        <CustomTable headings={["Account", "Status", ""]} rows={rows} />
      </TableContainer>
      {open && (
        <AddFundingSourceDialog
          open={!!open}
          onClose={() => setOpen(undefined)}
          account={open}
          userId={userId}
        />
      )}
    </>
  );
};

export default UserConnectedAccounts;
