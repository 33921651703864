import { useEffect, useState } from "react";
import { ScoutPlan } from "../../models/ScoutPlan";
import { fetcher } from "../../utils/data";
import { getScoutPlan } from "../../api/users";

const useScoutPlanDetailsDialog = (userId: string) => {
  const [scoutPlan, setScoutPlan] = useState<ScoutPlan>();

  const fetchScoutPlan = fetcher(getScoutPlan, [userId], setScoutPlan);

  useEffect(() => {
    fetchScoutPlan();
  }, [userId]);

  return {scoutPlan};
};

export default useScoutPlanDetailsDialog;
