import {
  cancelACHRelationship,
  getACHRelationshipDetails,
  reissueMicroDeposit,
} from "api/achRelationships";
import useACHRelationship from "components/Hooks/ACHRelationship.hooks";
import useConnectedAccounts from "components/Hooks/ConnectedAccounts.hooks";
import { find } from "lodash";
import { ApexAccountType } from "models/ApexAccount";
import { BankAccount } from "models/BankAccount";
import { useState } from "react";
import { map } from "rxjs/operators";

export enum FundingAccountDialogType {
  AddFundingSource = "Add Funding Source",
  RemoveFundingSource = "Remove Funding Source",
  SeeErrorDetails = "See Error Details",
  MakeFundingSource = "Make Funding Source",
  SeeRelationshipId = "See Relationship ID",
}

export default function useFundingAccount(
  userId: string,
  type: ApexAccountType
) {
  const accounts = useConnectedAccounts(userId);
  const relationship = useACHRelationship(userId, type);
  const [dialog, setDialog] = useState<FundingAccountDialogType>();

  const correspondingConnectedAccount = find(
    accounts,
    (account: BankAccount) => {
      return !!relationship && account.accountId === relationship.bankAccountId;
    }
  );

  const getRelationshipDetails = () => {
    return getACHRelationshipDetails(userId).pipe(
      map(({ data }) => {
        if (relationship) {
          const found = find(data, (o) => o.id === relationship.id);
          return JSON.stringify(found, null, 2);
        }

        return "";
      })
    );
  };

  const removeFundingSource = () => {
    if (relationship) {
      cancelACHRelationship(userId, relationship.id);
    }
  };

  const reinitiateMicrodeposits = () => {
    if (relationship) {
      reissueMicroDeposit(userId, relationship.id);
    }
  };

  return {
    relationship,
    account: correspondingConnectedAccount,
    onDialogClose: () => setDialog(undefined),
    dialog,
    setDialog,
    getRelationshipDetails,
    removeFundingSource,
    reinitiateMicrodeposits,
  };
}
