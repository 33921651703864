import { getUserNotificationSettings } from "api/notifications";
import { DeviceNotificationStatus } from "models/NotificationSettings";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

export default function useDeviceSettings(userId: string) {
  const [settings, setSettings] = useState<DeviceNotificationStatus[]>();

  const fetchSettings = fetcher(
    getUserNotificationSettings,
    [userId],
    setSettings,
    "deviceNotificationStatuses"
  );

  useEffect(() => {
    fetchSettings();
  }, [userId]);

  return { settings };
}
