import { Box } from "@mui/system";
import UserBasicInfo from "./UserBasicInfo/UserBasicInfo";
import Spacer from "components/Common/Spacer";
import UserConnectedAccounts from "./UserConnectedAccounts/UserConnectedAccounts";
import AppVersionDownloaded from "./AppVersionDownloaded";
import AccountBalances from "components/Details/AccountBalances/AccountBalances";
import UserDetailSection from "../UserDetailSection";
import UserScoutPlan from "./UserScoutPlan/UserScoutPlan";
import NotifsStatus from "./NotifsStatus";
import DeviceSettings from "./DeviceSettings/DeviceSettings";
import Notifications from "./Notifications/Notifications";
import DeleteUser from "./DeleteUser";

interface IProps {
  userId: string;
}

export default function OverviewTabView({ userId }: IProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
      width="100%"
    >
      <UserDetailSection heading="Basic Info">
        <UserBasicInfo userId={userId} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Total Balances">
        <AccountBalances userId={userId} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Scout Plan">
        <UserScoutPlan userId={userId} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Connected Accounts">
        <UserConnectedAccounts userId={userId} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="App Version Downloaded">
        <AppVersionDownloaded userId={userId} />
      </UserDetailSection>
      <Spacer height={32} />
      <NotifsStatus userId={userId} />
      <Spacer height={32} />
      <UserDetailSection heading="Device Settings">
        <DeviceSettings userId={userId} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Notifications">
        <Notifications userId={userId} />
      </UserDetailSection>
      <Spacer height={32} />
      <DeleteUser userId={userId} />
    </Box>
  );
}
