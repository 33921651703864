import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { Lock, LockOpen } from "@mui/icons-material";
import { useSuperAdminContext } from "components/Contexts/SuperAdminContext/SuperAdminContext";
import DialogModal from "components/Modals/DialogModal";
import Spacer from "components/Common/Spacer";
import useForm from "components/Form/Form.hooks";
import { useKataraUserContext } from "components/Contexts/KataraUserContext/KataraUserContext";
import { requestAuthCode, superAuth } from "api/user";
import { useState } from "react";

const SuperAdminLock = () => {
  const token = useSuperAdminContext();
  const user = useKataraUserContext();
  const { formValues, handleFormChange } = useForm({});
  const [unixSeconds] = useState<number>(Math.round(Date.now()));

  if (!user) {
    return <></>;
  }

  return (
    <DialogModal
      renderDisplay={(_, setOpen) => {
        return (
          <IconButton
            color="primary"
            disabled={!!token}
            onClick={() => {
              requestAuthCode(user.phoneNumber, true, unixSeconds);
              setOpen(true);
            }}
          >
            {token ? <LockOpen /> : <Lock />}
          </IconButton>
        );
      }}
      renderDialog={(_, setOpen) => {
        return (
          <>
            <Box minWidth={400}>
              <DialogTitle>Super Admin Auth Code</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="code"
                  label="Code"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={formValues.code}
                  name="code"
                  onChange={handleFormChange}
                />
                <Spacer height={12} />
              </DialogContent>
              <DialogActions>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    superAuth(formValues.code, user.phoneNumber, unixSeconds);
                    setOpen(false);
                  }}
                  size="large"
                >
                  Save
                </Button>
              </DialogActions>
            </Box>
          </>
        );
      }}
      onClose={() => {}}
    />
  );
};

export default SuperAdminLock;
