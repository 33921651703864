import { EducationSessionQuestion } from 'models/EducationSession';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import useEducationQuestionSelect from './EducationQuestionSelect.hooks';
import Spacer from '../../Common/Spacer';
import React from 'react';

interface IProps {
  onChange: (question: EducationSessionQuestion) => void;
}

const EducationQuestionSelect = ({onChange}: IProps) => {
  const {active, setActive, questions, setSearch} = useEducationQuestionSelect(onChange);

  return <FormControl fullWidth variant="standard">
    <Autocomplete
      renderInput={(params) => <TextField {...params}/>}
      options={questions}
      getOptionLabel={(q) => q.title}
      renderOption={(props, q) => (
        <MenuItem value={q.id} {...props}>
          {q.title}
        </MenuItem>
      )}
      onChange={(_, value) => {
        if (value) {
          onChange(value)
        }
      }}
      onInputChange={(_, inputValue) => {
        setSearch(inputValue)
      }}
    />
  </FormControl>
}

export default EducationQuestionSelect