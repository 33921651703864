import DialogModal from "components/Modals/DialogModal";
import React from "react";
import {
  Box,
  Typography,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { Address } from "models/User";
import Spacer from "components/Common/Spacer";
import useForm from "components/Form/Form.hooks";
import { updateUserAddress } from "api/users";

interface IProps {
  userId: string;
  address: Address;
  onClose: () => void;
}

const AddressDialog = ({ userId, address, onClose }: IProps) => {
  const { formValues, handleFormChange } = useForm(address);
  const { street1, street2, city, state, zipCode } = formValues;
  const onSave = () => {
    updateUserAddress(userId, {
      street1,
      street2,
      city,
      state,
      zipCode,
    });
  };

  return (
    <DialogModal
      onClose={onClose}
      renderDisplay={(open, setOpen) => (
        <Box onClick={() => setOpen(true)}>
          <Typography>{address.street1}</Typography>
          <Typography>
            {address.city}, {address.state}, {address.zipCode}
          </Typography>
        </Box>
      )}
      renderDialog={(open, setOpen) => (
        <>
          <Box minWidth={400}>
            <DialogTitle>Edit Address</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="street1"
                label="Street Address, Line 1"
                type="text"
                fullWidth
                variant="standard"
                value={street1}
                name="street1"
                onChange={handleFormChange}
              />
              <Spacer height={20} />
              <TextField
                margin="dense"
                id="street2"
                label="Street Address, Line 2"
                type="text"
                fullWidth
                variant="standard"
                value={street2}
                name="street2"
                onChange={handleFormChange}
              />
              <Spacer height={20} />
              <TextField
                margin="dense"
                id="city"
                label="City"
                type="text"
                fullWidth
                variant="standard"
                value={city}
                name="city"
                onChange={handleFormChange}
              />
              <Spacer height={20} />
              <TextField
                margin="dense"
                id="state"
                label="State (Abbrev)"
                type="state"
                fullWidth
                variant="standard"
                value={state}
                name="state"
                onChange={handleFormChange}
              />
              <Spacer height={20} />
              <TextField
                margin="dense"
                id="zipCode"
                label="Zip Code"
                type="text"
                fullWidth
                variant="standard"
                value={zipCode}
                name="zipCode"
                onChange={handleFormChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  onSave();
                  setOpen(false);
                }}
                size="large"
              >
                Save
              </Button>
            </DialogActions>
          </Box>
        </>
      )}
    />
  );
};

export default AddressDialog;
