import { Button } from "@mui/material";
import Page from "components/Common/Page";

const CalendlyLoginPage = () => {
  const clientId = process.env.REACT_APP_CALENDLY_CLIENT_ID;
  const redirectUrl = `${process.env.REACT_APP_URL}/calendly/auth`;

  return (
    <Page heading="Calendly Login">
      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          window.location.href =
            `https://calendly.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code`;
        }}
        size="large"
      >
        Log into calendly
      </Button>
    </Page>
  );
};

export default CalendlyLoginPage;
