import { Route, Routes } from "react-router-dom";
import ChatListPage from "./ChatListPage/ChatListPage";
import ChatChannelPage from "./ChatChannelPage/ChatChannelPage";

export default function ChatRoutes() {
  return (
    <Routes>
      <Route key="chat" path="/" element={<ChatListPage />} />
      <Route key="channel" path="/:id" element={<ChatChannelPage />} />
    </Routes>
  );
}
