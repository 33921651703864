import { fetcher } from "utils/data";
import {
  PendingValidationItem,
  getPendingValidationItemName,
} from "models/zuko/PendingValidationItem";
import { useState, useEffect } from "react";
import { getPendingValidationItems } from "api/zuko/pendingValidationItems";
import { ItemType } from "models/zuko/Item";
import { capitalize } from "lodash";
import StatusBadge from "components/Badges/StatusBadge";
import { Link } from "@mui/material";

const usePendingValidationItemListPage = () => {
  const [items, setItems] = useState<PendingValidationItem[]>([]);

  const fetchItems = fetcher(getPendingValidationItems, [], setItems);

  useEffect(() => {
    fetchItems();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "itemType",
      headerName: "Item Type",
      width: 300,
    },
    {
      field: "validatedAt",
      headerName: "Validated?",
      renderCell: (params: any) => {
        return <StatusBadge status={params.value} />
      },
      valueGetter: (params: any) => {
        let status = params.row.validatedAt ? "Reviewed" : "Needs Review";
        if (params.row.failedAt) {
          status = "Failed Verification"
        }
        return status;
      },
      width: 200,
    },
    {
      field: "link",
      headerName: "Link",
      width: 150,
      renderCell: (params: any) => (
        <Link
          href={`/pending_validation_items/${params.row.id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          target="_blank"
        >
          Link
        </Link>
      ),
    },
  ];

  const getRows = () => {
    return items.map((item) => {
      return {
        ...item,
        name: getPendingValidationItemName(item),
      };
    });
  };

  return {
    columns,
    rows: getRows(),
  };
};

export default usePendingValidationItemListPage;
