import React from "react";
import {
  Box,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import DialogModal from "components/Modals/DialogModal";
import useForm from "components/Form/Form.hooks";
import { updateUser } from "api/users";

interface IProps {
  email: string;
  userId: string;
  onClose: () => void;
}

const EmailDialog = ({ userId, email, onClose }: IProps) => {
  const { formValues, handleFormChange } = useForm({ email });

  const onSave = () => {
    updateUser(userId, { email: formValues.email });
  };

  return (
    <DialogModal
      onClose={onClose}
      renderDisplay={(open, setOpen) => (
        <Box
          onClick={() => setOpen(true)}
          width="100%"
          height="100%"
          alignItems="center"
          display="flex"
        >
          <Typography>{formValues.email}</Typography>
        </Box>
      )}
      renderDialog={(open, setOpen) => (
        <Box minWidth={400}>
          <DialogTitle>Edit Email</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              value={formValues.email}
              name="email"
              onChange={handleFormChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onSave();
                setOpen(false);
              }}
              size="large"
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      )}
    />
  );
};

export default EmailDialog;
