import { Box } from "@mui/system";

interface IProps {
  type: "column" | "row";
  horizontalAlign?: "flex-start" | "center" | "flex-end";
  verticalAlign?: "flex-start" | "center" | "flex-end";
  children: any;
}

export default function FlexBox({
  type,
  horizontalAlign,
  verticalAlign,
  children,
}: IProps) {
  const props: any = {};

  if (horizontalAlign) {
    const prop = type === "column" ? "justifyContent" : "alignItems";
    props[prop] = horizontalAlign;
  }

  if (verticalAlign) {
    const prop = type === "column" ? "alignItems" : "justifyContent";
    props[prop] = verticalAlign;
  }

  return (
    <Box display="flex" flexDirection={type} {...props}>
      {children}
    </Box>
  );
}
