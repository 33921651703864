import { calendlyAuth } from "api/calendly";
import Page from "components/Common/Page";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CalendlyCodePage = () => {
  const { search } = useLocation();
  const code = new URLSearchParams(search).get("code");

  useEffect(() => {
    if (code) {
      calendlyAuth(code).subscribe();
    }
  }, [code]);

  return <Page heading="Calendly Code">{code}</Page>;
};

export default CalendlyCodePage;
