import {
  createNotification,
  NotificationAction,
  NotificationTargetingType,
} from "api/notifications";
import { INotificationForm } from "components/Form/NotificationForm/notificationForm.reducer";
import { debounce, filter } from "lodash";
import { useState } from "react";

const useCreateNotificationPage = () => {
  const [form, setForm] = useState<INotificationForm>();

  const onCreateClick = () => {
    if (form) {
      let targeting;
      switch (form.type) {
        case NotificationTargetingType.UserIds:
          targeting = {
            type: form.type,
            userIds: form.userIds
              ? form.userIds.split(",").map((id) => id.trim())
              : [],
          };
          break;
        case NotificationTargetingType.InviteCode:
          targeting = {
            type: form.type,
            inviteCode: form.inviteCode,
          };
          break;
        case NotificationTargetingType.AppVersion:
          targeting = {
            type: form.type,
            appVersion: {
              minimum: form.appVersion?.minimum || undefined,
              maximum: form.appVersion?.maximum || undefined,
            },
          };
      }

      createNotification({
        title: form.title,
        body: form.body,
        link: form.link,
        action: form.action as NotificationAction,
        targeting,
      });
    }
  };

  const onFormChange = debounce((value: INotificationForm) => {
    setForm(value);
  }, 500);

  const isValid = () => {
    if (!form) {
      return false;
    }

    return (
      filter(["title", "body"], (property) => {
        const data = (form as any)[property];

        return data.length === 0;
      }).length === 0
    );
  };

  return { onCreateClick, isValid: isValid(), onFormChange };
};

export default useCreateNotificationPage;
