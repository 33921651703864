import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import useDepositDialog from "./DepositDialog.hooks";
import Spacer from "components/Common/Spacer";
import BankAccountDetails from "views/Users/UserDetailPage/components/UserActions/TransferDialog/BankAccountDetails";
import { ApexAccountType } from "models/ApexAccount";
import { capitalize } from "lodash";
import ACHRelationshipDetails from "views/Users/UserDetailPage/components/UserActions/TransferDialog/ACHRelationshipDetails";
import Loader from "components/Common/Loader";

interface IProps {
  userId: string;
  onEdit: () => void;
}

export default function DepositDialog({ userId, onEdit }: IProps) {
  const { account, formValues, handleFormChange, submit, relationship } =
    useDepositDialog(userId, onEdit);

  if (!relationship) {
    return <Loader />;
  }

  return (
    <Box minWidth={400}>
      <DialogTitle>Make a deposit</DialogTitle>
      <DialogContent>
        <Spacer height={8} />
        <FormControl fullWidth variant="standard">
          <InputLabel id="type">Destination</InputLabel>
          <Select
            labelId="type"
            id="type"
            value={formValues.type}
            name="type"
            label="Destination"
            onChange={handleFormChange}
          >
            {[ApexAccountType.Investment, ApexAccountType.Savings].map(
              (type) => (
                <MenuItem key={type} value={type}>
                  {capitalize(type)}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <Spacer height={20} />
        <TextField
          autoFocus
          margin="dense"
          id="amount"
          label="Amount"
          type="text"
          fullWidth
          variant="standard"
          value={formValues.amount}
          name="amount"
          onChange={handleFormChange}
        />
        <Spacer height={20} />
        {account ? (
          <BankAccountDetails account={account} />
        ) : (
          <ACHRelationshipDetails relationship={relationship} />
        )}
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={submit} size="large">
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
}
