import { updateUser } from "api/users";
import useForm from "components/Form/Form.hooks";
import DialogModal from "components/Modals/DialogModal";
import React from "react";
import {
  Box,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import Spacer from "components/Common/Spacer";

interface IProps {
  legalGivenName: string;
  legalMiddleName?: string;
  legalFamilyName: string;
  legalNameSuffix?: string;
  userId: string;
  onClose: () => void;
}

const LegalNameDialog = ({
  legalGivenName: initialLegalGivenName,
  legalMiddleName: initialLegalMiddleName,
  legalFamilyName: initialLegalFamilyName,
  legalNameSuffix: initialLegalNameSuffix,
  userId,
  onClose,
}: IProps) => {
  const { formValues, handleFormChange } = useForm({
    legalGivenName: initialLegalGivenName,
    legalMiddleName: initialLegalMiddleName,
    legalFamilyName: initialLegalFamilyName,
    legalNameSuffix: initialLegalNameSuffix,
  });
  const { legalGivenName, legalMiddleName, legalFamilyName, legalNameSuffix } =
    formValues;
  const onSave = () => {
    updateUser(userId, {
      legalGivenName,
      legalMiddleName,
      legalFamilyName,
      legalNameSuffix,
    });
  };

  return (
    <DialogModal
      onClose={onClose}
      renderDisplay={(open, setOpen) => (
        <Box
          onClick={() => setOpen(true)}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
        >
          <Typography variant="body2">
            {legalGivenName || ""} {legalMiddleName || ""}{" "}
            {legalFamilyName || ""} {legalNameSuffix || ""}
          </Typography>
        </Box>
      )}
      renderDialog={(open, setOpen) => (
        <Box minWidth={400}>
          <DialogTitle>Edit Birthday</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="legalGivenName"
              label="Legal First Name"
              type="text"
              fullWidth
              variant="standard"
              value={legalGivenName}
              name="legalGivenName"
              onChange={handleFormChange}
            />
            <Spacer height={20} />
            <TextField
              autoFocus
              margin="dense"
              id="legalMiddleName"
              label="Legal Middle Name"
              type="text"
              fullWidth
              variant="standard"
              value={legalMiddleName}
              name="legalMiddleName"
              onChange={handleFormChange}
            />
            <Spacer height={20} />
            <TextField
              autoFocus
              margin="dense"
              id="legalFamilyName"
              label="Legal Last Name"
              type="text"
              fullWidth
              variant="standard"
              value={legalFamilyName}
              name="legalFamilyName"
              onChange={handleFormChange}
            />
            <Spacer height={20} />
            <TextField
              autoFocus
              margin="dense"
              id="legalNameSuffix"
              label="Suffix (Optional)"
              type="text"
              fullWidth
              variant="standard"
              value={legalNameSuffix}
              name="legalNameSuffix"
              onChange={handleFormChange}
            />
            <Spacer height={20} />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onSave();
                setOpen(false);
              }}
              size="large"
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      )}
    />
  );
};

export default LegalNameDialog;
