import { Game } from "models/zuko/Game";
import { Player } from "models/zuko/Player";
import {
  PlayerCareerStat,
  getReadablePlayerCareerStatSeasonType,
  getReadablePlayerCareerStatType,
} from "models/zuko/PlayerCareerStat";
import { Team, getTeamName } from "models/zuko/Team";
import { toPercentage } from "utils/number";
import { formatUTCDate } from "utils/time";

interface PlayerCareerStatData {
  playerCareerStat: PlayerCareerStat;
  player: Player;
  game?: Game;
  team?: Team;
}

const usePlayerCareerStatTable = (model: PlayerCareerStatData) => {
  const tableRows = [
    {
      headerName: "ID",
      value: model.playerCareerStat.id,
    },
    {
      headerName: "Player First Name",
      value: model.player.givenName,
    },
    {
      headerName: "Player Last Name",
      value: model.player.familyName,
    },
    {
      headerName: "Team",
      value: model.team ? getTeamName(model.team) : "N/A",
    },
    {
      headerName: "Date",
      value: model.game ? formatUTCDate(model.game.date) : "N/A",
    },
    {
      headerName: "Game",
      value: model.game
        ? `${formatUTCDate(model.game.date)} - ${
            model.game.homeTeam.school
          } vs ${model.game.awayTeam.school}`
        : "N/A",
    },
    {
      headerName: "Year",
      value: formatUTCDate(model.playerCareerStat.startYear, "YYYY"),
    },
    {
      headerName: "Type",
      value: getReadablePlayerCareerStatType(model.playerCareerStat.type),
    },
  ];

  if (model.playerCareerStat.gamesPlayed) {
    tableRows.push({
      headerName: "Games Played",
      value: model.playerCareerStat.gamesPlayed.toString(),
    });
  }

  if (model.playerCareerStat.gamesStarted) {
    tableRows.push({
      headerName: "Games Started",
      value: model.playerCareerStat.gamesStarted.toString(),
    });
  }

  if (model.playerCareerStat.minutes) {
    tableRows.push({
      headerName: "Minutes",
      value: model.playerCareerStat.minutes.toString(),
    });
  }

  if (model.playerCareerStat.fieldGoalsMade) {
    tableRows.push({
      headerName: "Field Goals Made",
      value: model.playerCareerStat.fieldGoalsMade.toString(),
    });
  }

  if (model.playerCareerStat.fieldGoalsAttempted) {
    tableRows.push({
      headerName: "Field Goals Attempted",
      value: model.playerCareerStat.fieldGoalsAttempted.toString(),
    });
  }

  if (model.playerCareerStat.fieldGoalPercentage) {
    tableRows.push({
      headerName: "Field Goal Percentage",
      value: toPercentage(model.playerCareerStat.fieldGoalPercentage),
    });
  }

  if (model.playerCareerStat.twoPointsMade) {
    tableRows.push({
      headerName: "Two Points Made",
      value: model.playerCareerStat.twoPointsMade.toString(),
    });
  }

  if (model.playerCareerStat.twoPointsAttempted) {
    tableRows.push({
      headerName: "Two Points Attempted",
      value: model.playerCareerStat.twoPointsAttempted.toString(),
    });
  }

  if (model.playerCareerStat.twoPointPercentage) {
    tableRows.push({
      headerName: "Two Point Percentage",
      value: toPercentage(model.playerCareerStat.twoPointPercentage),
    });
  }

  if (model.playerCareerStat.threePointsMade) {
    tableRows.push({
      headerName: "Three Points Made",
      value: model.playerCareerStat.threePointsMade.toString(),
    });
  }

  if (model.playerCareerStat.threePointsAttempted) {
    tableRows.push({
      headerName: "Three Points Attempted",
      value: model.playerCareerStat.threePointsAttempted.toString(),
    });
  }

  if (model.playerCareerStat.threePointPercentage) {
    tableRows.push({
      headerName: "Three Point Percentage",
      value: toPercentage(model.playerCareerStat.threePointPercentage),
    });
  }

  if (model.playerCareerStat.freeThrowsMade) {
    tableRows.push({
      headerName: "Free Throws Made",
      value: model.playerCareerStat.freeThrowsMade.toString(),
    });
  }

  if (model.playerCareerStat.freeThrowsAttempted) {
    tableRows.push({
      headerName: "Free Throws Attempted",
      value: model.playerCareerStat.freeThrowsAttempted.toString(),
    });
  }

  if (model.playerCareerStat.freeThrowPercentage) {
    tableRows.push({
      headerName: "Free Throw Percentage",
      value: toPercentage(model.playerCareerStat.freeThrowPercentage),
    });
  }

  if (model.playerCareerStat.offensiveRebounds) {
    tableRows.push({
      headerName: "Offensive Rebounds",
      value: model.playerCareerStat.offensiveRebounds.toString(),
    });
  }

  if (model.playerCareerStat.defensiveRebounds) {
    tableRows.push({
      headerName: "Defensive Rebounds",
      value: model.playerCareerStat.defensiveRebounds.toString(),
    });
  }

  if (model.playerCareerStat.totalRebounds) {
    tableRows.push({
      headerName: "Total Rebounds",
      value: model.playerCareerStat.totalRebounds.toString(),
    });
  }

  if (model.playerCareerStat.turnovers) {
    tableRows.push({
      headerName: "Turnovers",
      value: model.playerCareerStat.turnovers.toString(),
    });
  }

  if (model.playerCareerStat.assists) {
    tableRows.push({
      headerName: "Assists",
      value: model.playerCareerStat.assists.toString(),
    });
  }

  if (model.playerCareerStat.steals) {
    tableRows.push({
      headerName: "Steals",
      value: model.playerCareerStat.steals.toString(),
    });
  }

  if (model.playerCareerStat.blocks) {
    tableRows.push({
      headerName: "Blocks",
      value: model.playerCareerStat.blocks.toString(),
    });
  }

  if (model.playerCareerStat.blockedShots) {
    tableRows.push({
      headerName: "Blocked Shots",
      value: model.playerCareerStat.blockedShots.toString(),
    });
  }

  if (model.playerCareerStat.personalFouls) {
    tableRows.push({
      headerName: "Personal Fouls",
      value: model.playerCareerStat.personalFouls.toString(),
    });
  }

  if (model.playerCareerStat.pointDifferential) {
    tableRows.push({
      headerName: "Point Differential",
      value: model.playerCareerStat.pointDifferential.toString(),
    });
  }

  if (model.playerCareerStat.points) {
    tableRows.push({
      headerName: "Points",
      value: model.playerCareerStat.points.toString(),
    });
  }

  if (model.playerCareerStat.seasonType) {
    tableRows.push({
      headerName: "Season Type",
      value: getReadablePlayerCareerStatSeasonType(
        model.playerCareerStat.seasonType
      ),
    });
  }

  if (model.playerCareerStat.winShares) {
    tableRows.push({
      headerName: "Win Shares",
      value: model.playerCareerStat.winShares.toString(),
    });
  }

  const getHeading = () => {
    return `Player Career Stat Information`;
  };

  return {
    tableRows,
    heading: getHeading(),
  };
};

export default usePlayerCareerStatTable;
