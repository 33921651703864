import Page from "../../../components/Common/Page";
import { useMatch } from "react-router-dom";
import useEducationSessionDetailPage from "./EducationSessionDetailPage.hooks";
import { Box, Breadcrumbs, Button, Link, Typography } from "@mui/material";
import moment from "moment";
import Spacer from "components/Common/Spacer";
import React from "react";
import EducationSessionQuestionsSection from "./EducationSessionQuestionsSection";
import AddQuestionDialog from './AddQuestionDialog';
import { endSession } from '../../../api/educationSessions';

const match = "education_sessions/:id"

const EducationSessionDetailPage = () => {
  const m = useMatch(match);
  const id = m?.params.id || "";
  const {session, questions, onQuestionActiveToggle, refresh} = useEducationSessionDetailPage(id)

  return <Page>
    <Box display="flex" justifyContent="space-between">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/education_sessions">
          Education Sessions
        </Link>
        <Typography color="text.primary">
          {session?.date ? moment(session.date).utc().format("MMMM D, YYYY") : ""} - {session?.inviteCode}
        </Typography>
      </Breadcrumbs>
    </Box>
    <Spacer height={40}/>
    <Box display="flex" justifyContent="space-between">
      <Typography
        variant="h4">{session?.date ? moment(session.date).utc().format("MMMM D, YYYY") : ""} - {session?.inviteCode?.toUpperCase()}</Typography>
      <Spacer height={40}/>
      <Box display="flex" alignItems="center">
        <Button onClick={() => {
          if (session) {
            endSession(session?.id).subscribe(refresh)
          }
        }} disabled={session?.ended}>{session?.ended ? "Ended" : "End Session"}</Button>
        <Spacer width={20}/>
        <AddQuestionDialog sessionId={session?.id} onSave={refresh}/>
      </Box>
    </Box>
    <Spacer height={40}/>
    {questions && <EducationSessionQuestionsSection questions={questions} onToggle={onQuestionActiveToggle}/>}
  </Page>
}

export default EducationSessionDetailPage