import { get, post } from "./base";

export interface FeatureFlag {
  title: String;
  enabled: boolean;
}

export const getFeatureFlags = () => {
  return get("/feature_flags", { includeDisabled: true });
};

export const updateFeatureFlag = (title: String, enabled: boolean) => {
  return post(`/feature_flags/${title}`, true, { enabled });
};
