import { Link, useNavigate } from "react-router-dom";
import {
  Channel,
  Chat,
  MessageInput,
  MessageList,
  Thread,
} from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import useChatChannel from "./ChatChannel.hooks";
import { Box } from "@mui/system";
import { ChevronLeft } from "@mui/icons-material";
import { Avatar, Button, Card, Typography } from "@mui/material";
import Spacer from "components/Common/Spacer";
import "./ChatChannel.css";

interface IProps {
    id: string;
    advisorId: string;
}

export default function ChatChannel({id, advisorId}: IProps) {
  const { advisorName, client, channel, user } = useChatChannel(id, advisorId);
  const navigate = useNavigate();

  return (
    <Box display="flex" flex={1} flexDirection="column" height="100%">
      <Box>
        <Link to="/chat" style={{ textDecoration: "none" }}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <ChevronLeft />
            <Typography>See all chats</Typography>
          </Box>
        </Link>
      </Box>
      <Spacer height={28} />
      <Box display="flex" flex={1} overflow="hidden">
        {client && (
          <Box display="flex" flexDirection="column">
            <Chat client={client}>
              {channel && user && (
                <Card>
                  <Channel channel={channel}>
                    <Box height="100%" display="flex" flexDirection="column">
                      <Box
                        padding="16px 20px"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Avatar
                          src={user.profileUrl}
                          sx={{ width: 36, height: 36 }}
                        />
                        <Spacer width={12} />
                        <Typography fontWeight="bold" variant="h5">
                          {user.givenName} {user.familyName}
                        </Typography>
                        <Box flex={1} />
                        <Button
                          variant="contained"
                          onClick={() => navigate(`/users/${user.id}`)}
                        >
                          Profile
                        </Button>
                      </Box>
                      <Box overflow="scroll" flex={1}>
                        <MessageList />
                      </Box>
                      <MessageInput
                        focus
                        overrideSubmitHandler={(message) => {
                          channel.sendMessage(message as any, {
                            skip_push: !user.showNotifications,
                          });
                        }}
                      />
                      <Thread />
                    </Box>
                  </Channel>
                </Card>
              )}
            </Chat>
            {advisorName && (
              <>
                <Spacer height={20} />
                <Typography align="center" color="#0000004D" variant="body1">
                  Responding as {advisorName}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
