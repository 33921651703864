import { Paper, TableContainer } from "@mui/material";
import { getAppVersion } from "api/users";
import Loader from "components/Common/Loader";
import HorizontalTable from "components/Table/HorizontalTable";
import { useEffect, useState } from "react";

function useAppVersionDownloaded(userId: string) {
  const [appVersion, setAppVersion] = useState();

  useEffect(() => {
    getAppVersion(userId).subscribe(({ data }) =>
      setAppVersion(data.appVersion)
    );
  }, []);

  return { appVersion };
}

interface IProps {
  userId: string;
}

const AppVersionDownloaded = ({ userId }: IProps) => {
  const { appVersion } = useAppVersionDownloaded(userId);

  if (!appVersion) {
    return <Loader />;
  }

  return (
    <TableContainer component={Paper}>
      <HorizontalTable
        rows={[
          {
            headerName: appVersion,
          },
        ]}
      />
    </TableContainer>
  );
};

export default AppVersionDownloaded;
