import { Button } from "@mui/material";
import DialogModal from "components/Modals/DialogModal";
import EditPlayerAwardTypeDialog from "./EditPlayerAwardTypeDialog";
import { Edit } from "@mui/icons-material";
import { PlayerAwardType } from "models/zuko/PlayerAward";

interface IProps {
  playerAwardType: PlayerAwardType;
  onClose: () => void;
  update: (name: string) => void;
}

const EditPlayerAwardTypeButton = ({
  playerAwardType,
  onClose,
  update,
}: IProps) => {
  return (
    <DialogModal
      renderDisplay={(open, setOpen) => (
        <Button
          startIcon={<Edit />}
          color="inherit"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Edit
        </Button>
      )}
      renderDialog={(open, setOpen) => (
        <EditPlayerAwardTypeDialog
          update={update}
          open={open}
          setOpen={setOpen}
          playerAwardType={playerAwardType}
        />
      )}
      onClose={onClose}
    />
  );
};

export default EditPlayerAwardTypeButton;
