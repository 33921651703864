import { KataraUser } from "models/KataraUser";
import * as React from "react";
import useKataraUser from "./KataraUser.hooks";

interface IProps {
  children: React.ReactNode;
  user?: KataraUser;
}

const KataraUserContext = React.createContext<KataraUser>(undefined!);

const KataraUserProvider = ({ children, user: adminUser }: IProps) => {
  const { user } = useKataraUser(adminUser);

  return (
    <KataraUserContext.Provider value={adminUser || user!}>
      {children}
    </KataraUserContext.Provider>
  );
};

const useKataraUserContext = () => {
  return React.useContext(KataraUserContext);
};

export { KataraUserProvider, useKataraUserContext };
