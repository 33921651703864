export interface Team {
  id: string;
  createdAt: Date;
  city: string | null;
  mascot: string | null;
  conference: string | null;
  division: string | null;
  abbreviation: string | null;
  type: string;
  school: string;
  sport: string;
  dataSourceId?: string;
}

export const getTeamName = (team: Team) => {
  return team.school ? team.school : `${team.city} ${team.mascot}`;
};

export interface TeamConference {
  id: string;
  teamId: string;
  conferenceId: string;
  startYear: Date;
}

export interface Conference {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
  deleted: boolean;
  name: string;
  defunct: boolean;
  startYear?: Date;
  defunctYear?: Date;
  wins?: number;
  losses?: number;
  winLossRatio?: number;
  simpleRatingSystem?: number;
  strengthOfSchedule?: number;
  apPollAppearances?: number;
  ncaaAppearances?: number;
  ncaaFinalFourAppearances?: number;
  ncaaWinners?: number;
}
