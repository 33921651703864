import { DataSourceId } from "models/zuko/DataSourceId";
import { ItemType } from "models/zuko/Item";
import { Game } from "models/zuko/Game";
import { formatUTCDate } from "utils/time";
import { getTeamName } from "models/zuko/Team";

const useGameTable = (
  models: Game[],
  datasourceIds: DataSourceId[],
  heading?: string
) => {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      valueFormatter: (params: any) => {
        return formatUTCDate(params.value, "MMMM D, YYYY");
      },
    },
    {
      field: "dataSourceId",
      headerName: "Data Source ID",
      width: 200,
    },
    {
      field: "homeTeam.school",
      headerName: "Team 1",
      valueGetter: (params: any) => {
        return getTeamName(params.row.homeTeam);
      },
      flex: 1,
    },
    {
      field: "homeTeamPoints",
      headerName: "T1 Pts",
      width: 50,
    },
    {
      field: "homeTeam.dataSourceId",
      headerName: "T1 DS ID",
      width: 150,
      valueGetter: (params: any) => {
        return params.row.homeTeam.dataSourceId;
      },
    },
    {
      field: "awayTeam.school",
      headerName: "Team 2",
      valueGetter: (params: any) => {
        return getTeamName(params.row.awayTeam);
      },
      flex: 1,
    },
    {
      field: "awayTeamPoints",
      headerName: "T2 Pts",
      width: 50,
    },
    {
      field: "awayTeam.dataSourceId",
      headerName: "T2 DS ID",
      width: 150,
      valueGetter: (params: any) => {
        return params.row.awayTeam.dataSourceId;
      },
    },
    {
      field: "pointDifferential",
      headerName: "Pt Diff",
      width: 50,
    },
    {
      field: "location",
      headerName: "Location",
      width: 150,
    },
  ];

  const getRows = () => {
    const gameMapping: { [id: string]: DataSourceId } = {};
    const teamMapping: { [id: string]: DataSourceId } = {};

    for (let i = 0; i < datasourceIds.length; i++) {
      const id = datasourceIds[i];
      if (id.itemType !== ItemType.Game && id.itemType !== ItemType.Team) {
        continue;
      }

      if (id.itemType === ItemType.Game) {
        gameMapping[id.itemId] = id;
      } else {
        teamMapping[id.itemId] = id;
      }
    }

    return models.map((model) => {
      model.homeTeam = {
        ...model.homeTeam,
        dataSourceId:
          (teamMapping[model.homeTeam.id] || {}).dataSourceId || "-",
      };

      model.awayTeam = {
        ...model.awayTeam,
        dataSourceId:
          (teamMapping[model.awayTeam.id] || {}).dataSourceId || "-",
      };

      return {
        ...model,
        dataSourceId: (gameMapping[model.id] || {}).dataSourceId || "-",
      };
    });
  };

  const getHeading = () => {
    return heading || `Games Information`;
  };

  return {
    columns,
    rows: getRows(),
    heading: getHeading(),
  };
};

export default useGameTable;
