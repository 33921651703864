export interface FinancialInfo {
  investingExperience: string;
  riskTolerance: string;
  investmentHolding: string;
  investmentLossReaction: string;
  investmentObjective: string;
  liquidityNeeds: string;
  annualIncomeRange: string;
  netWorthRange: string;
  liquidNetWorthRange: string;
  investmentAlternative: string;

  focus: string[];
  monthlyExpenses: string;
  upcomingPurchases: string;
  currentAssets: string;
}

export const getInvestmentObjectiveOptions = () => {
  return [
    "Maximum Growth",
    "Source of Income",
    "Balanced",
    "Capital Preservation",
    "Growth",
    "Growth Income",
    "Speculation",
    "Other",
  ];
};

export const getInvestmentHoldingOptions = () => {
  return {
    "0-3 years": "SHORT",
    "4-7 years": "AVERAGE",
    "8+ years": "LONG",
  };
};

export const getInvestmentLossReactionOptions = () => {
  return ["Sell everything", "Sell some", "Keep all", "Buy More"];
};

export const getNetWorthOptions = () => {
  return [
    "from0To50000",
    "from50001To100000",
    "from100001To200000",
    "from200001To500000",
    "from500001To1000000",
    "from1000001To5000000",
    "from5000001To9999999",
  ];
};

export const getAnnualIncomeOptions = () => {
  return [
    "from0To25000",
    "from25001To50000",
    "from50001To100000",
    "from100001To200000",
    "from200001To300000",
    "from300001To500000",
    "from500001To1200000",
    "from1200001To9999999",
  ];
};

export const getInvestmentAlternativeOptions = () => {
  return ["BUY_BITCOIN", "NIGHT_OUT", "SAVINGS", "NECESSITIES"];
};
