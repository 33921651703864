import {
  Box,
  IconButton,
  InputAdornment,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import DialogModal from "components/Modals/DialogModal";
import SearchIcon from "@mui/icons-material/Search";
import useUserSearch from "./UserSearch.hooks";

const UserSearch = () => {
  const { users, onSearchChange } = useUserSearch();

  return (
    <DialogModal
      renderDisplay={(_, setOpen) => {
        return (
          <IconButton
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <SearchIcon />
          </IconButton>
        );
      }}
      renderDialog={(_, setOpen) => {
        return (
          <>
            <Box minWidth={500}>
              <TextField
                autoFocus
                margin="dense"
                id="search"
                label="Search"
                type="text"
                fullWidth
                variant="filled"
                name="search"
                onChange={(e) => onSearchChange(e.currentTarget.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box>
                {users.map((user) => (
                  <ListItemButton component="a" href={`/users/${user.id}`}>
                    <ListItemText
                      primary={`${user.givenName} ${user.familyName}`}
                    />
                  </ListItemButton>
                ))}
              </Box>
            </Box>
          </>
        );
      }}
      onClose={() => {}}
    />
  );
};

export default UserSearch;
