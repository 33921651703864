import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { NotificationTargetingType } from "api/notifications";
import Spacer from "components/Common/Spacer";
import React from "react";
import { useEffect } from "react";
import { useReducer } from "react";
import notificationReducer, {
  ActionType,
  INITIAL_NOTIFICATION_FORM,
  INotificationForm,
} from "./notificationForm.reducer";

interface IProps {
  onChange: (value: INotificationForm) => void;
}

const NotificationForm = ({ onChange }: IProps) => {
  const [state, dispatch] = useReducer(
    notificationReducer,
    INITIAL_NOTIFICATION_FORM
  );

  useEffect(() => {
    onChange(state);
  }, [state]);

  return (
    <>
      <Box marginBottom="20px" width="100%">
        <TextField
          variant="outlined"
          fullWidth
          label="Notification Title"
          required
          value={state.title}
          onChange={(e) =>
            dispatch({
              type: ActionType.UpdateTitle,
              data: e.currentTarget.value,
            })
          }
        />
      </Box>
      <Box marginBottom="20px" width="100%">
        <TextField
          variant="outlined"
          fullWidth
          label="Notification Body"
          required
          value={state.body}
          onChange={(e) =>
            dispatch({
              type: ActionType.UpdateBody,
              data: e.currentTarget.value,
            })
          }
        />
      </Box>
      <Box marginBottom="20px" width="100%">
        <TextField
          variant="outlined"
          fullWidth
          label="Action"
          required
          value={state.action}
          onChange={(e) =>
            dispatch({
              type: ActionType.UpdateAction,
              data: e.currentTarget.value,
            })
          }
        />
      </Box>
      <Box marginBottom="20px" width="100%">
        <TextField
          variant="outlined"
          fullWidth
          label="Link"
          value={state.link}
          onChange={(e) =>
            dispatch({
              type: ActionType.UpdateLink,
              data: e.currentTarget.value,
            })
          }
        />
      </Box>
      <FormControl fullWidth>
        <InputLabel id="type">Targeting Type</InputLabel>
        <Select
          labelId="type"
          id="type_select"
          value={state.type}
          name="type"
          label="Targeting Type"
          onChange={(e) =>
            dispatch({
              type: ActionType.UpdateTargetingType,
              data: e.target.value,
            })
          }
        >
          {[
            NotificationTargetingType.UserIds,
            NotificationTargetingType.InviteCode,
            NotificationTargetingType.AppVersion,
          ].map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Spacer height={12} />
      {state.type === NotificationTargetingType.UserIds && (
        <Box marginBottom="20px" width="100%" textAlign="left">
          <TextField
            variant="outlined"
            fullWidth
            label="User IDs"
            helperText="User IDs must be comma separated."
            value={state.userIds}
            onChange={(e) =>
              dispatch({
                type: ActionType.UpdateUserIds,
                data: e.currentTarget.value,
              })
            }
          />
        </Box>
      )}
      {state.type === NotificationTargetingType.InviteCode && (
        <Box marginBottom="20px" width="100%" textAlign="left">
          <TextField
            variant="outlined"
            fullWidth
            label="Invite Code"
            value={state.inviteCode?.code}
            onChange={(e) =>
              dispatch({
                type: ActionType.UpdateCode,
                data: e.currentTarget.value,
              })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isPrefix"
                onChange={(e) =>
                  dispatch({
                    type: ActionType.TogglePrefix,
                    data: "",
                  })
                }
              />
            }
            label="isPrefix?"
          />
        </Box>
      )}
      {state.type === NotificationTargetingType.AppVersion && (
        <Box marginBottom="20px" width="100%" textAlign="left">
          <TextField
            variant="outlined"
            fullWidth
            label="Minimum Version (Inclusive)"
            helperText="optional"
            value={state.appVersion?.minimum}
            onChange={(e) =>
              dispatch({
                type: ActionType.UpdateMinimum,
                data: e.currentTarget.value,
              })
            }
          />
          <TextField
            variant="outlined"
            fullWidth
            label="Maximum Version (Inclusive)"
            helperText="optional"
            value={state.appVersion?.maximum}
            onChange={(e) =>
              dispatch({
                type: ActionType.UpdateMaximum,
                data: e.currentTarget.value,
              })
            }
          />
        </Box>
      )}
    </>
  );
};

export default NotificationForm;
