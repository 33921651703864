import { useEffect, useState } from "react";
import { EducationSession, EducationSessionQuestion } from "models/EducationSession";
import { fetcher } from "utils/data";
import { getEducationSession, updateEducationSessionQuestionActive } from "api/educationSessions";

interface Payload {
  session: EducationSession;
  questions: EducationSessionQuestion[];
}

const useEducationSessionDetailPage = (id: string) => {
  const [session, setSession] = useState<Payload>()

  const fetchSession = fetcher(getEducationSession, [id], setSession);

  useEffect(() => {
    fetchSession()
  }, []);

  const onQuestionActiveToggle = (questionId: string, active: boolean) => {
    updateEducationSessionQuestionActive(id, questionId, active).subscribe(fetchSession)
  }

  return {session: session?.session, questions: session?.questions, onQuestionActiveToggle, refresh: fetchSession}
}

export default useEducationSessionDetailPage