import {
  batchConfirmMerchantCategory,
  confirmMerchantCategory,
  getMerchantCategories,
  getPersonalFinanceCategories,
  updateMerchantCategory,
} from "api/plaid";
import {
  MerchantCategory,
  PersonalFinanceCategory,
} from "models/plaid/Transaction";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

const useTransactionsPage = () => {
  const [categories, setCategories] = useState<MerchantCategory[]>([]);
  const [personalFinanceCategories, setPersonalFinanceCategories] = useState<
    PersonalFinanceCategory[]
  >([]);
  const [selected, setSelected] = useState<string[]>([]);

  const fetchMerchantCategories = fetcher(
    getMerchantCategories,
    [],
    setCategories
  );

  const fetchPersonalFinanceCategories = fetcher(
    getPersonalFinanceCategories,
    [],
    setPersonalFinanceCategories
  );

  const confirm = (id: string) => {
    confirmMerchantCategory(id).subscribe(fetchMerchantCategories);
  };

  const update = (merchantCategory: MerchantCategory) => {
    updateMerchantCategory(merchantCategory).subscribe(fetchMerchantCategories);
  };

  const confirmSelected = () => {
    batchConfirmMerchantCategory(selected).subscribe(fetchMerchantCategories);
  }

  useEffect(() => {
    fetchMerchantCategories();
    fetchPersonalFinanceCategories();
  }, []);

  const onSelect = (s: string[]) => {
    setSelected(s);
  }

  return {
    categories,
    onClose: fetchMerchantCategories,
    confirm,
    personalFinanceCategories,
    update,
    onSelect,
    showConfirmSelected: selected.length > 0,
    confirmSelected,
  };
};

export default useTransactionsPage;
