import { PromoCode } from "models/PromoCode";
import { get, post } from "./base";

export const getPromoCodes = () => {
  return get("/admin/promo_codes");
};

export const createPromoCode = (code: PromoCode) => {
  return post("/admin/promo_codes", true, code);
};
