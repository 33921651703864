import { get, post } from './base';

export const getEducationSessions = (since?: string, before?: string) => {
  return get("/admin/education_sessions", {since, before});
}

export const getEducationSession = (id: string) => {
  return get(`/admin/education_sessions/${id}`);
}

export const updateEducationSessionQuestionActive = (sessionId: string, questionId: string, active: boolean) => {
  return post(`/admin/education_sessions/${sessionId}/questions/${questionId}/active`, true, {active});
}

export const createEducationSession = (date: string, inviteId: string) => {
  return post(`/admin/education_sessions`, true, {date, inviteId});
}

export const createQuestion = (formUrl: string, title: string) => {
  return post(`/admin/education_questions`, true, {formUrl, title});
}

export const addQuestion = (sessionId: string, questionId: string, priority: number) => {
  return post(`/admin/education_sessions/${sessionId}/questions/${questionId}`, true, {priority});
}

export const getQuestions = (search: string) => {
  return get(`/admin/education_questions`, { q: search });
}

export const endSession = (id: string) => {
  return post(`/admin/education_sessions/${id}`, true, {ended: true});
}