import {
  getPlayerAwardTypes,
  updatePlayerAwardTypes,
} from "api/zuko/playerAwards";
import StatusBadge from "components/Badges/StatusBadge";
import { PlayerAwardType } from "models/zuko/PlayerAward";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";
import EditPlayerAwardTypeButton from "./components/EditButton/EditPlayerAwardTypeButton";

const usePlayerAwardTypesPage = () => {
  const [types, setTypes] = useState<PlayerAwardType[]>([]);

  const fetchTypes = fetcher(getPlayerAwardTypes, [], setTypes);

  useEffect(() => {
    fetchTypes();
  }, []);

  const columns = [
    {
      field: "abbreviation",
      headerName: "Abbreviation",
      flex: 1,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 230,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params: any) => (
        <StatusBadge
          status={params.row.fullName ? "Reviewed" : "Needs Review"}
        />
      ),
      width: 150,
    },
    {
      field: "actions",
      headerName: "Review Actions",
      width: 300,
      renderCell: (params: any) => (
        <EditPlayerAwardTypeButton
          playerAwardType={params.row as PlayerAwardType}
          onClose={() => {}}
          update={(name: string) =>
            updatePlayerAwardTypes(params.row.id, name).subscribe(fetchTypes)
          }
        />
      ),
    },
  ];

  return { types, columns };
};

export default usePlayerAwardTypesPage;
