import { Button, Card, TableContainer, Typography } from "@mui/material";
import Page from "components/Common/Page";
import useFailedItemDetailPage from "./FailedItemDetailPage.hooks";
import { useMatch } from "react-router-dom";
import Loader from "components/Common/Loader";
import HorizontalTable from "components/Table/HorizontalTable";
import { Box } from "@mui/system";
import Spacer from "components/Common/Spacer";
import { ChevronRight } from "@mui/icons-material";
import FailedItemTable from "./FailedItemTable/FailedItemTable";

const match = "/failed_items/:id";

const FailedItemDetailPage = () => {
  const m = useMatch(match);
  const id = m?.params.id || "";
  const {
    item,
    model,
    heading,
    linkRows,
    scrapeLink,
    refetchModel,
    refetchItem,
    next,
    useTable,
  } = useFailedItemDetailPage(id);

  if (!item || !model) {
    return <Loader />;
  }

  if (!useTable) {
    return <Page heading={heading}>Table not implemented</Page>;
  }

  return (
    <Page heading={heading}>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Link Information</Typography>
          <Button variant="contained" color="primary" onClick={scrapeLink}>
            Scrape Link
          </Button>
        </Box>
        <Spacer height={20} />
        <TableContainer component={Card}>
          <HorizontalTable rows={linkRows} />
        </TableContainer>
      </Box>
      <Spacer height={32} />
      <FailedItemTable
        model={model}
        item={item}
        onUpdate={() => {
          refetchModel();
          refetchItem();
        }}
        useTable={useTable}
      />
      <Spacer height={32} />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
        width="100%"
      >
        {!!next && (
          <Button
            variant="contained"
            color="inherit"
            href={`/failed_items/${next.id}?previous=${id}`}
          >
            Next Item <ChevronRight />
          </Button>
        )}
      </Box>
    </Page>
  );
};

export default FailedItemDetailPage;
