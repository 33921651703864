import { Route, Routes } from "react-router-dom";
import CalendlyLoginPage from "./LoginPage/CalendlyLoginPage";
import CalendlyCodePage from "./CodePage/CalendlyCodePage";

export default function CalendlyRoutes() {
  return (
    <Routes>
      <Route
        key="calendly-login"
        path="/login"
        element={<CalendlyLoginPage />}
      />
      <Route key="calendly-code" path="/auth" element={<CalendlyCodePage />} />
    </Routes>
  );
}
