import Box from "@mui/material/Box";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { adminLogin } from "api/user";
import { isAdmin, KataraUser } from "models/KataraUser";
import { useEffect } from "react";
import { LocalStorageProp, setLocalStorageValue } from "utils/localStorage";

interface IProps {
  user?: KataraUser;
}

const LoginPage = ({ user }: IProps) => {
  const responseGoogle = (
    response: CredentialResponse
  ) => {
    if (response.credential) {
      adminLogin(response.credential).subscribe(({ data }) => {
        setLocalStorageValue(LocalStorageProp.Token, data.authToken);
        window.location.href = window.location.href;
      });
    }
  };

  useEffect(() => {
    if (user && isAdmin(user)) {
      window.open("/users", "_self");
    } else if (user) {
      window.open("/invite_codes", "_self");
    }
  }, [user]);

  return (
    <Box
      display="flex"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <GoogleLogin
        onSuccess={responseGoogle}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </Box>
  );
};

export default LoginPage;
