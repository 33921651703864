import { useState } from "react";
import { debounce } from "lodash";

export enum CalendlyEventsTab {
  UpcomingEvents = "UPCOMING EVENTS",
  PastEvents = "PAST EVENTS",
}

const useCalendlyEventsPage = () => {
  const [query, setQuery] = useState<string>("");
  const [currentTab, setCurrentTab] = useState<CalendlyEventsTab>(CalendlyEventsTab.UpcomingEvents);

  const debouncedSetQuery = debounce<(value: string) => void>(setQuery, 700);

  const onQueryChange = (value: string) => {
    debouncedSetQuery(value);
  };

  return {
    currentTab,
    hasQuery: query.length > 0,
    onQueryChange,
    query,
    setCurrentTab,
  };
};

export default useCalendlyEventsPage;
