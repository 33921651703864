import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import Spacer from "components/Common/Spacer";
import useForm from "components/Form/Form.hooks";
import { FailedVerificationItem } from "models/zuko/FailedVerificationItem";
import { Observable } from "rxjs";

interface IProps {
  item: FailedVerificationItem;
  setOpen: (open: boolean) => void;
  onUpdate: () => void;
  failItem: (id: string, reason: string) => Observable<any>;
}

const FailedVerificationItemReasonDialog = ({
  item,
  setOpen,
  onUpdate,
  failItem,
}: IProps) => {
  const { formValues, handleFormChange } = useForm(item);

  return (
    <Box width={400}>
      <DialogTitle>Failure Reason</DialogTitle>
      <DialogContent>
        <Box fontSize={16}>
          <Typography fontWeight={700}>ID</Typography>
          <Typography>{item.id}</Typography>
        </Box>
        <Spacer height={20} />
        <TextField
          variant="standard"
          fullWidth
          label="Reason"
          name="reason"
          multiline
          value={formValues.reason}
          onChange={handleFormChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(false);
            failItem(item.id, formValues.reason).subscribe(() => onUpdate());
          }}
        >
          save
        </Button>
      </DialogActions>
    </Box>
  );
};

export default FailedVerificationItemReasonDialog;
