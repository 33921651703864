import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Spacer from "components/Common/Spacer";

interface IProps {
  id: string;
  open: boolean;
  onClose: () => void;
  heading: string;
}

export default function IdDialog({ id, open, onClose, heading }: IProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{id}</Typography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="column" width="100%" minWidth={300}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigator.clipboard.writeText(id)}
            fullWidth
          >
            Copy ID
          </Button>
          <Spacer height={16} />
          <Button
            variant="contained"
            color="inherit"
            onClick={onClose}
            fullWidth
          >
            Nevermind
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
