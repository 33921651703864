import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import useSecurityModelDialog from "./SecurityModelDialog.hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Spacer from "components/Common/Spacer";
import useForm from "components/Form/Form.hooks";
import { AdvisorArchSecurityModel } from "models/advisor_arch/AdvisorArchSecurityModel";

interface IProps {
  open: boolean;
  onClose: () => void;
  model: AdvisorArchSecurityModel;
  refetch: () => void;
}

const getColumns = (onRowDelete: (cusip: string) => void) => {
  const columns: GridColDef[] = [
    {
      field: "cusip",
      headerName: "CUSIP",
      valueGetter: (params) => params.row.security?.cusip || "",
      width: 100,
    },
    {
      field: "symbol",
      headerName: "Symbol",
      valueGetter: (params) => params.row.security?.symbol || "",
      width: 80,
    },
    {
      field: "long_name",
      headerName: "Name",
      valueGetter: (params) => params.row.security?.long_name || "",
      flex: 1,
    },
    {
      field: "taxable_weight",
      headerName: "Weight",
      width: 80,
    },
    // {
    //   field: "delete",
    //   headerName: "",
    //   width: 50,
    //   valueGetter: (params) => "X",
    //   renderCell: (params: GridRenderCellParams) => {
    //     const disabled =
    //       params.row.security?.cusip === "922908769" || params.row.security?.cusip === "922907746";
    //     if (disabled) {
    //       return <></>;
    //     }

    //     return (
    //       <IconButton onClick={() => onRowDelete(params.row.security.cusip)}>
    //         <DeleteIcon />
    //       </IconButton>
    //     );
    //   },
    // },
  ];

  return columns;
};

const SecurityModelDialog = ({ open, onClose, model, refetch }: IProps) => {
  const { targets, onRowChange, onRowDelete, onRowAdd, onSave, editing } =
    useSecurityModelDialog(model, onClose, refetch);
  const { formValues, handleFormChange } = useForm(model);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {model.name} {model.risk} Security Model
      </DialogTitle>
      <DialogContent>
        <Box
          minWidth={500}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={formValues.name || ""}
            name="name"
            onChange={handleFormChange}
          />
          <Spacer height={20} />
          <TextField
            autoFocus
            margin="dense"
            id="subtitle"
            label="Subtitle"
            type="text"
            fullWidth
            variant="standard"
            value={formValues.subtitle || ""}
            name="subtitle"
            onChange={handleFormChange}
          />
          <Spacer height={20} />
          <DataGrid
            columns={getColumns(onRowDelete)}
            rows={targets}
            autoHeight
            editMode="row"
            hideFooter
            processRowUpdate={(params) => onRowChange(params)}
            onProcessRowUpdateError={(error) =>
              console.log("row update error", error)
            }
            getRowId={(row) => row.security?.cusip}
            experimentalFeatures={{ newEditingApi: true }}
          />
          <Button onClick={() => onRowAdd()}>
            <AddCircleOutlineRoundedIcon />
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          fullWidth
          variant="contained"
          onClick={onClose}
          size="large"
        >
          Exit
        </Button>
        {editing && (
          <Button
            fullWidth
            variant="contained"
            onClick={() => onSave(formValues)}
            size="large"
          >
            Save Changes
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SecurityModelDialog;
