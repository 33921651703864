import React from "react";
import { Box, Typography, Card } from "@mui/material";
import Spacer from "components/Common/Spacer";
import useRiskProfile from "./RiskProfile.hooks";
import Loader from "components/Common/Loader";
import { capitalizeAll } from "utils/strings";
import EditRiskProfileDialog from "./EditRiskProfileDialog/EditRiskProfileDialog";

interface IProps {
  userId: string;
}

export default function RiskProfile({ userId }: IProps) {
  const { info, risk, fetchAll } = useRiskProfile(userId);

  if (!info || !risk) {
    return <Loader />;
  }

  return (
    <Card>
      <Box padding="12px 16px" textAlign="left">
        <Typography variant="body1">{capitalizeAll(risk)} Risk</Typography>
        <Spacer height={12} />
        <Typography variant="body2" color="gray" component="div">
          <ul style={{ paddingLeft: "16px" }}>
            <li>Investing Goal: {capitalizeAll(info.investmentObjective)}</li>
            <li>
              Alternative to Investing:{" "}
              {capitalizeAll(info.investmentAlternative)}
            </li>
            <li>Time Horizon: {capitalizeAll(info.investmentHolding)}</li>
            <li>
              Loss Tolerance (in event of 5% drop):{" "}
              {capitalizeAll(info.investmentLossReaction)}
            </li>
            <li>Net Worth: ${info.netWorthRange}</li>
            <li>Annual Income: ${info.annualIncomeRange}</li>
          </ul>
        </Typography>
        <Spacer height={12} />
        <EditRiskProfileDialog userId={userId} info={info} onEdit={fetchAll} />
      </Box>
    </Card>
  );
}
