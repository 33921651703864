import { getPromoCodes } from "api/promoCodes";
import { PromoCode } from "models/PromoCode";
import { useEffect, useState, useCallback } from "react";

const usePromoCodesPage = () => {
  const [codes, setCodes] = useState<PromoCode[]>();

  const fetchPromoCodes = useCallback(() => {
    getPromoCodes().subscribe(({ data }) => setCodes(data));
  }, []);

  useEffect(() => {
    fetchPromoCodes();
  }, [fetchPromoCodes]);

  return {
    codes,
    fetchPromoCodes,
  };
};

export default usePromoCodesPage;
