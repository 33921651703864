import { Box } from "@mui/material";
import Spacer from "components/Common/Spacer";
import AccountBalances from "components/Details/AccountBalances/AccountBalances";
import AccountOverview from "components/Details/AccountOverview/AccountOverview";
import { ApexAccountType } from "models/ApexAccount";
import UserDetailSection from "../UserDetailSection";
import AccountActivity from "components/Details/AccountActivity/AccountActivity";
import FundingAccount from "components/Details/FundingAccount/FundingAccount";
import RecurringDeposits from "components/Details/RecurringDeposits/RecurringDeposits";

interface IProps {
  userId: string;
}

export default function SaveTabView({ userId }: IProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
      width="100%"
    >
      <UserDetailSection heading="Overview">
        <AccountOverview userId={userId} type={ApexAccountType.Savings} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Savings Balances">
        <AccountBalances userId={userId} type={ApexAccountType.Savings} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Activity">
        <AccountActivity userId={userId} type={ApexAccountType.Savings} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Funding Account">
        <FundingAccount userId={userId} type={ApexAccountType.Savings} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Recurring Deposits">
        <RecurringDeposits userId={userId} type={ApexAccountType.Savings} />
      </UserDetailSection>
    </Box>
  );
}
