import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  capitalize,
} from "@mui/material";
import Spacer from "components/Common/Spacer";
import useCreditDialog from "./CreditDialog.hooks";
import { ApexAccountType } from "models/ApexAccount";
import { CreditType } from "models/ApexTransfer";

interface IProps {
  userId: string;
  onEdit: () => void;
}

export default function CreditDialog({ userId, onEdit }: IProps) {
  const { formValues, handleFormChange, submit } = useCreditDialog(
    userId,
    onEdit
  );

  return (
    <Box minWidth={400}>
      <DialogTitle>Credit customer</DialogTitle>
      <DialogContent>
        <FormControl fullWidth variant="standard">
          <InputLabel id="type">Destination</InputLabel>
          <Select
            labelId="type"
            id="type"
            value={formValues.type}
            name="type"
            label="Destination"
            onChange={handleFormChange}
          >
            {[ApexAccountType.Investment, ApexAccountType.Savings].map(
              (type) => (
                <MenuItem key={type} value={type}>
                  {capitalize(type)}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <Spacer height={20} />
        <FormControl fullWidth variant="standard">
          <InputLabel id="type">Credit Type</InputLabel>
          <Select
            labelId="creditType"
            id="type_select"
            value={formValues.creditType}
            name="creditType"
            label="Credit Type"
            onChange={handleFormChange}
          >
            {[
              CreditType.FeeReversal,
              CreditType.PromoCredit,
              CreditType.WriteOff,
              CreditType.Rebate,
              CreditType.Courtesy,
            ].map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Spacer height={20} />
        <TextField
          autoFocus
          margin="dense"
          id="amount"
          label="Amount"
          type="text"
          fullWidth
          variant="standard"
          value={formValues.amount}
          name="amount"
          onChange={handleFormChange}
        />
        <Spacer height={20} />
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          variant="standard"
          value={formValues.description}
          name="description"
          onChange={handleFormChange}
        />
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={submit} size="large">
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
}
