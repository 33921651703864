export enum ItemType {
  Conference = "conferences",
  ConferenceName = "conference_names",
  DataSourceId = "data_source_ids",
  Game = "games",
  LeagueSalaryCap = "league_salary_caps",
  Link = "links",
  PlayerAwardType = "player_award_types",
  PlayerAward = "player_awards",
  PlayerCareerStat = "player_career_stats",
  PlayerSalary = "player_salaries",
  PlayerTeam = "player_teams",
  Player = "players",
  TeamConference = "team_conferences",
  TeamPayroll = "team_payrolls",
  Team = "teams",
}

export interface ChangeLogItem {
  type: ItemType;
  items: any[];
}
