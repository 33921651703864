import React from "react";
import { Box } from "@mui/material";

interface IProps {
  width?: number;
  height?: number;
}

const Spacer = ({ width, height }: IProps) => {
  return (
    <Box
      width={width ? `${width}px` : undefined}
      height={height ? `${height}px` : undefined}
    />
  );
};

export default Spacer;
