import { getUser } from "api/users";
import useChat from "components/Hooks/Chat.hooks";
import { User } from "models/User";
import { useEffect, useState } from "react";
import { from } from "rxjs";
import { Channel } from "stream-chat";
import { getAdvisor } from "../../../api/chat";

export default function useChatChannel(id: string, advisorId: string) {
  const [advisorName, setAdvisorName] = useState<string | undefined>();
  const [channel, setChannel] = useState<Channel | undefined>(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  const { client } = useChat(advisorId);

  if (client) {
    from(
      (client as any).queryChannels(
        { id },
        {},
        {
          watch: true,
          state: true,
        }
      )
    ).subscribe((res: any) => {
      setChannel(res[0]);
    });
  }

  useEffect(() => {
    if (!channel) {
      return;
    }
    const userId = (channel?.data?.created_by as any).id;
    if (userId) {
      getUser(userId).subscribe(({ data }) => {
        setUser(data);
      });
      getAdvisor(userId).subscribe(({ data }) => {
        const [firstName] = data.name.split(" ");
        setAdvisorName(firstName);
      });
    }
  }, [channel]);

  return {
    advisorName,
    client,
    channel,
    user,
  };
}
