import { useEffect, useState } from "react";
import { ActivityType, createNote, getActivities, UserActivity } from "../../../../../api/users";
import { fetcher } from "../../../../../utils/data";
import { v4 } from "uuid";
import { useKataraUserContext } from "../../../../../components/Contexts/KataraUserContext/KataraUserContext";

export default function useNotesTabView(userId: string) {
  const [activities, setActivities] = useState<UserActivity[]>([]);
  const [before, setBefore] = useState<string>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const user = useKataraUserContext();

  const limit = 11;

  const fetchActivities = fetcher(
    getActivities,
    [userId, limit, before],
    (fetched: any) => {
      if (!fetched?.length) {
        setHasMore(false);
        return;
      }
      const forDisplay = fetched.slice(0, limit - 1);
      setActivities(activities.concat(forDisplay));
      setHasMore(fetched.length === limit);
    },
  );

  useEffect(() => {
    fetchActivities();
  }, [userId, before]);

  const onShowMoreClick = () => {
    if (activities.length) {
      const last = activities[activities.length - 1];
      setBefore(last.date);
    }
  };

  const onSubmit = async (note: string, subject: string) => {
    await createNote(userId, note, subject);
    const now = new Date().toUTCString();
    const userName = `${user.givenName} ${user.familyName}`;
    const activity: UserActivity = {
      id: v4(),
      date: now,
      note: {note, subject, kataraUserName: userName},
      type: ActivityType.InternalNote,
    };
    setActivities([activity, ...activities]);
  };

  return {activities, hasMore, onShowMoreClick, onSubmit};
}
