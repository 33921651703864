import { uploadFile } from "api/s3";
import { IS3FileForm } from "components/Form/S3FileForm/s3FileForm.reducer";
import { useState } from "react";

const useUploadPage = () => {
  const [form, setForm] = useState<IS3FileForm>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [url, setUrl] = useState("");

  const onUploadClick = () => {
    if (form && form.file && form.type && form.contentType) {
      setLoading(true);
      uploadFile(form.file, form.type, form.contentType).subscribe(() => {
        setLoading(false);
        setSuccess(true);
        setUrl(`https://assets.elevatewith.com/${form.type}${form.file?.name}`);
      });
    }
  };

  const isValid = () => !!form && !!form.file && !!form.type;

  const onFormChange = (form: IS3FileForm) => {
    setForm(form);
  };

  const onRefreshClick = () => {
    window.location.reload();
  };

  return {
    onUploadClick,
    isValid: isValid(),
    onFormChange,
    loading,
    success,
    onRefreshClick,
    url,
  };
};

export default useUploadPage;
