import { useMatch } from "react-router-dom";
import usePendingValidationItemDetailPage from "./PendingValidationItemDetailPage.hooks";
import Page from "components/Common/Page";
import Loader from "components/Common/Loader";
import ModelListTable from "components/Table/ModelListTable/ModelListTable";
import { Player } from "models/zuko/Player";
import usePlayerTable from "components/Table/ModelListTable/PlayerListTable.hooks";
import { ItemType } from "models/zuko/Item";
import Spacer from "components/Common/Spacer";
import ModelTable from "components/Table/ModelTable/ModelTable";
import useLinkTable from "components/Table/ModelTable/LinkTable.hooks";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  Card,
} from "@mui/material";
import StatusBadge from "components/Badges/StatusBadge";
import FailedVerificationButton from "components/Buttons/FailedVerificationButton/FailedVerificationButton";
import { FailedVerificationItem } from "models/zuko/FailedVerificationItem";
import HorizontalTable from "components/Table/HorizontalTable";
import { formatUTCDate } from "utils/time";
import useGameTable from "components/Table/ModelListTable/GameListTable.hooks";
import { Game } from "models/zuko/Game";

const match = "/pending_validation_items/:id";

const PendingValidationItemDetailPage = () => {
  const m = useMatch(match);
  const id = m?.params.id || "";
  const {
    item,
    changeLog,
    heading,
    onValidate,
    onReset,
    refetch,
    changeLogLoading,
    dataSourceIds,
    recentFailed,
  } = usePendingValidationItemDetailPage(id);

  if (!item) {
    return <Loader />;
  }

  let modelTable = <></>;
  switch (item.itemType) {
    case ItemType.Link:
      modelTable = <ModelTable model={item.model} useTable={useLinkTable} />;
  }

  let status = item.validatedAt ? "Reviewed" : "Needs Review";
  if (item.failedAt) {
    status = "Failed Verification";
  }
  return (
    <Page>
      <Box display="flex" flexDirection="row">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            href="/pending_validation_items"
          >
            Pending Validation Items
          </Link>
          <Typography color="text.primary">{heading}</Typography>
        </Breadcrumbs>
      </Box>
      <Spacer height={48} />
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box flex={1} display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h4">{heading}</Typography>
          <Spacer width={12} />
          <StatusBadge status={status} />
        </Box>
        <Spacer width={12} />
        {status.toLowerCase() === "failed verification" && (
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onReset().subscribe(() => refetch());
              }}
            >
              Reset
            </Button>
          </Box>
        )}
      </Box>
      {!!recentFailed ? (
        <>
          <Spacer height={24} />
          <Typography variant="h6">Most Recent Failed Reason</Typography>
        </>
      ) : (
        <Spacer height={24} />
      )}
      {((item.failureReason && item.failedAt) || recentFailed) && [
        <Spacer height={20} />,
        <Card>
          <HorizontalTable
            rows={[
              {
                headerName: "Failed At",
                value: formatUTCDate(
                  item.failedAt || recentFailed?.updatedAt || new Date()
                ),
                key: "failed-at",
              },
              {
                headerName: "Failure Reason",
                value: item.failureReason || recentFailed?.reason,
                key: "failure-reason",
              },
            ]}
          />
        </Card>,
        <Spacer height={20} />,
      ]}
      {modelTable}
      {!changeLog && (
        <>
          <Spacer height={20} />
          <Box>
            <Typography>
              {changeLogLoading ? "Loading Changes..." : "No Changes Found"}
            </Typography>
          </Box>
        </>
      )}
      {changeLog &&
        changeLog.map((log) => {
          let table;
          switch (log.type) {
            case ItemType.Player:
              table = (
                <ModelListTable<Player>
                  models={log.items}
                  useTable={usePlayerTable}
                  heading="Changed Player Information"
                  dataSourceIds={dataSourceIds}
                />
              );
              break;
            case ItemType.Game:
              table = (
                <ModelListTable<Game>
                  models={log.items}
                  useTable={useGameTable}
                  heading="Changed Game Information"
                  dataSourceIds={dataSourceIds}
                />
              );
              break;
          }

          if (!table) {
            return <></>;
          }

          return [<Spacer height={20} />, table];
        })}
      <Spacer height={20} />
      {status.toLowerCase() === "needs review" && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              onValidate(true).subscribe(() => refetch());
            }}
          >
            Verify All Information Scraped
          </Button>
          <Spacer width={12} />
          <FailedVerificationButton
            item={
              {
                id: item.id,
                reason: item.failureReason,
              } as FailedVerificationItem
            }
            onUpdate={refetch}
            failItem={(id: string, reason: string) => {
              return onValidate(false, reason);
            }}
          />
        </Box>
      )}
    </Page>
  );
};

export default PendingValidationItemDetailPage;
