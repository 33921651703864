import { getUser } from "api/users";
import { User } from "models/User";
import { useEffect, useState } from "react";

const useUser = (userId?: string) => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (userId) {
      getUser(userId).subscribe(({ data }) => setUser(data));
    }
  }, [userId]);

  return user;
};

export default useUser;
