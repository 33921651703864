import Page from "components/Common/Page";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment, { Moment } from "moment";
import { useState } from "react";
import { Paper, Box, Button } from "@mui/material";
import Spacer from "components/Common/Spacer";
import { getSODFiles } from "api/apex";

const SODDownloadPage = () => {
  const [value, setValue] = useState<Moment | null>(moment());

  const handleChange = (newValue: Moment | null) => {
    setValue(newValue);
  };

  return (
    <Page heading="Download SOD Files">
      <Paper>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          padding="32px 16px"
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label="Date desktop"
              inputFormat="MM/DD/YYYY"
              value={value}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Spacer height={32} />
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              value &&
              getSODFiles(value.format("YYYYMMDD")).subscribe(({ data }) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(data);

                // create "a" HTLM element with href to file & click
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute(
                  "download",
                  `EXT982-${value.format("YYYYMMDD")}.csv`
                ); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
              })
            }
          >
            Download File
          </Button>
        </Box>
      </Paper>
    </Page>
  );
};

export default SODDownloadPage;
