import { Box, Button, Typography } from "@mui/material";
import Page from "components/Common/Page";
import useUploadPage from "./UploadPage.hooks";
import S3FileForm from "components/Form/S3FileForm/S3FileForm";
import Loader from "components/Common/Loader";
import Spacer from "components/Common/Spacer";

const UploadPage = () => {
  const {
    onUploadClick,
    isValid,
    onFormChange,
    loading,
    success,
    onRefreshClick,
    url,
  } = useUploadPage();

  return (
    <Page>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        padding="20px"
      >
        <Box marginTop="8px" marginBottom="32px">
          <Typography variant="h4">Upload to S3</Typography>
        </Box>
        <S3FileForm onChange={onFormChange} disabled={success || loading} />
        <Box
          display="flex"
          justifyContent="flex-end"
          width="100%"
          marginTop="32px"
        >
          {loading && (
            <Box marginRight="20px">
              <Loader />
            </Box>
          )}
          {!success && (
            <Button
              variant="contained"
              color="primary"
              disabled={!isValid || loading}
              onClick={onUploadClick}
            >
              {loading ? "Uploading..." : "Upload"}
            </Button>
          )}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {url && <Typography variant="body1">{url}</Typography>}
            <Spacer width={12} />
            {success && (
              <Button
                variant="contained"
                color="primary"
                onClick={onRefreshClick}
              >
                Upload Another File
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default UploadPage;
