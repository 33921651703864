import { Button } from "@mui/material";
import DialogModal from "components/Modals/DialogModal";
import FailedVerificationItemReasonDialog from "./FailedVerificationDialog";
import { FailedVerificationItem } from "models/zuko/FailedVerificationItem";
import { Observable } from "rxjs";

interface IProps {
  item: FailedVerificationItem;
  onUpdate: () => void;
  failItem: (id: string, reason: string) => Observable<any>;
}

const FailedVerificationButton = ({ item, onUpdate, failItem }: IProps) => {
  return (
    <DialogModal
      renderDisplay={(open, setOpen) => (
        <Button variant="contained" color="error" onClick={() => setOpen(true)}>
          Fail
        </Button>
      )}
      renderDialog={(open, setOpen) => (
        <FailedVerificationItemReasonDialog
          item={item}
          setOpen={setOpen}
          onUpdate={onUpdate}
          failItem={failItem}
        />
      )}
      onClose={() => {}}
    />
  );
};

export default FailedVerificationButton;
