import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  capitalize,
} from "@mui/material";
import Spacer from "components/Common/Spacer";
import BankAccountDetails from "views/Users/UserDetailPage/components/UserActions/TransferDialog/BankAccountDetails";
import useWithdrawalDialog from "./WithdrawalDialog.hooks";
import { ApexAccountType } from "models/ApexAccount";

interface IProps {
  userId: string;
  onEdit: () => void;
}

export default function WithdrawalDialog({ userId, onEdit }: IProps) {
  const {
    account,
    formValues,
    handleFormChange,
    handleCheckboxChange,
    submit,
  } = useWithdrawalDialog(userId, onEdit);

  return (
    <Box minWidth={400}>
      <DialogTitle>Make a withdrawal</DialogTitle>
      <DialogContent>
        <FormControl fullWidth variant="standard">
          <InputLabel id="type">Destination</InputLabel>
          <Select
            labelId="type"
            id="type"
            value={formValues.type}
            name="type"
            label="Destination"
            onChange={handleFormChange}
          >
            {[ApexAccountType.Investment, ApexAccountType.Savings].map(
              (type) => (
                <MenuItem key={type} value={type}>
                  {capitalize(type)}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <Spacer height={20} />
        <TextField
          autoFocus
          margin="dense"
          id="amount"
          label="Amount"
          type="text"
          fullWidth
          variant="standard"
          value={formValues.amount}
          name="amount"
          onChange={handleFormChange}
        />
        <Spacer height={20} />
        <FormControlLabel
          control={
            <Checkbox name="liquidateAll" onChange={handleCheckboxChange} />
          }
          label="Full Liquidation"
        />
        <Spacer height={20} />
        <FormControlLabel
          control={<Checkbox name="terminal" onChange={handleCheckboxChange} />}
          label="Fradualent?"
        />
        <Spacer height={20} />
        {account && <BankAccountDetails account={account} />}
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" onClick={submit} size="large">
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
}
