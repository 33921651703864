import { Button } from "@mui/material";
import DialogModal from "components/Modals/DialogModal";
import EditMerchantCategoryDialog from "./EditMerchantCategoryDialog";
import {
  MerchantCategory,
  PersonalFinanceCategory,
} from "models/plaid/Transaction";
import { Edit } from "@mui/icons-material";

interface IProps {
  merchantCategory: MerchantCategory;
  onClose: () => void;
  personalFinanceCategories: PersonalFinanceCategory[];
  update: (merchantCategory: MerchantCategory) => void;
}

const EditMerchantCategoryButton = ({
  merchantCategory,
  onClose,
  personalFinanceCategories,
  update,
}: IProps) => {
  return (
    <DialogModal
      renderDisplay={(open, setOpen) => (
        <Button
          startIcon={<Edit />}
          color="inherit"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Edit
        </Button>
      )}
      renderDialog={(open, setOpen) => (
        <EditMerchantCategoryDialog
          update={update}
          personalFinanceCategories={personalFinanceCategories}
          open={open}
          setOpen={setOpen}
          merchantCategory={merchantCategory}
        />
      )}
      onClose={onClose}
    />
  );
};

export default EditMerchantCategoryButton;
