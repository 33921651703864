import { Box, Button, DialogActions, DialogContent, DialogTitle, Link, Typography } from "@mui/material";
import React from "react";
import DialogModal from "../../components/Modals/DialogModal";
import { noop } from "lodash";
import useScoutPlanDetailsDialog from "./ScoutPlanDetailsDialog.hooks";
import Loader from "../../components/Common/Loader";

interface IProps {
  userId: string;
  userName: string;
}

const ScoutPlanDetailsDialog = ({userId, userName}: IProps) => {
  const {scoutPlan} = useScoutPlanDetailsDialog(userId);
  const currentAllocation = scoutPlan?.currentAllocation;

  const roundUpsStatusText = (status?: boolean) => status ? "Enabled" : "Disabled";

  return (
    <DialogModal
      renderDisplay={(_, setOpen) => <Link onClick={() => setOpen(true)}>See Details</Link>}
      renderDialog={(_, setOpen) => (
        <Box width="400px">
          <DialogTitle>{userName ? `${userName}’s ` : ""}Scout Plan Status</DialogTitle>
          {scoutPlan ? (
            <>
              <DialogContent>
                <Typography variant="body2">
                  <Typography fontWeight="bold">Monthly Investing</Typography>
                  Our Recommendation: ${scoutPlan.investmentMonthlyDeposit}/month<br/>
                  Their Current Status: ${currentAllocation?.investmentMonthlyDeposit}/month<br/>
                  <br/>
                  <Typography fontWeight="bold">Monthly Saving</Typography>
                  Our Recommendation: ${scoutPlan.savingsMonthlyDeposit}/month<br/>
                  Their Current Status: ${currentAllocation?.savingsMonthlyDeposit}/month<br/>
                  <br/>
                  <Typography fontWeight="bold">Round Ups</Typography>
                  Our Recommendation: {roundUpsStatusText(scoutPlan.roundUps)}<br/>
                  Their Current Status: {roundUpsStatusText(currentAllocation?.roundUps)}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={() => setOpen(false)}>Ok</Button>
              </DialogActions>
            </>
          ) : <Loader/>}
        </Box>
      )}
      onClose={noop}/>
  );
};

export default ScoutPlanDetailsDialog;
