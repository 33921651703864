import { getAdvisorArchSecurityModels } from "api/advisor_arch/securityModels";
import { AdvisorArchSecurityModel } from "models/advisor_arch/AdvisorArchSecurityModel";
import { useCallback, useEffect, useState } from "react";

const useSecurityModelsPage = () => {
  const [models, setModels] = useState();
  const [selected, setSelected] = useState<AdvisorArchSecurityModel>();
  const [creating, setCreating] = useState(false);

  const fetchModels = useCallback(
    () =>
      getAdvisorArchSecurityModels().subscribe(({ data }) => setModels(data)),
    []
  );

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  return {
    models,
    setSelected,
    selected,
    refetch: fetchModels,
    setCreating,
    creating,
  };
};

export default useSecurityModelsPage;
