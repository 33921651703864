import { useEffect, useState } from "react";
import { InviteCode } from "models/InviteCode";
import { fetcher } from "utils/data";
import { getInviteCodes } from "api/inviteCodes";


const useInviteCodeSelect = (onChange: (code: InviteCode) => void, payingOnly?: boolean) => {
  const [codes, setCodes] = useState<InviteCode[]>([])
  const [active, setActive] = useState<InviteCode>()

  const fetchCodes = fetcher(getInviteCodes, [], setCodes);

  useEffect(() => {
    fetchCodes();
  }, []);

  useEffect(() => {
    if (active) {
      onChange(active);
    }
  }, [active])

  return {codes: codes.filter(code => payingOnly ? code.paying : true), active, setActive}
}

export default useInviteCodeSelect