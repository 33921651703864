import { getApexInternalBalanceByUserId } from "api/accounts";
import { getTotalInternalBalanceByUserId } from "api/apex";
import { ApexAccountType } from "models/ApexAccount";
import { ApexInternalBalance } from "models/ApexInternalBalance";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

export default function useAccountBalances(
  userId: string,
  type?: ApexAccountType
) {
  const [balances, setBalances] = useState<ApexInternalBalance>();

  const fetchInternalBalance = fetcher(
    type ? getApexInternalBalanceByUserId : getTotalInternalBalanceByUserId,
    type ? [userId, type] : [userId],
    setBalances
  );

  useEffect(() => {
    fetchInternalBalance();
  }, [userId]);

  return { balances };
}
