import { fetcher } from "../../../../utils/data";
import { getInvestmentTransactions } from "../../../../api/plaid";
import { useEffect, useState } from "react";
import { InvestmentTransactions } from "../../../../models/plaid/Transaction";

export default function useAllAccountsTabPanel() {
  const transactionsPerPage = 10;

  const [page, setPage] = useState<number>(0);
  const [transactions, setTransactions] = useState<InvestmentTransactions>();

  const fetchTransactions = fetcher(getInvestmentTransactions, [false, transactionsPerPage, page * transactionsPerPage], setTransactions);

  useEffect(() => {
    fetchTransactions();
  }, [page]);

  return {
    loading: transactions === undefined,
    page,
    setPage,
    totalCount: transactions?.totalCount ?? 0,
    transactions: transactions?.transactions ?? [],
    transactionsPerPage,
  };
}
