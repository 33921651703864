import { Avatar, Box, Typography } from "@mui/material";
import Spacer from "components/Common/Spacer";
import { BankAccount } from "models/BankAccount";
import React from "react";

interface IProps {
  account: BankAccount;
}

const BankAccountDetails = ({ account }: IProps) => {
  return (
    <Box width="100%" display="flex" flexDirection="row">
      <Avatar src={`data:image/png;base64, ${account.institutionLogo}`} />
      <Spacer width={20} />
      <Box>
        <Typography variant="body1">{account.name}</Typography>
        <Typography variant="body1" color="gray">
          Available Balance: ${account.availableBalance}
        </Typography>
      </Box>
    </Box>
  );
};

export default BankAccountDetails;
