import React, { useEffect } from "react";
import { getLocalStorageValue, LocalStorageProp } from "utils/localStorage";

const useAuth = () => {
  useEffect(() => {
    const token = getLocalStorageValue(LocalStorageProp.Token);

    if (!token) {
      window.open("/login", "_self");
    }
  }, []);
};

export default useAuth;
