import { Button } from "@mui/material";
import FlexBox from "components/Common/FlexBox";
import Spacer from "components/Common/Spacer";
import DialogModal from "components/Modals/DialogModal";
import OverflowMenuDialog from "./OverflowMenuDialog/OverflowMenuDialog";
import { noop } from "lodash";
import SuperAdminAction from "components/SuperAdmin/SuperAdminAction";
import { MoreHoriz } from "@mui/icons-material";
import TransferDialog from "./TransferDialog/TransferDialog";

interface IProps {
  userId: string;
}

export default function UserActions({ userId }: IProps) {
  return (
    <FlexBox type="row">
      <DialogModal
        renderDialog={(open, setOpen) => (
          <TransferDialog userId={userId} close={() => setOpen(false)} />
        )}
        renderDisplay={(open, setOpen) => (
          <SuperAdminAction
            renderComponent={(onClick) => (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={onClick}
              >
                Transfer
              </Button>
            )}
            action={() => setOpen(true)}
          />
        )}
        onClose={noop}
      />
      <Spacer width={12} />
      <DialogModal
        renderDialog={(open, setOpen) => (
          <OverflowMenuDialog
            userId={userId}
            closeDialog={() => setOpen(false)}
            onEdit={() => window.location.reload()}
          />
        )}
        renderDisplay={(open, setOpen) => (
          <Button variant="contained" color="inherit" onClick={() => setOpen(true)}>
            <MoreHoriz />
          </Button>
        )}
        onClose={noop}
      />
    </FlexBox>
  );
}
