import {
  FeatureFlag,
  getFeatureFlags,
  updateFeatureFlag,
} from "api/featureFlags";
import { keys } from "lodash";
import { useCallback, useEffect, useState } from "react";

const useFeatureFlagsPage = () => {
  const [flags, setFlags] = useState<FeatureFlag[]>();

  const fetchFlags = useCallback(() => {
    getFeatureFlags().subscribe((mapping) => {
      setFlags(
        keys(mapping.data).map((key) => ({
          title: key,
          enabled: mapping.data[key],
        }))
      );
    });
  }, []);

  useEffect(() => {
    fetchFlags();
  }, [fetchFlags]);

  const onFeatureFlagChange = (flag: FeatureFlag) => {
    updateFeatureFlag(flag.title, !flag.enabled).subscribe(() => {
      fetchFlags();
    });
  };

  return { flags, onFeatureFlagChange };
};

export default useFeatureFlagsPage;
