import { updateUserFinancialInfo } from "api/users";
import { FinancialInfo } from "models/RiskProfile";
import { useState } from "react";

export default function useEditRiskProfileDialog(
  userId: string,
  onEdit: () => void
) {
  const [form, setForm] = useState<FinancialInfo>();

  const onSave = () => {
    if (form) {
      updateUserFinancialInfo(userId, form).subscribe(onEdit);
    }
  };

  return {
    setForm,
    onSave,
  };
}
