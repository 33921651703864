import Page from "components/Common/Page";
import useTransactionsPage from "./TransactionsPage.hooks";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import StatusBadge from "components/Badges/StatusBadge";
import { Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import EditMerchantCategoryButton from "./components/EditButton/EditMerchantCategoryButton";
import Spacer from "components/Common/Spacer";
import { MerchantCategory } from "models/plaid/Transaction";
import { Check } from "@mui/icons-material";

const TransactionsPage = () => {
  const {
    categories,
    onClose,
    confirm,
    personalFinanceCategories,
    update,
    onSelect,
    showConfirmSelected,
    confirmSelected,
  } = useTransactionsPage();

  const getColumns = (): GridColDef[] => [
    {
      field: "merchantName",
      headerName: "Merchant Name",
      width: 230,
      renderCell: (params: any) => (
        <Typography variant="body2" style={{ overflowWrap: "break-word" }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "primaryCategory",
      headerName: "Primary Category",
      width: 200,
    },
    {
      field: "detailedCategory",
      headerName: "Detailed Category",
      width: 400,
    },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      renderCell: (params: any) => (
        <Typography style={{ overflowWrap: "break-word" }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "manualVerification",
      headerName: "Status",
      renderCell: (params: any) => (
        <StatusBadge status={params.value ? "Reviewed" : "Needs Review"} />
      ),
      width: 150,
    },
    {
      field: "actions",
      headerName: "Review Actions",
      width: 300,
      renderCell: (params: any) => (
        <Box display="flex" flexDirection="row">
          {params.row.manualVerification ? (
            <></>
          ) : (
            <>
              <Button
                startIcon={<Check />}
                variant="contained"
                color="success"
                onClick={() => {
                  confirm(params.id);
                }}
              >
                Confirm
              </Button>
              <Spacer width={12} />
            </>
          )}
          <EditMerchantCategoryButton
            merchantCategory={params.row as MerchantCategory}
            onClose={onClose}
            personalFinanceCategories={personalFinanceCategories}
            update={(merchantCategory: MerchantCategory) =>
              update(merchantCategory)
            }
          />
        </Box>
      ),
    },
  ];

  return (
    <Page
      heading="Transaction Categories"
      RightHeadingComponent={
        <Box>
          {showConfirmSelected && (
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                confirmSelected();
              }}
            >
              Confirm Selected
            </Button>
          )}
        </Box>
      }
    >
      <Paper>
        <DataGrid
          checkboxSelection
          onSelectionModelChange={(newSelection) =>
            onSelect(newSelection as string[])
          }
          rows={categories}
          columns={getColumns()}
          autoHeight
          rowCount={categories.length}
          sx={{ overflowX: "scroll" }}
          getRowHeight={() => "auto"}
        />
      </Paper>
    </Page>
  );
};

export default TransactionsPage;
