import { Avatar, Box, Typography } from "@mui/material";
import Spacer from "components/Common/Spacer";
import { ApexACHRelationship } from "models/ApexACHRelationship";
import React from "react";
import { capitalizeAll } from "utils/strings";

interface IProps {
  relationship: ApexACHRelationship;
}

export default function ACHRelationshipDetails({ relationship }: IProps) {
  return (
    <Box width="100%" display="flex" flexDirection="row">
      <Avatar />
      <Spacer width={20} />
      <Box>
        <Typography variant="body1">
          {relationship.bankAccountNickname}
        </Typography>
        <Typography variant="body1" color="gray">
          Approval Method: {capitalizeAll(relationship.approvalMethod)}
        </Typography>
      </Box>
    </Box>
  );
}
