import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import OverviewTabView from "./OverviewTabView/OverviewTabView";
import Spacer from "components/Common/Spacer";
import InvestTabView from "./InvestTabView/InvestTabView";
import SaveTabView from "./SaveTabView/SaveTabView";
import NotesTabView from "./NotesTabView/NotesTabView";

enum UserDetailsTab {
  Overview = "Overview",
  Invest = "Invest",
  Save = "Save",
  Notes = "Notes",
}

interface IProps {
  userId: string;
}

const useUserDetailsContainer = () => {
  const [selectedTab, setSelectedTab] = useState(UserDetailsTab.Overview);

  return {
    tabs: [
      UserDetailsTab.Overview,
      UserDetailsTab.Invest,
      UserDetailsTab.Save,
      UserDetailsTab.Notes,
    ],
    selectedTab,
    setSelectedTab,
  };
};

export default function UserDetailsContainer({ userId }: IProps) {
  const { tabs, selectedTab, setSelectedTab } = useUserDetailsContainer();

  return (
    <>
      <Box width="100%" display="flex" flexDirection="column">
        <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)}>
          {tabs.map((tab) => (
            <Tab key={tab} label={tab} value={tab} />
          ))}
        </Tabs>
        <Spacer height={24} />
        <Box display="flex" width="100%" flex={1}>
          {selectedTab === UserDetailsTab.Overview && (
            <OverviewTabView userId={userId} />
          )}
          {selectedTab === UserDetailsTab.Invest && (
            <InvestTabView userId={userId} />
          )}
          {selectedTab === UserDetailsTab.Save && (
            <SaveTabView userId={userId} />
          )}
          {selectedTab === UserDetailsTab.Notes && (
            <NotesTabView userId={userId} />
          )}
        </Box>
      </Box>
    </>
  );
}
