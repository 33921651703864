import { Card, TableContainer, Box, Typography } from "@mui/material";
import HorizontalTable from "components/Table/HorizontalTable";
import Spacer from "components/Common/Spacer";

interface IProps {
  model: any;
  useTable: (model: any) => { tableRows: any[]; heading: string };
  leftHeaderItems?: any[];
  rightHeaderItems?: any[];
}

const ModelTable = ({
  model,
  useTable,
  leftHeaderItems,
  rightHeaderItems,
}: IProps) => {
  const { tableRows, heading } = useTable(model);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h6">{heading}</Typography>
          {leftHeaderItems}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {rightHeaderItems}
        </Box>
      </Box>
      <Spacer height={20} />
      <TableContainer component={Card}>
        <HorizontalTable rows={tableRows} />
      </TableContainer>
    </Box>
  );
};

export default ModelTable;
