import React from "react";
import { Box } from "@mui/material";

interface IProps {
  text: string;
  borderColor: string;
  color: string;
  bgcolor: string;
  onClick: () => void;
}

const RectangleButton = ({ text, borderColor, color, bgcolor, onClick }: IProps) => {
  return (
    <Box
      height="32px"
      width="32px"
      border="1px solid"
      borderColor={borderColor}
      borderRadius="4px"
      onClick={onClick}
      fontSize="14px"
      fontWeight="bold"
      color={color}
      bgcolor={bgcolor}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {text}
    </Box>
  );
};

export default RectangleButton;
