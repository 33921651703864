import { Card } from "@mui/material";
import Page from "components/Common/Page";
import useFailedItemsListPage from "./FailedItemsListPage.hooks";
import { DataGrid } from "@mui/x-data-grid";

const FailedItemsListPage = () => {
  const { items, columns } = useFailedItemsListPage();

  return (
    <Page heading="Model Validation">
      <Card>
        <DataGrid
          rows={items}
          columns={columns}
          autoHeight
          rowCount={items.length}
          sx={{ overflowX: "scroll" }}
        />
      </Card>
    </Page>
  );
};

export default FailedItemsListPage;
