export enum ApexAccountType {
  Savings = "savings",
  Investment = "investment",
}

export enum AccountRequestState {
  New = "NEW",
  Pending = "PENDING",
  VendorRequestSubmitted = "VENDOR_REQUEST_SUBMITTED",
  InvestigationSubmitted = "INVESTIGATION_SUBMITTED",
  ReadyForBackOffice = "READY_FOR_BACK_OFFICE",
  BackOffice = "BACK_OFFICE",
  AccountSetup = "ACCOUNT_SETUP",
  Complete = "COMPLETE",

  NotSubmitted = "NOT_SUBMITTED",
  Closed = "CLOSED",

  Rejected = "REJECTED",
  Suspended = "SUSPENDED",
  Error = "ERROR",
  Cancelled = "CANCELED",
}

export const getAccountRequestStateColor = (state: AccountRequestState) => {
  switch (state) {
    case AccountRequestState.Rejected:
    case AccountRequestState.Suspended:
    case AccountRequestState.Cancelled:
      return "warning";
    case AccountRequestState.Error:
      return "error";
    case AccountRequestState.Complete:
      return "success";
    default:
      return "primary";
  }
};

export interface ApexAccount {
  accountId: string;
  requestState: AccountRequestState;
  createdAt: string;
  requestId: string;
  updatedAt: string;
  userId: string;
  type: ApexAccountType;
}
