import moment from "moment";

const minutesInSeconds = 60;
const hoursInSeconds = 60 * minutesInSeconds;

export const getTimeString = (seconds: number = 0) => {
  let s = seconds;
  const hours = Math.floor(s / hoursInSeconds);

  s -= hours * hoursInSeconds;
  const minutes = Math.floor(s / minutesInSeconds);

  s -= minutes * minutesInSeconds;

  return `${hours > 0 ? `${hours} hour(s) ` : ""}${
    minutes > 0 ? `${minutes} minute(s) ` : ""
  }${s} seconds`;
};

export const formatUTCDate = (date: Date | string, format?: string) => {
  return moment(date).utc().format(format || "MM/DD/YYYY");
};
