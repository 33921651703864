import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Spacer from "components/Common/Spacer";
import useForm from "components/Form/Form.hooks";
import { uniq } from "lodash";
import {
  MerchantCategory,
  PersonalFinanceCategory,
} from "models/plaid/Transaction";

interface IProps {
  merchantCategory: MerchantCategory;
  open: boolean;
  setOpen: (open: boolean) => void;
  personalFinanceCategories: PersonalFinanceCategory[];
  update: (merchantCategory: MerchantCategory) => void;
}
const EditMerchantCategoryDialog = (props: IProps) => {
  const { merchantCategory, setOpen, personalFinanceCategories, update } =
    props;
  const { formValues, handleFormChange } = useForm(merchantCategory);

  const primaryOptions = uniq(personalFinanceCategories.map((c) => c.primary));
  const detailedOptions = personalFinanceCategories
    .filter((c) => {
      return c.primary === formValues.primaryCategory;
    })
    .map((c) => c.detailed);

  return (
    <Box width="400px">
      <DialogTitle>Edit Category</DialogTitle>
      <DialogContent>
        <Box fontSize={16}>
          <Typography fontWeight={700}>Merchant Name</Typography>
          <Typography>{formValues.merchantName}</Typography>
        </Box>
        <Spacer height={20} />
        <FormControl fullWidth>
          <InputLabel id="primaryCategory" sx={{ left: "-15px" }}>
            Primary Category
          </InputLabel>
          <Select
            variant="standard"
            labelId="primaryCategory"
            id="primaryCategory_select"
            value={formValues.primaryCategory}
            name="primaryCategory"
            label="primaryCategory"
            onChange={handleFormChange}
          >
            {primaryOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Spacer height={20} />
        <FormControl fullWidth>
          <InputLabel id="detailedCategory" sx={{ left: "-15px" }}>
            Detailed Category
          </InputLabel>
          <Select
            variant="standard"
            labelId="detailedCategory"
            id="detailedCategory_select"
            value={formValues.detailedCategory}
            name="detailedCategory"
            label="detailedCategory"
            onChange={handleFormChange}
          >
            {detailedOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Spacer height={20} />
        <Box fontSize={16}>
          <Typography fontWeight={700}>Category Description</Typography>
          <Typography>{formValues.description}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => {
            update(formValues);
            setOpen(false);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Box>
  );
};

export default EditMerchantCategoryDialog;
