import { Box, Typography } from "@mui/material";
import Spacer from "components/Common/Spacer";

interface IProps {
  heading: string;
  children: any;
}

export default function UserDetailSection({ heading, children }: IProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      width="100%"
    >
      <Typography variant="h6">{heading}</Typography>
      <Spacer height={8} />
      {children}
    </Box>
  );
}
