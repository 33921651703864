import { Box, Button, DialogContent, DialogTitle } from "@mui/material";
import useOverflowMenuDialog from "./OverflowMenuDialog.hooks";
import Loader from "components/Common/Loader";
import Spacer from "components/Common/Spacer";
import DialogModal from "components/Modals/DialogModal";
import SendTextDialog from "./SendTextDialog/SendTextDialog";

interface IProps {
  userId: string;
  closeDialog: () => void;
  onEdit: () => void;
}

export default function OverflowMenuDialog({
  userId,
  closeDialog,
  onEdit,
}: IProps) {
  const { superAdminToken, user, toggleBlocked, closeAccount, syncHubspot, sendChat } =
    useOverflowMenuDialog(userId, closeDialog, onEdit);

  if (!user) {
    return <Loader />;
  }

  return (
    <Box minWidth={400}>
      <DialogTitle>Other Actions</DialogTitle>
      <DialogContent>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={sendChat}
        >
          Send Chat
        </Button>
        {superAdminToken && (
          <>
            <Spacer height={16} />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={toggleBlocked}
            >
              {user.blocked ? "Unblock User" : "Block User"}
            </Button>
            <Spacer height={16} />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={closeAccount}
            >
              Close Account
            </Button>
          </>
        )}
        <Spacer height={16} />
        <DialogModal
          renderDialog={(open, setOpen) => (
            <SendTextDialog phoneNumber={user.phoneNumber} setOpen={setOpen} />
          )}
          renderDisplay={(open, setOpen) => (
            <Button fullWidth variant="contained" onClick={() => setOpen(true)}>
              Send Text
            </Button>
          )}
          onClose={closeDialog}
        />
        <Spacer height={16} />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={syncHubspot}
        >
          Sync With Hubspot
        </Button>
        <Spacer height={16} />
        <Button
          fullWidth
          variant="contained"
          color="inherit"
          onClick={closeDialog}
        >
          Nevermind
        </Button>
      </DialogContent>
    </Box>
  );
}
