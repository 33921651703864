import { Box, Typography } from "@mui/material";
import React from "react";

interface IProps {
  children: any;
  heading?: string;
  RightHeadingComponent?: React.ReactComponentElement<any, any>;
  noStyling?: boolean;
}

const Page = ({
  children,
  heading,
  RightHeadingComponent,
  noStyling,
}: IProps) => {
  return (
    <Box
      padding={!noStyling ? "24px" : undefined}
      boxSizing="border-box"
      flexDirection="column"
      overflow="scroll"
    >
      {(heading || RightHeadingComponent) && (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          padding="16px 0"
          gap="24px"
          alignItems="center">
          <Box sx={{flexGrow: {xs: 0, sm: 1}}}>
            <Typography variant="h4">{heading}</Typography>
          </Box>
          {RightHeadingComponent}
        </Box>
      )}
      {children}
    </Box>
  );
};

export default Page;
