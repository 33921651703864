import { Button } from "@mui/material";
import DialogModal from "components/Modals/DialogModal";
import SuperAdminAction from "components/SuperAdmin/SuperAdminAction";
import { noop } from "lodash";
import CreditDialog from "./CreditDialog/CreditDialog";

interface IProps {
  userId: string;
}

export default function CreditDialogModal({ userId }: IProps) {
  return (
    <DialogModal
      renderDisplay={(open, setOpen) => (
        <SuperAdminAction
          renderComponent={(onClick) => (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onClick}
            >
              Credit
            </Button>
          )}
          action={() => setOpen(true)}
        />
      )}
      renderDialog={(open, setOpen) => (
        <CreditDialog
          userId={userId}
          onEdit={() => window.location.reload()}
        />
      )}
      onClose={noop}
    />
  );
}
