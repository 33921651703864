import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Page from "components/Common/Page";
import NotificationInfoRow from "components/Rows/NotificationInfoRow";
import { PageIndicator } from "components/Common/PageIndicator";
import {
  ITEMS_PER_PAGE,
  useNotificationsPage,
} from "./NotificationsPage.hooks";

const NotificationsPage = () => {
  const {
    notificationInfo,
    pageNotificationInfo,
    currentPageIndex,
    onCreateClick,
    onNotificationInfoClick,
    onPageIndicatorClick,
  } = useNotificationsPage();

  const pagesCount = Math.ceil(notificationInfo.length / ITEMS_PER_PAGE);

  return (
    <Page>
      <Box width="100%" display="flex">
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          padding="16px"
          alignItems="center"
        >
          <Box flex={1}>
            <Box marginTop="8px">
              <Typography variant="h5">Notifications</Typography>
            </Box>
          </Box>
          <Button variant="contained" color="primary" onClick={onCreateClick}>
            Create
          </Button>
        </Box>
      </Box>
      <Box>
        {pageNotificationInfo.map((notificationInfo) => (
          <NotificationInfoRow
            notificationInfo={notificationInfo}
            onClick={onNotificationInfoClick}
          />
        ))}
      </Box>
      {pagesCount > 0 && (
        <Box paddingY="24px">
          <PageIndicator
            pagesCount={pagesCount}
            currentPageIndex={currentPageIndex}
            siblingsCount={1}
            onClick={onPageIndicatorClick}
          />
        </Box>
      )}
    </Page>
  );
};

export default NotificationsPage;
