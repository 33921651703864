import { getInviteCodes } from "api/inviteCodes";
import { InviteCode } from "models/InviteCode";
import { useEffect, useState, useCallback } from "react";

const useInviteCodesPage = () => {
  const [codes, setCodes] = useState<InviteCode[]>();

  const fetchInviteCodes = useCallback(() => {
    getInviteCodes().subscribe(({ data }) => setCodes(data));
  }, []);

  useEffect(() => {
    fetchInviteCodes();
  }, [fetchInviteCodes]);

  return {
    codes,
    fetchInviteCodes,
  };
};

export default useInviteCodesPage;
