import { useEffect, useState } from "react";
import { fetcher } from "../../utils/data";
import { getScoutPlans } from "../../api/scoutPlan";
import { ScoutPlan } from "../../models/ScoutPlan";
import { User } from "../../models/User";
import { getUser } from "../../api/users";
import { firstValueFrom } from "rxjs";
import { AxiosError, HttpStatusCode } from "axios";

const useScoutPlansPage = () => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [scoutPlans, setScoutPlans] = useState<ScoutPlan[]>();
  const [users, setUsers] = useState<User[]>();

  const fetchScoutPlans = fetcher(getScoutPlans, [], (plans: ScoutPlan[]) => {
    plans.sort((a, b) => {
      if (a.enabled === b.enabled) return 0;
      return a.enabled ? 1 : -1;
    });
    plans.sort((a, b) => {
      if (a.initiated === b.initiated) return 0;
      return a.initiated ? 1 : -1;
    });
    setScoutPlans(plans);
  });

  useEffect(() => {
    fetchScoutPlans();
  }, []);

  useEffect(() => {
    if (scoutPlans) {
      const start = page * pageSize;
      const end = start + pageSize;
      const userPromises = scoutPlans.slice(start, end).map(e => firstValueFrom(getUser(e.userId)).catch((error) => {
        if (error instanceof AxiosError && error.response?.status === HttpStatusCode.NotFound) {
          return null;
        } else {
          throw error;
        }
      }));
      Promise.all(userPromises).then((result) => {
        const users = result.filter(e => !!e).map(e => e!.data);
        setUsers(users);
      });
    }
  }, [page, pageSize, scoutPlans]);

  const findUser = (id: string) => {
    return users?.find(e => e.id === id);
  };

  return {findUser, pageSize, setPage, setPageSize, scoutPlans, users};
};

export default useScoutPlansPage;
