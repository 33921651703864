export enum CalendlyScheduledEventStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  NoShow = "NO_SHOW",
  Rescheduled = "RESCHEDULED",
  Scheduled = "SCHEDULED"
}

export interface CalendlyScheduledEvent {
  id: string;
  userId: string;
  uri: string;
  eventTypeName: string;
  eventTypeUri: string;
  status: CalendlyScheduledEventStatus;
  statusReason?: string;
  startTime: string;
  endTime: string;

  userInviteCodeUsed?: string;
  userPhoneNumber?: string;
  userGivenName?: string;
  userFamilyName?: string;
}

export interface CalendlyScheduledEvents {
  events: CalendlyScheduledEvent[];
  totalCount: number;
}
