import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { sendSms } from "api/users";
import useForm from "components/Form/Form.hooks";
import React from "react";

interface IProps {
  phoneNumber: string;
  setOpen: (open: boolean) => void;
}

export default function SendTextDialog({ phoneNumber, setOpen }: IProps) {
  const { formValues, handleFormChange } = useForm({
    msg: "Your verification code is",
  });

  return (
    <Box minWidth={400}>
      <DialogTitle>Send text to {phoneNumber}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Amount"
          type="text"
          fullWidth
          variant="standard"
          value={formValues.msg}
          name="msg"
          onChange={handleFormChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            sendSms(phoneNumber, formValues.msg);

            setOpen(false);
          }}
          size="large"
        >
          Send Message
        </Button>
      </DialogActions>
    </Box>
  );
}
