import { Box, Link } from "@mui/material";
import Spacer from "../../../../../components/Common/Spacer";
import useNotesTabView from "./NotesTabView.hooks";
import { ActivityType, UserActivity } from "api/users";
import AddNoteDialog from "./AddNoteDialog";
import { formatUTCDate } from "../../../../../utils/time";
import { ActivityCard } from "./ActivityCard";

interface IProps {
  userId: string;
}

export default function NotesTabView({userId}: IProps) {
  const {activities, hasMore, onShowMoreClick, onSubmit} = useNotesTabView(userId);

  const formatDate = (value: string) => formatUTCDate(value, "M/D/YYYY h:mmA");

  const tryFormatDate = (value?: string) => value ? formatDate(value) : undefined;

  const activityCard = (activity: UserActivity) => {
    switch (activity.type) {
      case ActivityType.InternalNote:
        const note = activity.note!;
        return <ActivityCard title={note.subject} content={note.note} color="info"
                             bottomLabel={`${note.kataraUserName} • ${formatDate(activity.date)}`}/>;
      case ActivityType.MeetingScheduled:
        return <ActivityCard title="Meeting Scheduled" content={tryFormatDate(activity.event!.startTime)} color="info"
                             bottomLabel={formatDate(activity.date)}/>;
      case ActivityType.MeetingStatusRescheduled:
        return <ActivityCard title="Meeting Rescheduled"
                             content={activity.event!.startTime ? `Rescheduled from ${formatDate(activity.event!.startTime)}` : undefined}
                             color="info"
                             bottomLabel={formatDate(activity.date)}/>;
      case ActivityType.MeetingStatusNoShow:
      case ActivityType.MeetingStatusCanceled:
        return <ActivityCard title="Meeting No Showed / Cancelled" content={tryFormatDate(activity.event!.startTime)}
                             color="error" bottomLabel={formatDate(activity.date)}/>;
      case ActivityType.MeetingStatusCompleted:
        return <ActivityCard title="Meeting Completed" content={tryFormatDate(activity.event!.startTime)}
                             color="success" bottomLabel={formatDate(activity.date)}/>;
    }
  };

  return (
    <Box flexDirection="column" width="100%">
      <AddNoteDialog onSubmit={({note, subject}) => onSubmit(note, subject)}/>
      <Spacer height={24}/>
      {activities && activities.map((e, i) => (
        <>
          {activityCard(e)}
          {i !== activities.length - 1 && <Spacer height={16}/>}
        </>
      ))}
      {hasMore && (
        <>
          <Spacer height={16}/>
          <Link onClick={onShowMoreClick}>Show more</Link>
        </>
      )}
    </Box>
  );
}
