import { Box, Button, DialogContent, DialogTitle } from "@mui/material";
import DepositDialogModal from "./DepositDialogModal/DepositDialogModal";
import Spacer from "components/Common/Spacer";
import WithdrawalDialogModal from "./WithdrawalDialogModal/WithdrawalDialogModal";
import CreditDialogModal from "./CreditDialogModal/CreditDialogModal";
import IntraTransferDialogModal from "./IntraTransferDialogModal/IntraTransferDialogModal";

interface IProps {
  userId: string;
  close: () => void;
}

export default function TransferDialog({ userId, close }: IProps) {
  return (
    <Box minWidth={400}>
      <DialogTitle>Make a transfer</DialogTitle>
      <DialogContent>
        <DepositDialogModal userId={userId} />
        <Spacer height={16} />
        <IntraTransferDialogModal userId={userId} />
        <Spacer height={16} />
        <WithdrawalDialogModal userId={userId} />
        <Spacer height={16} />
        <CreditDialogModal userId={userId} />
        <Spacer height={16} />
        <Button
          fullWidth
          variant="contained"
          color="inherit"
          size="large"
          onClick={close}
        >
          Nevermind
        </Button>
      </DialogContent>
    </Box>
  );
}
