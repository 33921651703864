import React from "react";
import Page from "components/Common/Page";
import Loader from "components/Common/Loader";
import { useMatch } from "react-router-dom";
import { Box, Typography, TextField } from "@mui/material";
import { useNotificationDetailPage } from "./NotificationDetailPage.hooks";

const match = "/notifications/:id";

const NotificationDetailPage = () => {
  let m = useMatch(match);
  const id = m?.params.id;
  const { notificationInfo } = useNotificationDetailPage(id || "");

  if (!notificationInfo) {
    return <Loader />;
  }

  const sentPercentage = (notificationInfo.sent / notificationInfo.count) * 100;
  const openedPercentage =
    notificationInfo.sent > 0
      ? (notificationInfo.opened / notificationInfo.sent) * 100
      : 0;

  return (
    <Page>
      <Box
        display="flex"
        flexDirection="column"
        padding="16px"
        alignItems="flex-start"
      >
        <Box height="38px" />
        <Typography variant="h5">Notification</Typography>
        <Box height="4px" />
        <Box color="#757575">
          {notificationInfo.id} • {sentPercentage}% sent • {openedPercentage}
          %opened
        </Box>
        <Box height="20px" />
        <TextField
          variant="outlined"
          fullWidth
          label="Notification Title"
          inputProps={{
            readOnly: true,
          }}
          value={notificationInfo.title}
        />
        <Box height="20px" />
        <TextField
          variant="outlined"
          fullWidth
          label="Notification Body"
          inputProps={{
            readOnly: true,
          }}
          required
          value={notificationInfo.body}
        />
        <Box height="20px" />
        <TextField
          variant="outlined"
          fullWidth
          label="Link"
          inputProps={{
            readOnly: true,
          }}
          value={notificationInfo.link}
        />
        <Box height="20px" />
        <TextField
          variant="outlined"
          fullWidth
          label="User IDs"
          inputProps={{
            readOnly: true,
          }}
          helperText="User IDs must be comma separated."
          required
          value={notificationInfo.userIds}
        />
      </Box>
    </Page>
  );
};

export default NotificationDetailPage;
