import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "views/LoginPage";
import UploadPage from "views/UploadPage/UploadPage";
import InviteCodesPage from "views/InviteCodesPage/InviteCodesPage";
import FeatureFlagsPage from "views/FeatureFlagsPage/FeatureFlagsPage";
import SecurityModelsPage from "views/SecurityModelsPage/SecurityModelsPage";
import { KataraUser } from "models/KataraUser";
import { getMe } from "api/user";
import UnauthorizedPage from "views/UnauthorizedPage";
import { LocalStorageProp, unsetLocalStorageValue } from "utils/localStorage";
import PromoCodesPage from "views/PromoCodesPage/PromoCodesPage";
import SODDownloadPage from "views/SODDownloadPage/SODDownloadPage";
import { KataraUserProvider } from "components/Contexts/KataraUserContext/KataraUserContext";
import OnfidoCheckDownloadPage from "views/OnfidoCheckDownloadPage/OnfidoCheckDownloadPage";
import NotificationRoutes from "views/Notifications/Notification.routes";
import UserRoutes from "views/Users/User.routes";
import ChatRoutes from "views/Chat/Chat.routes";
import TransactionsPage from "views/TransactionsPage/TransactionsPage";
import FailedItemsRoutes from "views/FailedItems/FailedItems.routes";
import PlayerAwardTypesPage from "views/PlayerAwardTypesPage/PlayerAwardTypesPage";
import CalendlyRoutes from "views/Calendly/Calendly.routes";
import CalendlyEventsPage from "./views/CalendlyEventsPage/CalendlyEventsPage";
import ScoutPlansPage from "./views/ScoutPlans/ScoutPlansPage";
import PendingValidationItemsRoutes from "./views/PendingValidationItems/PendingValidationItems.routes";
import NavWrapper from "./components/NavWrapper";
import OfficerCompliancePage from "./views/OfficerCompliance/OfficerCompliancePage";
import EducationSessionRoutes from "./views/EducationSessions/EducationSessions.routes";

const AppRoutes = () => {
  const [user, setUser] = useState<KataraUser>();

  useEffect(() => {
    try {
      getMe().subscribe(
        ({data}) => {
          setUser(data);
        },
        (e) => {
          unsetLocalStorageValue(LocalStorageProp.Token);
          window.open("/login", "_self");
        }
      );
    } catch {
    }
  }, []);

  return (
    <>
      <KataraUserProvider user={user}>
        <BrowserRouter>
          <Routes>
            <Route
              key="login"
              path="/login"
              element={<LoginPage user={user}/>}
            />
            <Route element={<NavWrapper/>}>
              <Route key="upload" path="/upload" element={<UploadPage/>}/>
              <Route
                key="invite_codes"
                path="/invite_codes"
                element={<InviteCodesPage/>}
              />
              <Route
                key="promo_codes"
                path="/promo_codes"
                element={<PromoCodesPage/>}
              />
              <Route path="/notifications/*" element={<NotificationRoutes/>}/>
              <Route key="security_models" path="/security_models" element={<SecurityModelsPage/>}/>
              <Route
                key="feature-flags"
                path="/feature_flags"
                element={<FeatureFlagsPage/>}
              />
              <Route path="/users/*" element={<UserRoutes/>}/>
              <Route path="/scout_plans" element={<ScoutPlansPage/>}/>
              <Route
                key="sod-files"
                path="/sod_files"
                element={<SODDownloadPage/>}
              />
              <Route
                key="check-files"
                path="/check_files"
                element={<OnfidoCheckDownloadPage/>}
              />
              <Route path="/officer_compliance" element={<OfficerCompliancePage/>}/>
              <Route path="/calendly_events" element={<CalendlyEventsPage/>}/>
              <Route path="/education_sessions/*" element={<EducationSessionRoutes/>}/>
              <Route path="/chat/*" element={<ChatRoutes/>}/>
              <Route path="/transactions" element={<TransactionsPage/>}/>
              <Route path="/failed_items/*" element={<FailedItemsRoutes/>}/>
              <Route path="/calendly/*" element={<CalendlyRoutes/>}/>
              <Route
                path="/player_award_types"
                element={<PlayerAwardTypesPage/>}
              />
              <Route
                path="/pending_validation_items/*"
                element={<PendingValidationItemsRoutes/>}
              />
            </Route>
            <Route
              key="unauthorized"
              path="/unauthorized"
              element={<UnauthorizedPage/>}
            />
            <Route path="*" element={<Navigate to="/login"/>}/>
          </Routes>
        </BrowserRouter>
      </KataraUserProvider>
    </>
  );
};

export default AppRoutes;
