import { useReducer } from "react";

const useForm = (initialValues: any) => {
  const [formValues, setFormValues] = useReducer(
    (currVals: any, newVals: any) => ({ ...currVals, ...newVals }),
    initialValues
  );

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({ [name]: value });
  };

  const handleCheckboxChange = (e: any) => {
    const { name } = e.target;
    setFormValues({ [name]: !formValues[name] });
  };

  const setForm = (property: string, value: any) =>
    setFormValues({ [property]: value });

  return {
    formValues,
    handleFormChange,
    handleCheckboxChange,
    setForm,
  };
};

export default useForm;
